import { Injectable } from '@angular/core';
import { EntityService } from '../../../interfaces/entity-service.class';
import { EffimodTableService } from '../../effimod-table/services/effimod-table.service';
import { EffimodTableFilterKeyValue, EffimodTableSortingEvent } from '../../../interfaces/effimod-table-definition.interface';

@Injectable({
    providedIn: 'root'
})
export class ReservationenTableService {

    constructor(
        private effimodTableService: EffimodTableService
    ) {}

    changeSortOrderWithReload(service: EntityService, sortingEvent: EffimodTableSortingEvent) {
        this.effimodTableService.changeSortOrderWithReload(service, sortingEvent);
    }

    filtersChanged(service: EntityService, filterEvents: EffimodTableFilterKeyValue) {
        this.effimodTableService.filtersChanged(service, filterEvents);
    }
}
