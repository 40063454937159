import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SelectedVeranstaltungenService } from '../../services/selected-veranstaltungen.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { VeranstaltungenDataService } from '../../../data/services/veranstaltungen-data.service';

@Component({
    selector: 'effimod-veranstaltungen-provisorische-deletion-button',
    templateUrl: './veranstaltungen-provisorische-deletion-button.component.html',
    styleUrls: ['./veranstaltungen-provisorische-deletion-button.component.scss']
})
export class VeranstaltungenProvisorischeDeletionButtonComponent {

    @Output() deletedVeranstaltungen = new EventEmitter<number[]>();

    public displayDeletionCheckback: boolean = false;
    iconProps: IconProp = ['fas', 'trash-alt'];

    constructor(
        public selectedVeranstaltungenService: SelectedVeranstaltungenService,
        private veranstaltungenDataService: VeranstaltungenDataService
    ) {}

    deleteProvisorischeVeranstaltungen(): void {
        this.displayDeletionCheckback = true;
    }

    confirmDeletion(): void {
        this.displayDeletionCheckback = false;
        const selectedVeranstaltungen = this.selectedVeranstaltungenService.getSelectedVeranstaltungen();
        const deletedVeranstaltungIds = selectedVeranstaltungen.map(v => v.id);

        deletedVeranstaltungIds.forEach(id => this.veranstaltungenDataService.deleteVeranstaltung(id, false));
        this.selectedVeranstaltungenService.clearSelection();
        this.deletedVeranstaltungen.emit(deletedVeranstaltungIds);
    }

    cancelDeletion(): void {
        this.displayDeletionCheckback = false;
    }
}
