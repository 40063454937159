import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromStore from '../../store';
import { LoadingStatus } from '../../store';

@Injectable({providedIn: 'root'})
export class GebaeudeGuard implements CanActivate {


    constructor(private store: Store<fromStore.AppState>) {
    }

    canActivate(): Observable<boolean> {
        return this.checkStatus().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }

    checkStatus(): Observable<boolean> {
        return this.store.pipe(
            select(fromStore.getGebaeudeStateStatus),
            tap((status) => {
                if (status.geschosse === LoadingStatus.Initializing && status.raeume === LoadingStatus.Initializing) {
                    this.store.dispatch(fromStore.loadAll());
                } else if (status.geschosse !== LoadingStatus.Initializing && status.raeume === LoadingStatus.Initializing) {
                    this.store.dispatch(fromStore.loadRaeume());
                } else if (status.geschosse === LoadingStatus.Initializing && status.raeume !== LoadingStatus.Initializing) {
                    this.store.dispatch(fromStore.loadGeschosse());
                }
            }),
            filter((status) => {
                return (status.raeume === LoadingStatus.Loaded || status.raeume === LoadingStatus.Error) &&
                    (status.geschosse === LoadingStatus.Loaded || status.geschosse === LoadingStatus.Error);
            }),
            map(status => {
                return true;
            }),
            take(1)
        );
    }
}
