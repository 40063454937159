import gql from 'graphql-tag';
import { BaseEntityFilter } from '../../interfaces/base-entity-filter.interface';
import { DocumentNode } from 'graphql';
import { Reservation } from '../../interfaces/reservation.interface';

export const ReservationenApi: any = {};

ReservationenApi.fragments = {
    reservation: gql`
        fragment ReservationFragment on ReservationType {
            id
            createdOn
            lastModified
            reservationsNummer
            beschriftung
            anlassBeginn
            anzahlPersonen
            beginn
            ende
            isRelevantForArmit
            isRelevantForBouygues
            isRelevantForWacheAg
            isRelevantForZfv
            lieferantenAnweisungen {
                id
                anweisung
                lieferant {
                    id
                    name
                }
            }
            raum {
                id
                bezeichnung
                disabled
                geschoss {
                    id
                    bezeichnung
                }
            }
            raumBestuhlung {
                id
                bezeichnung
            }
            veranstaltung {
                id
                titel
                beginn
                ende
                status {
                    id
                    bezeichnung
                }
                veranstalterVersion {
                    id
                    firma
                    vorname
                    nachname
                    name
                    adresse {
                        id
                        strasse
                        plz
                        ort
                    }
                }
                kundeAnsprechpersonVersion {
                    id
                    name
                    anrede
                }
                isSealed
            },
            standardBestellpositionen {
                id
                anzahl
                bemerkung
                artikel {
                    einheit
                    bezeichnung
                    kategorie {
                        id
                        bezeichnung
                        sortIndex
                    }
                }
            }
            folgeReservation {
                id
                reservationsNummer
                beschriftung
                anlassBeginn
                status {
                    id
                    bezeichnung
                }
            }
            hasInternetVoucher
        }
    `,
    reservationForListing: gql`
        fragment ReservationForListingFragment on ReservationType {
            id
            reservationsNummer
            beschriftung
            beginn
            ende
            isRelevantForArmit
            isRelevantForBouygues
            isRelevantForWacheAg
            isRelevantForZfv
            raum {
                id
                bezeichnung
                disabled
                geschoss {
                    id
                    bezeichnung
                }
            }
            veranstaltung {
                id
                veranstalterVersion {
                    id
                    firma
                    vorname
                    nachname
                    name
                    adresse {
                        id
                        ort
                    }
                }
                kundeAnsprechpersonVersion {
                    id
                    name
                    anrede
                }
                status {
                    id
                    bezeichnung
                }
            }
            hasInternetVoucher
        }
    `
};

ReservationenApi.mutations = {
    erstellen: gql`
        mutation($reservation: reservationInput!) {
            reservationen {
                erstellen(reservation: $reservation) {
                    ... ReservationForListingFragment
                }
            }
        }
        ${ReservationenApi.fragments.reservationForListing}
    `,
    delete: gql`
        mutation($reservationId: Int!) {
            reservationen {
                delete(id: $reservationId)
            }
        }
    `
};

ReservationenApi.mutations.update = gql`
    mutation reservationBearbeiten($reservation: reservationUpdateInput!) {
        reservationen {
            bearbeiten(reservation: $reservation) {
                id
                reservationsNummer
            }
        }
    }
`;


export interface ReservationenResponse {
    privateApi: {
        reservationen: {
            alleReservationen: Reservation[];
            alleRaumbelegungen: Reservation[];
        };
    };
}

export function getReservationen(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    let fragment = 'ReservationForListingFragment';
    let fragmentImport = ReservationenApi.fragments.reservationForListing;
    let displayDetails = ', displayDetails: false';
    if (withDetails) {
        fragment = 'ReservationFragment';
        fragmentImport = ReservationenApi.fragments.reservation;
        displayDetails = ', displayDetails: true';
    }
    let filterOrderString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        filterOrderString += `, filter: {${filter.filterBy.map(f => f.toQuery()).join(',')}}`;
    }
    if (filter.orderBy && filter.orderBy.length > 0) {
        filterOrderString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }
    const query = gql`
        query getReservation {
            privateApi {
                reservationen {
                    alleReservationen(take: ${filter.take}, skip: ${filter.skip}${filterOrderString}${displayDetails}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;
    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

export function getRaumbelegungen(filter: BaseEntityFilter, debug = false) {
    const fragment = 'ReservationForListingFragment';
    const fragmentImport = ReservationenApi.fragments.reservationForListing;
    let filterOrderString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        filterOrderString += `, filter: {${filter.filterBy.map(f => f.toQuery()).join(',')}}`;
    }
    const query = gql`
        query getRaumbelegungen {
            privateApi {
                reservationen {
                    alleRaumbelegungen(take: ${filter.take}, skip: ${filter.skip}${filterOrderString}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;
    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

function getGqlString(doc: DocumentNode) {
    return doc.loc && doc.loc.source.body;
}
