import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-short-text-labeled-input',
    templateUrl: './short-text-labeled-input.component.html',
    styleUrls: ['./short-text-labeled-input.component.scss']
})
export class ShortTextLabeledInputComponent {
    @Input()
    disabled: boolean = false;

    @Input()
    inputType: 'text' | 'number' = 'text';

    @Input()
    parent: FormGroup;

    @Input()
    placeholder: string;

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    formControlNameString: string;

    // called like this because otherwise the routerlink gets set on the whole component
    @Input()
    routerLinkInput: string;

    // see also: https://en.wikipedia.org/wiki/ISO_4217
    @Input()
    currencyCode: string;

    @Input()
    tooltip?: string;

    public routeMatches: boolean = true;

    public get isCurrency(): boolean {
        return !!this.currencyCode;
    }

    get effimodFormControl() { return this.parent.get(this.formControlNameString); }
}
