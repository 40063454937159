import gql from 'graphql-tag';

export const ArtikelApi: any = {};

ArtikelApi.queries = {
    getArtikel: gql`
        query getArtikel($kategorieId: Int) {
            publicApi {
                artikel {
                    alleArtikel(kategorieId: $kategorieId) {
                        id
                        nummer
                        bezeichnung
                        einheitspreis
                        einheit
                        mwst
                        kategorie {
                            id
                            bezeichnung
                            sortIndex
                        },
                        disabled
                    }
                }
            }
        }
    `,
    getBestellbareArtikel: gql`
        query getBestellbareArtikel {
            publicApi {
                artikel {
                    bestellbareArtikel {
                        id
                        nummer
                        bezeichnung
                        einheitspreis
                        einheit
                        mwst
                        kategorie {
                            id
                            bezeichnung
                            sortIndex
                        },
                        disabled
                    }
                }
            }
        }
    `
};
