import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KundenTypOptions } from '../../kunden-typ-selector/kunden-typ-options';

@Component({
    selector: 'effimod-insightly-kunden-typeahead-search-result-selector',
    templateUrl: './insightly-kunden-typeahead-search-result-selector.component.html',
    styleUrls: ['./insightly-kunden-typeahead-search-result-selector.component.scss']
})
export class InsightlyKundenTypeaheadSearchResultSelectorComponent implements OnInit {

    @Input()
    searchFieldLabel: string = 'Kunde';

    @Input()
    preselectedKundenTypOption: KundenTypOptions;

    @Input()
    searchFieldPlaceholder: string;

    @Output()
    kundeSelected: EventEmitter<any> = new EventEmitter<any>();

    isOrganisationSearch: boolean = true;

    constructor() { }

    ngOnInit() {
    }

    onKundeSelected(insightlyKunde: any) {
        this.kundeSelected.emit(insightlyKunde);
    }
}
