import { Pipe, PipeTransform } from '@angular/core';
import { SachbearbeiterDropdownOption } from '../helpers/data/models/sachbearbeiter-dropdown-option.model';
import { User } from '../../data/interfaces/user.interface';

@Pipe({
  name: 'sachbearbeiterOptions'
})
export class SachbearbeiterOptionsPipe implements PipeTransform {

    transform(value: User | User[]): SachbearbeiterDropdownOption | SachbearbeiterDropdownOption[] | any {
        if (Array.isArray(value)) {
            // Handle list of items
            return value.map(item => this.transformSingleItem(item));
        } else {
            // Handle single item
            return this.transformSingleItem(value);
        }
    }

    private transformSingleItem(item: User): SachbearbeiterDropdownOption {
        return {
            id: item.id,
            name: `${item.vorname} ${item.nachname}`,
            hide: item.isInaktiv
        } as SachbearbeiterDropdownOption;
    }

}
