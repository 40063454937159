import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { throwError } from 'rxjs';

@Pipe({
    name: 'kundenFormEffimodLinkGenerator'
})
export class KundenFormEffimodLinkGeneratorPipe implements PipeTransform {

    constructor(private readonly router: Router) { }

    transform(form: FormGroup, ...args: any[]): string {
        const link = this.getRouterLink(form);

        if (link && link.endsWith(this.router.url)) {
            return '';
        }

        return link;
    }

    getRouterLink(form: FormGroup): string {
        if (!form) {
            throwError('Form is required!');
            return '';
        }

        if (!(form.get('id') && form.get('id').value)) {
            console.error('ID is required!');
            return '';
        }
        const id = form.get('id').value;
        return `/kunden/${id}`;
    }
}
