import { Veranstaltung } from '../../interfaces/veranstaltung.interface';
import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import { VeranstaltungenApi } from './veranstaltungen.gql';

export interface VeranstaltungResponse {
    veranstaltungen: {
        erstellen: Veranstaltung,
        delete: number,
        kopieren: Veranstaltung,
        abschliessen: boolean,
        annullieren: boolean,
        blockierungenKonvertierenZuVeranstaltung: Veranstaltung
        anzahlungVerarbeiten: boolean;
    };
}

@Injectable({providedIn: 'root'})
export class CreateVeranstaltungMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.erstellen;
}

@Injectable({providedIn: 'root'})
export class DeleteVeranstaltungMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.delete;
}

@Injectable({providedIn: 'root'})
export class CancelVeranstaltungMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.annullieren;
}

@Injectable({providedIn: 'root'})
export class CopyVeranstaltungMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.kopieren;
}

@Injectable({providedIn: 'root'})
export class AbschliessenVeranstaltungMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.abschliessen;
}

@Injectable({providedIn: 'root'})
export class UpdateAnsprechpersonenMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.updateAnsprechpersonen;
}

@Injectable({providedIn: 'root'})
export class UpdateVeranstaltungStatusMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.updateVeranstaltungStatus;
}

@Injectable({providedIn: 'root'})
export class CreateVeranstaltungFromBlockierungenMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.createVeranstaltungFromBlockierungen;
}

@Injectable({providedIn: 'root'})
export class AnzahlungenVerarbeitenMutationGQL extends Mutation<VeranstaltungResponse, {}> {
    document = VeranstaltungenApi.mutations.anzahlungenVerarbeiten;
}

