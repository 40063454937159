import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { EffimodPermissionEnum } from '../enums/permission.enum';
import { AuthorizationService } from 'src/app/auth/authorization.service';

// Reference: https://angular.io/guide/structural-directives
@Directive({
    selector: '[effimodAuthorization]'
})
export class AuthorizationDirective {

    private hasView: boolean = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authorizationService: AuthorizationService) { }

    @Input() set effimodAuthorization(permissionName: EffimodPermissionEnum) {
        this.authorizationService.checkCurrentUserForPermission(permissionName)
            .pipe(
                first()
            )
            .subscribe({
                next: (hasPermission) => {
                    if (hasPermission && !this.hasView) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                        this.hasView = true;
                    } else if (!hasPermission && this.hasView) {
                        this.viewContainer.clear();
                        this.hasView = false;
                    }
                }
            });
    }
}
