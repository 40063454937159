import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { VeranstaltungenTableFieldSelectors } from '../constants/veranstaltungen-table-field-selectors';
import { EffimodTableDefinition, EffimodTableFilterType } from '../../../interfaces/effimod-table-definition.interface';
import { VeranstaltungenFilterNames } from '../constants/veranstaltungen-filter-names';
import { EffimodTableFilterOperators } from '../../reservationen-list/constants/effimod-table-filter-operators';

@Injectable({ providedIn: 'root' })
export class VeranstaltungenTableDefinitionService {
    constructor() {}

    getTableDefinition(sortingDisabled: boolean): EffimodTableDefinition {
        return {
            sortingDisabled,
            idColumnSelector: 'id',
            columns: [
                {
                    header: 'Nummer',
                    fieldSelector: VeranstaltungenTableFieldSelectors.ID,
                    filterType: EffimodTableFilterType.number,
                    filterName: VeranstaltungenFilterNames.VERANSTALTUNG_ID,
                    operator: EffimodTableFilterOperators.STARTS_WITH,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-nummer'
                },
                {
                    header: 'Titel',
                    fieldSelector: VeranstaltungenTableFieldSelectors.TITEL,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.TITEL,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-title'
                },
                {
                    header: 'Von',
                    fieldSelector: VeranstaltungenTableFieldSelectors.BEGINN,
                    pipe: new DatePipe('de-CH'),
                    pipeArgs: ['dd.MM.yyyy HH:mm'],
                    filterField: VeranstaltungenTableFieldSelectors.BEGINN,
                    filterType: EffimodTableFilterType.date,
                    filterName: VeranstaltungenFilterNames.BEGINN,
                    operator: EffimodTableFilterOperators.GT,
                    printClipText: true,
                    styleClass: 'veranstaltung-beginn'
                },
                {
                    header: 'Bis',
                    fieldSelector: VeranstaltungenTableFieldSelectors.ENDE,
                    pipe: new DatePipe('de-CH'),
                    pipeArgs: ['dd.MM.yyyy HH:mm'],
                    filterField: VeranstaltungenTableFieldSelectors.ENDE,
                    filterType: EffimodTableFilterType.date,
                    filterName: VeranstaltungenFilterNames.ENDE,
                    operator: EffimodTableFilterOperators.LT,
                    printClipText: true,
                    styleClass: 'veranstaltung-ende'
                },
                {
                    header: 'Veranstalter',
                    fieldSelector: VeranstaltungenTableFieldSelectors.VERANSTALTER_NAME,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.VERANSTALTER_NAME,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-veranstalter'
                },
                {
                    header: 'Ort',
                    fieldSelector: VeranstaltungenTableFieldSelectors.VERANSTALTER_ORT,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.VERANSTALTER_ORT,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-veranstalter-ort'
                },
                {
                    header: 'Sachbearbeiter',
                    fieldSelector: VeranstaltungenTableFieldSelectors.SACHBEARBEITER_NAME,
                    selectionOptions: [{ label: 'Alle', value: 'all' }],
                    filterType: EffimodTableFilterType.selection,
                    filterName: VeranstaltungenFilterNames.SACHBEARBEITER_ID,
                    operator: EffimodTableFilterOperators.EQ,
                    sortingDisabled: true,
                    width: 180,
                    styleClass: 'veranstaltung-sachbearbeiter'
                },
                {
                    header: 'Status',
                    fieldSelector: VeranstaltungenTableFieldSelectors.STATUS_BEZEICHNUNG,
                    selectionOptions: [{ label: 'Alle', value: 'all' }],
                    filterType: EffimodTableFilterType.selection,
                    filterName: VeranstaltungenFilterNames.STATUS_ID,
                    operator: EffimodTableFilterOperators.EQ,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-status'
                }
            ],
            navigatable: true,
            selectMultiple: true
        };
    }
}
