import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'effimod-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

    @Input()
    values: Array<string>;

    @Input()
    parent: AbstractControl;

    @Input()
    formControlNameString: string;

    @Input()
    label: string;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    handleChange(event: any) {
        this.valueChange.emit(event);
    }

    get effimodFormControl() { return this.parent.get(this.formControlNameString); }

}
