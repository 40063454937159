import { Injectable } from '@angular/core';
import { getCurrentUser } from '../../store/selectors';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RelevantReservationFlagNameFinderService {

    constructor(private store: Store<AppState>) { }

    getFlagSelector(): Observable<RelevantReservationFlagData> {
        return this.store.select(getCurrentUser)
            .pipe(
                take(1),
                map(currentUser => {
                        // if (!currentUser) { // todo it may be necessary to reactivate this again...
                        //     return null;
                        // }

                    // todo jonathan: @rustom can we remove this and use backend for other use cases?
                        const fieldName = currentUser.vorname.toUpperCase().includes('wache'.toUpperCase()) ? LieferantenRelevantFlags.Wache :
                            currentUser.vorname.toUpperCase().includes('Bouygues'.toUpperCase()) ? LieferantenRelevantFlags.Bouygues :
                                currentUser.vorname.toUpperCase().includes('Armit'.toUpperCase()) ? LieferantenRelevantFlags.Armit :
                                    currentUser.vorname.toUpperCase().includes('Zfv'.toUpperCase()) ? LieferantenRelevantFlags.Zfv : '';
                        return {reservationLieferantenFlagName: fieldName, flagColumnName: currentUser.vorname};
                    }
                )
            );
    }
}

export interface RelevantReservationFlagData {
    reservationLieferantenFlagName: string;
    flagColumnName: string;
}

export enum LieferantenRelevantFlags {
    Bouygues = 'isRelevantForBouygues',
    Wache = 'isRelevantForWacheAg',
    Armit = 'isRelevantForArmit',
    Zfv = 'isRelevantForZfv'
}
