import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { Notification } from '../../shared/interfaces/notification.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'effimod-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    public notifications$: Observable<Notification[]>;

    constructor(private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.notifications$ = this.notificationService.notifications$;
    }

    public hide(id: number) {
        this.notificationService.hide(id);
    }
}
