import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-long-text-labeled-input',
    templateUrl: './long-text-labeled-input.component.html',
    styleUrls: ['./long-text-labeled-input.component.scss']
})
export class LongTextLabeledInputComponent implements OnInit {
    @Input()
    parent: FormGroup;

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    formControlNameString: string;

    @Input()
    templateText: string = '';

    constructor() {
    }

    ngOnInit() {
    }

    public fillFromTemplate() {
        this.parent.get(this.formControlNameString).setValue(this.templateText);
    }

    public isDisabled() {
        return this.parent.disabled;
    }
}
