import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { CacheDataService } from './shared/services/cache-data.service';
import { EffimodActionsService } from './shared/services/effimod-actions.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'effimod-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Effimod MMXX';
    environment: any;

    constructor(private auth: AuthService, private cacheSyncService: CacheDataService, private effimodActionsService: EffimodActionsService, private titleService: Title, private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.auth.localAuthSetup();
        this.auth.handleAuthCallback();
        this.environment = environment;

        this.setTitle();
    }

    onHeaderActionClicked(actionName: string) {
        this.handleHeaderAction(actionName);
    }

    private handleHeaderAction(actionName: string) {
        // todo define constants for the actionNames used here in order to avoid unintended bugs to to re-namings and such...
        if (actionName == 'aktualisieren') {
            this.cacheSyncService.invalidateInsightlyEffimodCache();
        } else if (actionName == 'syncArtikel') {
            this.effimodActionsService.syncBestellbareArtikelFromGaraioremToEffimod();
        }
    }


    // changes the title tag depending on data given in routing-module data property. If data.noId is true, we ignore the id param, if it is left out we display the id in the tab aswell
    private setTitle() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            map((route) => route.snapshot),
            map(snapshot => {
                if (snapshot.data.windowTitle) {
                    if (snapshot.paramMap.get('id') !== null && !snapshot.data.noId) {
                        return snapshot.data.windowTitle + ' ' + snapshot.paramMap.get('id') + ' | Effimod MMXX';
                    } else {
                        return snapshot.data.windowTitle + ' | Effimod MMXX';
                    }
                }
                return 'Effimod MMXX';
            })
        ).subscribe((event) => {
            this.titleService.setTitle(event);
        });
    }
}

