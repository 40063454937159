import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { Geschoss } from '../../interfaces/geschoss.interface';
import { Raum } from '../../interfaces/raum.interface';
import { GebaeudeApi } from './gebaeude.gql';
import { RaumBestuhlung } from '../../interfaces/raum-bestuhlung.interface';

export interface GebaeudeResponse {
    publicApi: {
        gebaeude: { alleRaeume: Raum[], alleGeschosse: Geschoss[], alleRaumBestuhlungen: RaumBestuhlung[] };
    };
}

@Injectable({providedIn: 'root'})
export class GetRaeumeGQL extends Query<GebaeudeResponse, {}> {
    document = GebaeudeApi.queries.getRaeume;
}

@Injectable({providedIn: 'root'})
export class GetGeschosseGQL extends Query<GebaeudeResponse, {}> {
    document = GebaeudeApi.queries.getGeschosse;
}

@Injectable({providedIn: 'root'})
export class GetRaumBestuhlungenGQL extends Query<GebaeudeResponse, {}> {
    document = GebaeudeApi.queries.getRaueme;
}
