export const ReservationenTableFieldSelectors = {
    RESERVATION_NUMMER: 'reservationsNummer',
    BESCHRIFTUNG: ['beschriftung'],
    RAUM_BEZEICHNUNG: ['raum', 'bezeichnung'],
    VERANSTALTER_NAME: ['veranstaltung', 'veranstalterVersion', 'name'],
    VERANSTALTER_ORT: ['veranstaltung', 'veranstalterVersion', 'adresse', 'ort'],
    BEGINN: ['beginn'],
    ENDE: ['ende'],
    STATUS_BEZEICHNUNG: ['veranstaltung', 'status', 'bezeichnung'],
    VOUCHER: ['hasInternetVoucher']
};
