import { Injectable } from '@angular/core';
import { KundeVersion } from '../../data/interfaces/kunde-version.interface';
import { InsightlyContact, InsightlyOrganisation } from './insightly-data.types';

@Injectable({
    providedIn: 'root'
})
export class InsightlyDataMapper {

    mapContactsToKundeVersion(contacts: InsightlyContact[]): KundeVersion[] {
        return contacts.map(this.mapContactToKundeVersion);
    }

    mapContactToKundeVersion(contact) {
        return {
            id: undefined,
            kundeId: undefined,
            kunde: {
                organisationId: contact.organisationId,
                isFirma: false
            },
            crmId: undefined,
            crm: {
                name: 'Insightly'
            },
            veranstaltungen: undefined,
            adresseId: undefined,
            rechnungsAdresseId: undefined,
            firma: contact.name,
            anrede: contact.salutation,
            titel: contact.title,
            vorname: contact.firstName,
            nachname: contact.lastName,
            name: contact.name,
            adresse: {
                strasse: contact.addressStreet,
                plz: contact.addressPostcode,
                ort: contact.addressCity,
                bundesland: contact.addressState,
                land: contact.addressCountry
            },
            rechnungsAdresse: {
                strasse: contact.addressStreet,
                plz: contact.addressPostcode,
                ort: contact.addressCity,
                bundesland: contact.addressState,
                land: contact.addressCountry
            },
            telefon: contact.phone,
            mobile: contact.phoneMobile,
            email: contact.email,
            debitorenNummer: contact.debitorenNummer,
            externalCrmKundeId: contact.contactId
        } as unknown as KundeVersion;
    }

    mapOrganisationsToKundeVersion(organisations: InsightlyOrganisation[]): KundeVersion[] {
        return organisations.map(this.mapOrganisationToKundeVersion);
    }

    mapOrganisationToKundeVersion(org): KundeVersion {
        return {
            id: undefined,
            kundeId: undefined,
            crmId: undefined,
            crm: {
                name: 'Insightly'
            },
            veranstaltungen: undefined,
            kunde: {
                isFirma: true
            },
            firma: org.organisationName,
            anrede: '',     // Organisations typically don't have a salutation
            titel: '',      // Organisations typically don't have a title
            vorname: '',    // Organisations typically don't have a first name
            nachname: '',   // Organisations typically don't have a last name
            name: org.organisationName,
            adresseId: undefined,
            adresse: {
                strasse: org.addressShipStreet,
                plz: org.addressShipPostcode,
                ort: org.addressShipCity,
                bundesland: org.addressShipState,
                land: org.addressShipCountry
            },
            rechnungsAdresseId: undefined,
            rechnungsAdresse: {
                strasse: org.addressBillingStreet,
                plz: org.addressBillingPostcode,
                ort: org.addressBillingCity,
                bundesland: org.addressBillingState,
                land: org.addressBillingCountry
            },
            telefon: org.phone,
            mobile: '',     // Organisations typically don't have a mobile number
            email: '',      // Organisations typically don't have an email address
            debitorenNummer: org.debitorenNummer,
            externalCrmKundeId: org.organisationId
        } as unknown as KundeVersion;
    }
}
