import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KundenTypOptions } from './kunden-typ-options';

@Component({
    selector: 'effimod-kunden-typ-selector',
    templateUrl: './kunden-typ-selector.component.html',
    styleUrls: ['./kunden-typ-selector.component.scss']
})
export class KundenTypSelectorComponent implements OnInit {

    @Input()
    useBlockDisplay: boolean = false;

    @Input()
    hideLabel: boolean = false;

    @Input()
    defaultKundenTypSelection: KundenTypOptions = KundenTypOptions.organisation;

    @Input()
    isReadonly: boolean = false;

    @Output()
    isOrganisationSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

    isOrganisation: boolean = true;

    constructor() { }

    ngOnInit() {
        this.isOrganisation = this.defaultKundenTypSelection === KundenTypOptions.organisation ? true : false;
        this.kundenTypSelectionSet();
    }


    kundenTypSelectionSet() {
        this.isOrganisationSelected.emit(this.isOrganisation);
    }
}
