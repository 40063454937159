import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface RouterState {
    url: string;
    breadcrumbs: { route: string, name: string }[];
    identifier: string;
    queryParams: {};
}

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterState> {

    constructor() {}

    serialize(routerState: RouterStateSnapshot): RouterState {
        const { url } = routerState;
        const breadcrumbs = [{ route: '/', name: 'Home' }];
        let identifier = null;
        const urlSegments: string[] = [];
        let fullPath = '';

        let state: ActivatedRouteSnapshot = routerState.root;
        while (state.firstChild) {
            state = state.firstChild;
            if (state.url.length > 0) {
                const segmentPath = state.url.map(segment => segment.path).join('/');
                urlSegments.push(segmentPath);
                fullPath += `/${segmentPath}`;

                const name = segmentPath.charAt(0).toUpperCase() + segmentPath.slice(1);
                breadcrumbs.push({
                    route: fullPath,
                    name
                });

                if (!identifier && state.params.id) {
                    identifier = state.params.id;
                }
            }
        }

        return {
            url,
            breadcrumbs,
            identifier,
            queryParams: state.queryParams
        };
    }
}
