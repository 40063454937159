import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';
import { EffimodPermissionEnum } from '../shared/enums/permission.enum';

@Injectable({
    providedIn: 'root'
})
export class CheckAuthorizationGuard implements CanActivate {

    constructor(private authorization: AuthorizationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        const requiredPermission = route.data.permission as EffimodPermissionEnum;
        return this.authorization.checkCurrentUserForPermission(requiredPermission);
    }
}
