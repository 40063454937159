import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsightlyContact, InsightlyOrganisation } from './insightly-data.types';

@Injectable({
    providedIn: 'root'
})
export class InsightlyDataService {
    constructor(private apollo: Apollo) {}

    searchContacts(keywords: string): Observable<InsightlyContact[]> {
        return this.apollo.query({
            query: gql`
                query($keywords: String!) {
                    privateApi {
                        insightly {
                            contacts {
                                getByLastname(keywords: $keywords) {
                                    contactId
                                    salutation
                                    title
                                    email
                                    firstName
                                    lastName
                                    name
                                    organisationId
                                    addressStreet
                                    addressPostcode
                                    addressCity
                                    addressState
                                    addressCountry
                                    debitorenNummer
                                    phone
                                    phoneMobile
                                }
                            }
                        }
                    }
                }
            `,
            variables: { keywords }
        }).pipe(
            map((result: any) => result.data.privateApi.insightly.contacts.getByLastname as InsightlyContact[])
        );
    }

    searchOrganisations(keywords: string): Observable<InsightlyOrganisation[]> {
        return this.apollo.query({
            query: gql`
                query($keywords: String!) {
                    privateApi {
                        insightly {
                            organisations {
                                getByOrganisationName(keywords: $keywords) {
                                    organisationId
                                    organisationName
                                    name
                                    addressBillingStreet
                                    addressBillingPostcode
                                    addressBillingCity
                                    addressBillingState
                                    addressBillingCountry
                                    addressShipStreet
                                    addressShipPostcode
                                    addressShipCity
                                    addressShipState
                                    addressShipCountry
                                    debitorenNummer
                                    phone
                                }
                            }
                        }
                    }
                }
            `,
            variables: { keywords }
        }).pipe(
            map((result: any) => result.data.privateApi.insightly.organisations.getByOrganisationName as InsightlyOrganisation[])
        );
    }
}
