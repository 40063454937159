import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Veranstaltung } from '../interfaces/veranstaltung.interface';
import {
    AbschliessenVeranstaltungMutationGQL,
    AnzahlungenVerarbeitenMutationGQL,
    CancelVeranstaltungMutationGQL,
    CopyVeranstaltungMutationGQL,
    CreateVeranstaltungFromBlockierungenMutationGQL,
    CreateVeranstaltungMutationGQL,
    DeleteVeranstaltungMutationGQL,
    UpdateAnsprechpersonenMutationGQL,
    UpdateVeranstaltungStatusMutationGQL
} from '../graphql/veranstaltungen/veranstaltungen.mutations';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { NotificationUrgency } from '../../shared/interfaces/notification.interface';
import { NotificationService } from '../../shared/services/notification.service';
import { Router } from '@angular/router';
import { EntityService } from '../../shared/interfaces/entity-service.class';
import { NumberFilter, TextFilter } from '../interfaces/base-entity-filter.interface';
import { Apollo } from 'apollo-angular';
import { getVeranstaltungen, VeranstaltungenApi, VeranstaltungenResponse } from '../graphql/veranstaltungen/veranstaltungen.gql';
import { VeranstaltungInput } from '../interfaces/veranstaltung-input';
import { GraphQLVeranstaltungUpdateInput } from '../graphql/veranstaltungen/input/veranstaltung-update.input';
import { GraphQLAnsprechpersonenUpdateInput } from '../graphql/veranstaltungen/input/ansprechpersonen-update.input';
import { GraphQLVeranstaltungStatusUpdateInput } from '../graphql/veranstaltungen/input/veranstaltung-status-update.input';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BlockierungenKovertierenZuVeranstaltungenInputType } from '../graphql/blockierungen/input/blockierungen-kovertieren-zu-veranstaltungen-input.type';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class VeranstaltungenDataService extends EntityService {

    public loadedVeranstaltungenSubject: BehaviorSubject<Veranstaltung[]> = new BehaviorSubject<Veranstaltung[]>([]);
    public currentVeranstaltungSubject: BehaviorSubject<Veranstaltung> = new BehaviorSubject<Veranstaltung>(null);
    public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public loadedVeranstaltungen$: Observable<Veranstaltung[]> = this.loadedVeranstaltungenSubject;
    public currentVeranstaltung$: Observable<Veranstaltung> = this.currentVeranstaltungSubject;
    public loading$: Observable<boolean> = this.loadingSubject;

    private veranstaltungLookup$: Subject<void> = new Subject();

    constructor(private apollo: Apollo,
                private httpClient: HttpClient,
                private createVeranstaltungMutation: CreateVeranstaltungMutationGQL,
                private deleteVeranstaltungMutation: DeleteVeranstaltungMutationGQL,
                private cancelVeranstaltungMutation: CancelVeranstaltungMutationGQL,
                private copyVeranstaltungMutation: CopyVeranstaltungMutationGQL,
                private abschliessenVeranstaltungMutation: AbschliessenVeranstaltungMutationGQL,
                private updateAnsprechpersonenMutation: UpdateAnsprechpersonenMutationGQL,
                private updateVeranstaltungStatusMutation: UpdateVeranstaltungStatusMutationGQL,
                private anzahlungenVerarbeitenMutation: AnzahlungenVerarbeitenMutationGQL,
                private createVeranstaltungFromBlockierungenMutationGQL: CreateVeranstaltungFromBlockierungenMutationGQL,
                private store: Store<AppState>,
                private notificationService: NotificationService,
                private location: Location,
                private router: Router) {
        super();
        this.setupVeranstaltungLookup();
    }

    public setupVeranstaltungLookup() {
        this.veranstaltungLookup$.pipe(
            switchMap(() => this.apollo.subscribe<VeranstaltungenResponse>({query: getVeranstaltungen(this.currentFilter, false)}))
        ).subscribe(
            (res) => {
                this.loadingSubject.next(false);
                this.loadedVeranstaltungenSubject.next(res.data.privateApi.veranstaltungen.alleVeranstaltungen);
            },
            (error) => {
                console.error(error);
                this.loadingSubject.next(false);
            }
        );
    }

    public loadEntities(): void {
        this.loadingSubject.next(true);
        this.veranstaltungLookup$.next();
    }


    public loadEntity(id: string): void {
        this.loadingSubject.next(true);
        this.apollo.subscribe<VeranstaltungenResponse>({
            query: getVeranstaltungen({
                take: 1,
                skip: 0,
                filterBy: [
                    new NumberFilter({field: 'id', operator: 'eq', value: id})
                ]
            }, true)
        }).subscribe(
            (res) => {
                this.loadingSubject.next(false);
                this.currentVeranstaltungSubject.next(res.data.privateApi.veranstaltungen.alleVeranstaltungen[0]);
            },
            (error) => {
                this.loadingSubject.next(false);
                console.error(error);
            }
        );
    }

    public loadVeranstaltungenFromToday(): Observable<Veranstaltung[]> {
        this.initTodayFilter();
        return this.apollo.subscribe<VeranstaltungenResponse>({query: getVeranstaltungen(this.currentFilter, false)})
            .pipe(
                map(res => {
                    return res.data.privateApi.veranstaltungen.alleVeranstaltungen;
                }),
                tap(res => {
                    this.loadedVeranstaltungenSubject.next(res);
                })
            );
    }

    public createVeranstaltung(veranstaltungInput: VeranstaltungInput): void {
        this.loadingSubject.next(true);
        this.createVeranstaltungMutation.mutate({veranstaltungInput}).subscribe(
            res => {
                this.loadingSubject.next(false);
                this.notificationService.notifiy({
                    text: `Veranstaltung erstellt`,
                    urgency: NotificationUrgency.success
                });
                const newVeranstaltungId = res.data.veranstaltungen.erstellen.id;
                this.router.navigateByUrl('veranstaltungen/' + newVeranstaltungId);
            },
            _ => {
                this.loadingSubject.next(false);
                this.notificationService.notifiy({
                    text: `Fehler bei der Erstellung der Veranstaltung mit dem Titel ${veranstaltungInput.titel}`,
                    urgency: NotificationUrgency.error
                });
            }
        );
    }

    deleteVeranstaltung(veranstaltungId: number, withNavigationAfterSuccess: boolean = true): void {
        this.deleteVeranstaltungMutation.mutate({veranstaltungId})
            .subscribe(_ => {
                this.loadEntities();
                if (withNavigationAfterSuccess) {
                    this.location.back();
                }
                this.notificationService.notifySuccess(`Provisorische Veranstaltung wurde gelöscht`);
            });
    }

    anzahlungVerarbeiten(veranstaltungId: number): Observable<boolean> {
        return this.anzahlungenVerarbeitenMutation.mutate({id: veranstaltungId})
            .pipe(
                tap(res => {
                    if (res.data.veranstaltungen.anzahlungVerarbeiten) {
                        this.notificationService.notifiy({
                            text: `Die Anzahlungsrechnung wurde an Garaio REM übermittelt.`,
                            urgency: NotificationUrgency.success
                        });
                    } else {
                        this.notificationService.notifiy({
                            text: `Die Anzahlungsrechnung konnte nicht verarbeitet werden.`,
                            urgency: NotificationUrgency.error
                        });
                    }
                }),
                map(res => {
                    return res.data.veranstaltungen.anzahlungVerarbeiten;
                })
            );
    }

    cancelVeranstaltung(veranstaltungId: number): void {
        this.cancelVeranstaltungMutation.mutate({veranstaltungId})
            .subscribe(res => {
                this.loadEntity(veranstaltungId + '');
                this.router.navigateByUrl('veranstaltungen/' + veranstaltungId);

                if (res.data.veranstaltungen.annullieren) {
                    this.notificationService.notifiy({
                        text: `Veranstaltung annulliert`,
                        urgency: NotificationUrgency.success
                    });
                } else {
                    this.notificationService.notifiy({
                        text: `Veranstaltung konnte nicht annulliert werden`,
                        urgency: NotificationUrgency.error
                    });
                }
            }),
            catchError(() => {
                this.notificationService.notifiy({
                    text: `Fehler beim annullieren der Veranstaltung`,
                    urgency: NotificationUrgency.error
                });
                return of(false);
            });
    }

    copyVeranstaltung(veranstaltungId: number): Observable<Veranstaltung> {
        return this.copyVeranstaltungMutation.mutate({veranstaltungId})
            .pipe(
                map(res => res.data.veranstaltungen.kopieren)
            );
    }

    updateAnsprechpersonen(ansprechpersonenUpdate: GraphQLAnsprechpersonenUpdateInput): Observable<any> {
        return this.updateAnsprechpersonenMutation.mutate({
            ansprechpersonenUpdate: {
                veranstaltungenIds: ansprechpersonenUpdate.veranstaltungenIds,
                newAnsprechpersonVersionId: ansprechpersonenUpdate.newAnsprechpersonVersionId
            }
        });
    }

    // updateAnsprechpersonen(ansprechpersonenUpdate: GraphQLAnsprechpersonenUpdateInput): void {
    //     this.updateAnsprechpersonenMutation
    //         .mutate(ansprechpersonenUpdate)
    //         .subscribe(
    //             (_) => {
    //                 this.loadEntities();
    //                 this.notificationService.notifiy({
    //                     text: `Die Ansprechpersonen wurden angepasst`,
    //                     urgency: NotificationUrgency.success
    //                 });
    //             },
    //             (error) => {
    //                 console.error(error);
    //                 this.notificationService.notifiy({
    //                     text: `Fehler beim Aktualisieren der Ansprechpersonen`,
    //                     urgency: NotificationUrgency.error
    //                 });
    //             }
    //         );
    // }

    updateVeranstaltungStatus(veranstaltungStatusUpdate: GraphQLVeranstaltungStatusUpdateInput): Observable<any> {
        return this.updateVeranstaltungStatusMutation.mutate({
            veranstaltungStatusUpdate
        });
    }

    updateVeranstaltung(veranstaltungUpdateInput: GraphQLVeranstaltungUpdateInput): void {
        const mutationOptions = {
            mutation: VeranstaltungenApi.mutations.update,
            variables: {veranstaltung: veranstaltungUpdateInput}
        };
        this.apollo.mutate<any>(mutationOptions)
            .subscribe(v => {
                // TODO: You don't want trigger two HTTP calls here but for the sake of consistency this is currently used as a workaround (discussed with Rustom 26.11)
                this.loadEntities();
                this.loadEntity(v.data.veranstaltungen.bearbeiten.id);

                this.notificationService.notifiy({
                    text: `Veranstaltung geändert`,
                    urgency: NotificationUrgency.success
                });
            });
    }

    abschliessen(veranstaltungId: number): void {
        this.abschliessenVeranstaltungMutation.mutate({veranstaltungId})
            .subscribe(res => {
                this.loadEntity(veranstaltungId + '');
                this.router.navigateByUrl('veranstaltungen/' + veranstaltungId);

                if (res.data.veranstaltungen.abschliessen) {
                    this.notificationService.notifiy({
                        text: `Veranstaltung abgeschlossen`,
                        urgency: NotificationUrgency.success
                    });
                } else {
                    this.notificationService.notifiy({
                        text: `Veranstaltung konnte nicht abgeschlossen werden`,
                        urgency: NotificationUrgency.error
                    });
                }
            }),
            catchError(() => {
                this.notificationService.notifiy({
                    text: `Fehler beim Abschliessen der Veranstaltung`,
                    urgency: NotificationUrgency.error
                });
                return of(false);
            });
    }

    createVeranstaltungFromBlockierungen(args: BlockierungenKovertierenZuVeranstaltungenInputType): Observable<Veranstaltung> {
        return this.createVeranstaltungFromBlockierungenMutationGQL
            .mutate({blockierungenKonvertieren: args})
            .pipe(
                map(res => {
                    return res.data.veranstaltungen.blockierungenKonvertierenZuVeranstaltung;
                }),
                tap(newVeranstaltung => {
                    this.loadedVeranstaltungenSubject.next([...this.loadedVeranstaltungenSubject.value, newVeranstaltung]);
                    return newVeranstaltung;
                })
            );
    }

    public absagen(veranstaltungId: number): Observable<any> {
        const apiUrl = `${environment.api.rest}/veranstaltungen/${veranstaltungId}/status/absage`;
        return this.httpClient.put<any>(apiUrl, {});
    }
}
