import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailService } from '../../services/email.service';
import { BenachrichtigungsEmail } from '../../interfaces/benachrichtigungsEmail';
import { NotificationService } from '../../services/notification.service';
import { NotificationUrgency } from '../../interfaces/notification.interface';
import { BenachrichtigungsLogInterface } from '../../interfaces/benachrichtigungs-log.interface';
import * as fromStore from '../../../store';
import { filter, first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

@Component({
    selector: 'effimod-benachrichtigung-modal',
    templateUrl: './benachrichtigung-modal.component.html',
    styleUrls: ['./benachrichtigung-modal.component.scss']
})
export class BenachrichtigungModalComponent implements OnInit {
    @Input() isActive: boolean;
    @Input() id: string;
    @Input() titel: string;
    @Input() typ: string;

    public sachbearbeiterOptions$: Observable<{name: string, id: number, hide: boolean}[]>;
    sachbearbeiterOption = 'Event-Team Technopark Zürich';
    isSubmitButtonDisabled = true;

    @Output() closeModal = new EventEmitter<any>();

    logs: BenachrichtigungsLogInterface[];

    mailBody: string;
    recipients: {active?: boolean, identifier: string, name: string}[] = [
        {name: 'Wache',      identifier: 'wache ag'},
        {name: 'ZFV',           identifier: 'zfv'},
        {name: 'Equans',      identifier: 'equans'},
        {name: 'Armit',         identifier: 'armit'}
    ];

    constructor(private emailService: EmailService, private notificationService: NotificationService, private store: Store<AppState>) { }


    ngOnInit() {
        this.loadUsers();
        this.loadLogs();
        this.mailBody = `Guten Tag, es hat eine Änderung/Ergänzung in der ${this.typ} ${this.id} gegeben.
Beste Grüsse
`;
     }

    loadUsers()  {
        this.sachbearbeiterOptions$ = this.store.select(fromStore.getUserEntities).pipe(
            filter(users => {
                return users !== undefined && Object.keys(users).length > 0;
            }),
            first(),
            map(users => {
                const userValues = Object.values(users);
                let options = userValues.map(u => ({name: (u.vorname + ' ' + u.nachname), id: u.id, hide: u.isInaktiv}));
                options = [{name: 'Event-Team Technopark Zürich', id: 0, hide: false}].concat(options);
                return options;
            })
        );
    }

    loadLogs() {
        let logsTmp;
        if (this.typ === 'Veranstaltung') {
            logsTmp = this.emailService.getBenachrichtigungsHistoryForVeranstaltung(this.id);
        } else if (this.typ === 'Reservation') {
            logsTmp = this.emailService.getBenachrichtigungsHistoryForReservation(this.id);
        }
        logsTmp.subscribe(res => {
            this.logs = res;
        });
    }

    sendEmail() {
        const chosenRecipients = this.recipients.filter(x => x.active).map(x => x.identifier);
        const email: BenachrichtigungsEmail = this.emailService.generateAenderungsEmail(this.typ, this.id, this.titel, this.mailBody, chosenRecipients, this.sachbearbeiterOption);

        this.emailService.sendEmail(email).subscribe(res => {
            this.closeModal.emit();
            // reload logs
            this.loadLogs();

            this.notificationService.notifiy({
                text: `Benachrichtigung Versand Erfolgreich`,
                urgency: NotificationUrgency.success
            });
        },
        err => {
            this.notificationService.notifiy({
                text: err.message,
                urgency: NotificationUrgency.error
            });
        });
    }

    selectionChanged($event: any) {
        this.isSubmitButtonDisabled = !this.recipients.map(x => x.active).some(x => x);
    }
}
