export enum VoucherDurationInDaysModel {
    OneDay = 1, // isiCode=28,
    TwoDays = 2, // isiCode=29,
    ThreeDays = 3, // isiCode=30,
    FourDays = 4, // isiCode=31,
    OneWeek = 7, // isiCode=32,
    TwoWeeks = 14, // isiCode=33
    ThreeWeeks = 21, // isiCode=34
    FourWeeks = 28, // isiCode=35
}
