import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-relation-selector',
    templateUrl: './relation-selector.component.html',
    styleUrls: ['./relation-selector.component.scss']
})
export class RelationSelectorComponent implements OnInit {

    @Input() parent: FormGroup;
    @Input() inputId: string;
    @Input() label: string;
    @Input() formControlNameString: string;
    @Input() relationObjects: object[];
    @Input() selectedObject: object;
    @Input() valueString: string;

    constructor() {
    }

    ngOnInit() {
    }

}
