import { Pipe, PipeTransform } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';

@Pipe({
    name: 'veranstaltungenBatchBearbeitungButtonTooltip'
})
export class VeranstaltungenBatchBearbeitungButtonTooltipPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        const veranstaltungen = value as Veranstaltung[];

        if (!veranstaltungen || !veranstaltungen.length) {
            return 'Welche Veranstaltungen sollen bearbeitet werden?';
        }

        if (veranstaltungen.some(x => x.isSealed)) {
            return 'Einige der Veranstaltungen dürfen nicht bearbeitet werden.'
        }

        return 'Veranstaltungen bearbeiten';
    }

}
