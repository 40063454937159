import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { NotificationService } from '../../services/notification.service';
import { NotificationUrgency } from '../../interfaces/notification.interface';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { VeranstaltungenBatchBearbeitungService } from '../../services/veranstaltungen-batch-bearbeitung.service';

@Component({
    selector: 'effimod-veranstaltungen-batch-bearbeitung-modal',
    templateUrl: './veranstaltungen-batch-bearbeitung-modal.component.html',
    styleUrls: ['./veranstaltungen-batch-bearbeitung-modal.component.scss']
})
export class VeranstaltungenBatchBearbeitungModalComponent {
    @Input() readonly isModalDisplayed: boolean;
    @Input() readonly veranstaltungen: Veranstaltung[];
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
    modalCheckTitle: string = 'Veranstaltungen bearbeiten';
    tiagInfo: string;
    effimodPermissionEnum = EffimodPermissionEnum;

    constructor(private veranstaltungenBatchBearbeitungService: VeranstaltungenBatchBearbeitungService, private notificationService: NotificationService) { }

    modalCheckConfirmation() {
        const veranstaltungIds: number[] = this.veranstaltungen.map(x => x.id);
        this.veranstaltungenBatchBearbeitungService.appendTiaginfo(veranstaltungIds, this.tiagInfo).subscribe(
            res => {
                this.notificationService.notifiy({
                    text: `Die gewünschten Daten wurden erfolgreich übermittelt`,
                    urgency: NotificationUrgency.success
                });
                this.tiagInfo = null;
            },
            err => {
                const errorMessage = `${err.error ? ': ' + err.error : ''}`;
                this.notificationService.notifiy({
                    text: `Die Übermittlung der Daten ist fehlgeschlagen${errorMessage}`,
                    urgency: NotificationUrgency.error
                });
            },
            () => { this.modalClosed.emit(); }
        );
    }
}
