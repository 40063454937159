import { Injectable } from '@angular/core';
import { Veranstaltung } from '../../data/interfaces/veranstaltung.interface';
import { VeranstaltungStatus } from '../../data/interfaces/VeranstaltungStatus';

@Injectable({
    providedIn: 'root'
})
export class VeranstaltungBearbeitenPermissionCheckService {

    constructor() { }

    areVeranstaltungenBearbeitbar(veranstaltungen: Veranstaltung[]): boolean {
        if (!veranstaltungen || !veranstaltungen.length) {
            return false;
        }

        return veranstaltungen.every(x => x.status && (this.isProvisorisch(x.status) || this.isDefinitiv(x.status))) === true;
    }

    private isProvisorisch(status: VeranstaltungStatus): boolean {
        return this.equals(status.bezeichnung, 'Provisorisch');

    }

    private isDefinitiv(status: VeranstaltungStatus): boolean {
        return this.equals(status.bezeichnung, 'Definitiv');
    }

    private equals(text1: string, text2: string) {
        if (!text1 && !text2) {
            return true;
        }

        if (!text1 || !text2) {
            return false;
        }

        return text1.toUpperCase() === text2.toUpperCase();
    }
}
