import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions';
import * as fromHelpers from '../helpers';
import { User } from '../../data/interfaces/user.interface';
import { Auth0User } from '../../data/interfaces/auth0-user.interface';

export interface UserState {
    currentUser: User;
    entities: { [auth0Id: string]: User };
    loading: boolean;
    loaded: boolean;
    errorMessage: string;
}

const initialState: UserState = {
    currentUser: null,
    entities: {},
    loading: false,
    loaded: false,
    errorMessage: null
};

export function userReducerFn(state: UserState, action: Action) {
    return userReducer(state, action);
}

export const userReducer = createReducer(
    initialState,
    on(fromActions.loadUsers, (state) => {
        return {...state, loading: true, loaded: false, errorMessage: null};
    }),
    on(fromActions.loadUsersSuccess, (state, {entities}) => {
        const results = fromHelpers.addItems<User>(state.entities, entities);
        return {...state, loading: false, loaded: true, errorMessage: null, entities: results};
    }),
    on(fromActions.loadUsersFail, (state, {errorMessage}) => {
        return {...state, loading: false, loaded: false, errorMessage};
    }),
    on(fromActions.storeCurrentUser, (state, {auth0User}) => {
        const mappedUser = mapAuth0UserToUserModel(auth0User);
        return {...state, loading: true, loaded: false, errorMessage: null, currentUser: mappedUser};
    }),
    on(fromActions.storeCurrentUserSuccess, (state, {currentUser}) => {
        return {...state, loading: false, loaded: true, errorMessage: null, currentUser};
    }),
    on(fromActions.storeCurrentUserFail, (state, {errorMessage}) => {
        return {...state, loading: false, loaded: false, errorMessage};
    })
);

function mapAuth0UserToUserModel(auth0User: Auth0User) {
    const user = {
        auth0Id: auth0User.sub,
        vorname: auth0User['https://effimod-api.ecodev.io/user_metadata'].vorname,
        nachname: auth0User['https://effimod-api.ecodev.io/user_metadata'].nachname,
        isInaktiv: auth0User['https://effimod-api.ecodev.io/user_metadata'].sachbearbeiter,
        username: auth0User.nickname,
        permissions: auth0User.permissions
    } as User;
    return user;
}


