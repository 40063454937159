import { BaseEntity } from '../../data/interfaces/base-entity.interface';

export function addItems<T extends BaseEntity>(entities: { [id: number]: T }, newEntities: T[]): { [id: number]: T } {
    return newEntities.reduce(
        (items: { [id: number]: T }, item: T) => {
            return {
                ...items,
                [item.id]: item
            };
        },
        {
            ...entities
        }
    );
}
export function addItemsArray<T extends BaseEntity>(entities: T[], newEntities: T[]): T[] {
    return newEntities.reduce(
        (items: T[], item: T) => {
            return {
                ...items,
                item
            };
        },
        {
            ...entities
        }
    );
}

