import { PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';

export interface EffimodTableDefinition {
    idColumnSelector: string;
    columns: EffimodTableColumnDefinition[];
    sortingDisabled?: boolean;
    enableRowEditMode?: boolean;
    enableAddNewRow?: boolean;
    contentItemModelTemplate?: any;
    navigatable?: boolean; // defines if row navigation is possible on table -> ! see excemptFromNavigation on column definition
    selectMultiple?: boolean;
    canDelete?: boolean;
}

export interface EffimodTableColumnDefinition {
    header: string;
    fieldSelector: string[] | any;
    sortingDisabled?: boolean;
    pipe?: PipeTransform;
    pipeArgs?: any[];
    routerLinkBase?: string;
    insightlyLink?: boolean;
    filterField?: string[] | any;
    filterType?: EffimodTableFilterType;
    filterName?: string;
    operator?: string;
    selectionOptions?: SelectItem[];
    inputType?: EffimodTableInputType;
    editable?: boolean;
    printHide?: boolean;
    printWidth?: number;
    printPullRight?: boolean;
    printCenter?: boolean;
    printClipText?: boolean; // be aware, this was created as a tricky and hacky way to remove the time from dates in printviews
    exemptFromNavigation?: boolean; // defines whether a column should be exempt from row navigation
    width?: number | 'auto';
    styleClass?: string;
    pullRight?: boolean;
}

export interface EffimodTableFilterEvent {
    fieldSelector?: string[];
    filterField?: string[];
    filterName?: string;
    filterType: EffimodTableFilterType;
    operator?: string;
    value: string | boolean;
}

export interface EffimodTableSortingEvent {
    fieldSelector: string[];
    order: number;
}

export enum EffimodTableFilterType {
    text = 'text',
    date = 'date',
    selection = 'selection',
    number = 'number',
    checkbox = 'checkbox'
}

export enum EffimodTableInputType {
    text = 'text',
    date = 'date',
    selection = 'selection',
    number = 'number',
    checkbox = 'checkbox'
}

export interface EffimodTableSelectionEvent {
    selection: any[];
}

export interface EffimodTableFilterKeyValue {
    [filterName: string]: EffimodTableFilterEvent;
}

// export const EffimodTableFilterOperators = {
//     STARTS_WITH: 'startsWith',
//     GT: 'gt',
//     LT: 'lt',
//     EQ: 'eq',
//     NEQ: 'neq',
//     CONTAINS: 'contains',
//     EQUALS: 'equals',
//     ENDS_WITH: 'endsWith',
// };
