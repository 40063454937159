import { Injectable } from '@angular/core';
import { EffimodKontakteDataService } from '../../data/services/kunden/effimod-kontakte-data.service';
import { NotificationService } from './notification.service';
import { KundeVersion } from '../../data/interfaces/kunde-version.interface';

@Injectable({
    providedIn: 'root'
})
export class KundeVersionSyncService {
    constructor(
        private effimodKontakteDataService: EffimodKontakteDataService,
        private notificationService: NotificationService
    ) {}

    async syncInsightlyKunde(externalCrmKundeId: string, isFirma: boolean): Promise<KundeVersion | undefined> {
        try {
            const syncedKunde = await this.effimodKontakteDataService.syncKundeByExternalCrmId(externalCrmKundeId, isFirma).toPromise();
            if (syncedKunde) {
                return syncedKunde;
            } else {
                throw new Error('No Kunde returned from sync.');
            }
        } catch (error) {
            this.notificationService.notifyError('Fehler beim Synchronisieren des Kunden. Bitte melden Sie sich beim Sytemadministrator und teilen Sie ihm die Kundendaten mit.');
            console.error('Error syncing Kunde:', error);
        }
    }
}
