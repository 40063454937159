export interface Notification {
    id: number;
    text: string;
    urgency: NotificationUrgency;
}

export enum NotificationUrgency {
    info = 'is-primary',
    success = 'is-success',
    error = 'is-danger',
}
