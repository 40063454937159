import { Component, Input, OnInit } from '@angular/core';
import { VoucherDurationBezeichnungModel } from '../../../internet-services/models/voucher-duration-bezeichnung.model';
import { VoucherDurationInDaysModel } from '../../../internet-services/models/voucher-duration-in-days.model';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-voucher-duration-selector-form',
    templateUrl: './voucher-duration-selector-form.component.html',
    styleUrls: ['./voucher-duration-selector-form.component.scss']
})
export class VoucherDurationSelectorFormComponent implements OnInit {

    public voucherDauerNames = VoucherDurationBezeichnungModel;
    public voucherDauerValues = VoucherDurationInDaysModel;

    @Input() parent: FormGroup;
    @Input() formControlNameString: string;
    @Input() formControlLabelText: string;

    constructor() { }

    ngOnInit() {
    }

}
