import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GraphQLVeranstaltungStatusUpdateInput } from '../../../data/graphql/veranstaltungen/input/veranstaltung-status-update.input';
import { NotificationService } from '../../services/notification.service';
import { VeranstaltungenDataService } from '../../../data/services/veranstaltungen-data.service';
import { SelectedVeranstaltungenService } from '../../services/selected-veranstaltungen.service';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'effimod-veranstaltungen-status-provisorisch-zu-definitiv-button',
    templateUrl: './veranstaltungen-status-provisorisch-zu-definitiv-button.component.html',
    styleUrls: ['./veranstaltungen-status-provisorisch-zu-definitiv-button.component.scss']
})
export class VeranstaltungenStatusProvisorischZuDefinitivButtonComponent implements OnInit {

    @Output() statusChanged = new EventEmitter<any>();

    public readonly effimodPermissionEnum = EffimodPermissionEnum;
    public areAllSelectedProvisorisch$: Observable<boolean>;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly veranstaltungenDataService: VeranstaltungenDataService,
        protected readonly selectedVeranstaltungenService: SelectedVeranstaltungenService
    ) {}

    ngOnInit() {
        this.areAllSelectedProvisorisch$ = this.selectedVeranstaltungenService.areAllSelectedProvisorisch$();
    }

    changeVeranstaltungenStatus() {
        this.selectedVeranstaltungenService.areAllSelectedProvisorisch$()
            .pipe(first())
            .subscribe((areAllProvisorisch) => {
                if (!areAllProvisorisch) {
                    return;
                }

                const selectedVeranstaltungen = this.selectedVeranstaltungenService.getSelectedVeranstaltungen();
                const veranstaltungStatusUpdate: GraphQLVeranstaltungStatusUpdateInput = {
                    veranstaltungenId: selectedVeranstaltungen.map(v => v.id)
                };

                this.veranstaltungenDataService.updateVeranstaltungStatus(veranstaltungStatusUpdate).subscribe(() => {
                    this.statusChanged.emit();
                    this.notificationService.notifySuccess(`Provisorisch zu Definitiv geändert`);
                });
            });
    }
}
