import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';

@Injectable({providedIn: 'root'})
export class VeranstaltungStatusGuard implements CanActivate {
    constructor(private store: Store<fromStore.AppState>) {
    }

    canActivate(): Observable<boolean> {
        return this.checkStatus().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }

    checkStatus(): Observable<boolean> {
        return this.store.pipe(
            select(fromStore.getVeranstaltungStatusState),
            tap(state => {
                if (!state.loading && !state.loaded && !Object.keys(state.entities).length) {
                    this.store.dispatch(fromStore.loadVeranstaltungStatus());
                }
            }),
            map(state => {
                return state.loaded && !state.loading;
            }),
            take(1)
        );
    }
}
