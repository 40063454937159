import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterState } from '../reducers';


export const getRouterReducerState = createFeatureSelector<RouterReducerState<RouterState>>('routerReducer');
export const getRouterState = createSelector(getRouterReducerState, (state: RouterReducerState<RouterState>) => {
    return state ? state.state : null;
});
export const getBasePath = createSelector(getRouterState, (state: RouterState) => state ? state.breadcrumbs.map(b => b.route).join('') : null);
export const getBreadcrumbs = createSelector(getRouterState, (state: RouterState) => state ? state.breadcrumbs : null);
export const getIdentifier = createSelector(getRouterState, (state: RouterState) => state ? state.identifier : null);

