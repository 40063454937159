import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as fromStore from '../store';
import { AppState, getCurrentUser } from '../store';
import { Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';

// https://auth0.com/docs/quickstart/spa/angular2/01-login
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client({
            domain: 'technopark.eu.auth0.com',
            client_id: 'zI5WhB9NVB6nnQIt3DFMn0YfwuwMdLHb',
            redirect_uri: `${window.location.origin}`,
            audience: 'https://effimod-api.ecodev.io'
        })
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );
    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => this.loggedIn = res)
    );
    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();
    // Create a local property for login status
    loggedIn: boolean = null;

    constructor(private router: Router, private store: Store<AppState>) { }

    getTokenSilently$(options?): Observable<string> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => {
                return from(client.getTokenSilently(options)).pipe(
                    tap(token => {
                        this.store.select(getCurrentUser)
                            .pipe(take(1))
                            .subscribe(currentUser => {
                                if (!currentUser) {
                                    const decodedAccessToken = this.getDecodedAccessToken(token);
                                    this.store.dispatch(fromStore.storeCurrentUser({auth0User: decodedAccessToken}));
                                }
                            });
                    })
                );
            })
        );
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap(user => this.userProfileSubject$.next(user))
        );
    }

    localAuthSetup() {
        // This should only be called on app initialization
        // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            }),
            // todo the following call could probably be merged in the conctatMap from above...
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    return this.getTokenSilently$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe();
    }

    /**
     * Takes care of logging the user into the application.
     * @param redirectPath a desired redirect path can be passed to login method, (e.g., from a route guard).
     */
    login(redirectPath: string = '/') {
        // prevents infinite login loop. Don't try to login if already in the process.
        if (!redirectPath.includes('code=')) {
            // Ensure Auth0 client instance exists
            this.auth0Client$.subscribe((client: Auth0Client) => {
                // Call method to log in
                client.loginWithRedirect({
                    redirect_uri: `${window.location.origin}`,
                    appState: {target: redirectPath}
                });
            });
        }
    }

    handleAuthCallback() {
        // Call when app reloads after user logs in with Auth0
        const params = window.location.search;
        if (params.includes('code=') && params.includes('state=')) {
            let targetRoute: string; // Path to redirect to after login processsed
            const authComplete$ = this.handleRedirectCallback$.pipe(
                // Have client, now call method to handle auth callback redirect
                tap(cbRes => {
                    // Get and set target redirect route from callback results
                    targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
                }),
                concatMap(() => {
                    // Redirect callback complete; get user and login status
                    return combineLatest([
                        this.getUser$(),
                        this.isAuthenticated$
                    ]);
                })
            );
            // Subscribe to authentication completion observable
            // Response will be an array of user and login status
            authComplete$.subscribe(([user, loggedIn]) => {
                // Redirect to target route after callback processing
                this.router.navigate([targetRoute]);
            });
        }
    }

    logout() {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log out
            client.logout({
                client_id: 'zI5WhB9NVB6nnQIt3DFMn0YfwuwMdLHb',
                returnTo: `${window.location.origin}`
            });
        });
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
