import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationUrgency } from '../interfaces/notification.interface';

@Injectable({
    providedIn: 'root'
})
export class EffimodGraphqlErrorsInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // retry(1),
            tap(event => {
                if (event && event.type === HttpEventType.Response && event.body && event.body.errors) {
                    for (const error of event.body.errors) {
                        // the message does not need to be changed or cleaned of extra info, because it gets corrected by the backend already when deployed in production.
                        // const errorMessage = `${error.message.replace('GraphQL error:', '').replace('GraphQL.ExecutionError:', '')}`;
                        const errorMessage = `${error.message}`;
                        this.notificationService.notifiy({text: errorMessage, urgency: NotificationUrgency.error});
                    }
                }
            })
        );
    }
}
