import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KundeVersion } from 'src/app/data/interfaces/kunde-version.interface';

@Component({
    selector: 'effimod-simple-kunden-list',
    templateUrl: './simple-kunden-list.component.html',
    styleUrls: ['./simple-kunden-list.component.scss']
})
export class SimpleKundenListComponent implements OnInit {
    kundeTyp: string = 'organisation';

    @Input() kunden: KundeVersion[];
    @Output() kundeSelected: EventEmitter<KundeVersion> = new EventEmitter<KundeVersion>();

    constructor() { }

    ngOnInit() {
    }

    onSelection(kunde: KundeVersion): void {
        this.kundeSelected.emit(kunde);
    }
}
