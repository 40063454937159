import gql from 'graphql-tag';

export const AdminApi: any = {};

AdminApi.fragments = {
    auth0UsersForListing: gql`
        fragment Auth0UsersForListingFragment on UserType {
            id
            auth0Id
            username
            vorname
            nachname
            name
            isInaktiv
            sequence
            activeDirectoryName
            createdOn
        }
    `
};

AdminApi.queries = {
    alleUser: gql`
        query alleUserAbfragen($selectInactiveUsers: Boolean) {
            privateApi {
                admin {
                    alleUser(selectInactiveUsers: $selectInactiveUsers) {
                        ... Auth0UsersForListingFragment
                    }
                }
            }
        }
        ${AdminApi.fragments.auth0UsersForListing}
    `
};


