import * as moment from 'moment';

export interface BaseEntityFilter {
    take: number;
    skip: number;
    orderBy?: OrderBy[];
    filterBy?: AbstractFilter[];
}

export class OrderBy {
    field: string;
    direction: number;

    constructor(options: { field: string, direction: number }) {
        this.field = options.field;
        this.direction = options.direction;
    }

    toQuery(): string {
        return `${this.field}: ${this.direction}`;
    }
}

export abstract class AbstractFilter {
    field: string;
    value: string | number | moment.Moment | boolean;
    operator: string;

    constructor(options: { field: string, value: string | number | moment.Moment | boolean, operator: string }) {
        this.field = options.field;
        this.value = options.value;
        this.operator = options.operator;
    }

    toQuery(): string {
        const query = `${this.field}: {
            ${this.operator}: ${this.value}
        }`;
        return query;
    }
}

export class TextFilter extends AbstractFilter {
    value: string;
    operator: 'equals' | 'contains' | 'startsWith' | 'endsWith';

    toQuery(): string {
        return `${this.field}: {${this.operator}: "${this.value}"}`;
    }
}

export class DateTimeFilter extends AbstractFilter {
    value: moment.Moment;
    operator: 'eq' | 'neq' | 'lt' | 'gt';

    timeAddition: string = this.operator == 'lt' || this.operator == 'gt' ? ' HH:mm:ss' : '';

    toQuery(): string {
        return `${this.field}: {${this.operator}: "${this.value.format('YYYY-MM-DD' + this.timeAddition)}"}`;
    }
}

export class NumberFilter extends AbstractFilter {
    value: number;
    operator: 'eq' | 'neq' | 'lt' | 'gt';

    toQuery(): string {
        return `${this.field}: {${this.operator}: ${this.value}}`;
    }
}

export class SelectionFilter extends AbstractFilter {
    value: number;
    operator: 'eq';

    toQuery(): string {
        return `${this.field}: {${this.operator}: ${this.value}}`;
    }
}

export class CheckboxFilter extends AbstractFilter {
    value: boolean;
    operator: 'eq' | 'neq';

    constructor(options: { field: string, value: boolean, operator: string }) {
        super(options);
        this.value = options.value;
        this.operator = options.operator as 'eq' | 'neq';
    }

    toQuery(): string {
        return `${this.field}: {${this.operator}: ${this.value}}`;
    }
}
