import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KundeVersion } from '../../../../data/interfaces/kunde-version.interface';

@Component({
    selector: 'effimod-insightly-link',
    templateUrl: './insightly-link.component.html',
    styleUrls: ['./insightly-link.component.scss']
})
export class InsightlyLinkComponent implements OnInit, OnChanges {
    @Input() kundeVersion: KundeVersion;
    @Input() showName: boolean;
    @Input() showAdditionalInformation: boolean;

    public externalLink: string;
    public title: string;

    private readonly externalInsightlyLink: string = 'https://crm.na1.insightly.com/details/';

    ngOnInit() {
        this.setValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.kundeVersion) {
            this.setValues();
        }
    }

    private setValues() {
        if (!this.kundeVersion || !this.kundeVersion.externalCrmKundeId) {
            this.resetValues();
            return;
        }

        const kundenType = this.kundeVersion.kunde.isFirma ? 'Organisation' : 'Contact';
        this.externalLink = `${this.externalInsightlyLink}${kundenType}/${this.kundeVersion.externalCrmKundeId}`;
        this.title = `Insightly öffnen mit ${kundenType} ID: ${this.kundeVersion.externalCrmKundeId}`;
    }

    private resetValues() {
        this.externalLink = '';
        this.title = 'Link zum CRM: nicht vorhanden.';
    }
}
