import { EventEmitter, Input, Output } from '@angular/core';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { NotificationService } from '../../services/notification.service';
import { KundeVersionSyncService } from '../../services/kunde-version-sync.service';

export abstract class BaseKundeSelectionModalButtonComponent {

    @Input() buttonText: string = 'ändern';
    @Input() buttonTooltip: string;
    @Input() buttonClasses: string = '';
    @Input() disabled: boolean;
    @Input() disabledReason: string;
    @Input() preselectedKundenTypOption: KundenTypOptions;
    @Input() modalTitle: string;
    @Input() modalSubmitButtonText: string;
    @Input() modalSubmitButtonDisabledReason: string;

    @Output() selected = new EventEmitter<KundeVersion>();

    currentKundeSelection: KundeVersion;

    constructor(
        protected notificationService: NotificationService,
        protected kundeVersionSyncService: KundeVersionSyncService
    ) {}

    async onKundeSelectionConfirmed() {
        if (!this.currentKundeSelection) {
            this.notificationService.notifyError('Bitte wählen Sie einen Kunden aus.');
            return;
        }

        if (!this.currentKundeSelection.kunde) {
            this.notificationService.notifyError('Der Stammkunde für die gewählte Kundeversion konnte nicht gefunden werden.');
            return;
        }

        if (this.currentKundeSelection.kunde.isFirma && !this.currentKundeSelection.externalCrmKundeId) {
            this.notificationService.notifyError('Firmen können nicht ohne CRM-ID synchronisiert werden.');
            return;
        }

        if (this.currentKundeSelection.externalCrmKundeId) {
            const syncedKunde = await this.kundeVersionSyncService.syncInsightlyKunde(this.currentKundeSelection.externalCrmKundeId, this.currentKundeSelection.kunde.isFirma);
            if (syncedKunde) {
                this.selected.emit(syncedKunde);
            } else {
                this.notificationService.notifyError('Der Kunde konnte nicht synchronisiert werden.');
            }
        } else if (this.currentKundeSelection.id) {
            this.selected.emit(this.currentKundeSelection);
        } else {
            this.notificationService.notifyError('Der gewählte Kunde konnte nicht verarbeitet werden.');
        }
    }
}
