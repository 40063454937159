import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as fromStore from '../../store';

@Component({
    selector: 'effimod-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {

    public displayBack$: Observable<boolean>;
    public displayForward$: Observable<boolean>;
    public breadcrumbs$: Observable<{ route: string, name: string }[]>;

    private basePath: string;
    private basePathSubscription: Subscription;

    constructor(private store: Store<fromStore.AppState>) {
    }

    ngOnInit() {
        this.displayBack$ = this.store.pipe(select(fromStore.canNavigateBack));
        this.displayForward$ = this.store.pipe(select(fromStore.canNavigateForward));
        this.breadcrumbs$ = this.store.pipe(select(fromStore.getBreadcrumbs));
        this.basePathSubscription = this.store.pipe(select(fromStore.getBasePath)).subscribe((basePath) => this.basePath = basePath);
    }

    ngOnDestroy(): void {
        if (this.basePathSubscription) {
            this.basePathSubscription.unsubscribe();
        }
    }

    public navigateBack() {
        this.store.dispatch(fromStore.loadPreviousItem({basePath: this.basePath}));
    }

    public navigateForward() {
        this.store.dispatch(fromStore.loadNextItem({basePath: this.basePath}));
    }

}
