import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import * as fromReducers from './reducers';

export * from './reducers';
export * from './actions';
export * from './effects';
export * from './selectors';
export * from './helpers';

export interface AppState {
    routerReducer: RouterReducerState<fromReducers.RouterState>;
    artikel: fromReducers.ArtikelState;
    gebaeude: fromReducers.GebaeudeState;
    navigation: fromReducers.NavigationState;
    veranstaltungStatus: fromReducers.VeranstaltungStatusState;
    users: fromReducers.UserState;
}

export const reducers: ActionReducerMap<AppState> = {
    routerReducer,
    artikel: fromReducers.artikelReducerFn,
    gebaeude: fromReducers.gebaeudeReducerFn,
    navigation: fromReducers.navigationReducerFn,
    veranstaltungStatus: fromReducers.veranstaltungStatusReducerFn,
    users: fromReducers.userReducerFn
};
