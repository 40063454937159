import gql from 'graphql-tag';

export const GebaeudeApi: any = {};

GebaeudeApi.fragments = {
    raumFragment: gql`
        fragment RaumFragment on RaumType {
            id
            bezeichnung
            sortIndex
            geschoss {
                id
                bezeichnung
            }
        }
    `
};

GebaeudeApi.queries = {
    getRaeume: gql`
        query getRaeume {
            publicApi {
                gebaeude {
                    alleRaeume {
                        ... RaumFragment
                    }
                }
            }
        },
        ${GebaeudeApi.fragments.raumFragment}
    `,
    getGeschosse: gql`
        query getGeschosse {
            publicApi {
                gebaeude {
                    alleGeschosse {
                        id
                        bezeichnung
                    }
                }
            }
        },
    `,
    getRaueme: gql`
        query getRaueme {
            publicApi {
                gebaeude {
                    alleRaumBestuhlungen {
                        id
                        bezeichnung
                    }
                }
            }
        },
    `
};
