import gql from 'graphql-tag';

export const BestellpositionenApi: any = {};

BestellpositionenApi.fragments = {
    bestellpositionForListing: gql`
        fragment BestellpositionForListingFragment on BestellpositionType {
            id
            anzahl
            einheitspreis
            rabatt
            verrechnungsstufe
            bemerkung
            totalOhneMwstAfterDiscount
            anzahlungspflichtig
            artikel {
                id
                bezeichnung
                nummer
                einheitspreis
                einheit
                mwst
                kategorie {
                    id
                    bezeichnung
                    sortIndex
                }
                disabled
            }
        }
    `
};

BestellpositionenApi.queries = {
    getBestellpositionen: gql`
        query getBestellpositionen($reservationId : Int) {
            privateApi {
                bestellpositionen {
                    alleBestellpositionen(reservationId: $reservationId) {
                        ... BestellpositionForListingFragment
                    }
                }
            }
        }
        ${BestellpositionenApi.fragments.bestellpositionForListing}
    `
};

BestellpositionenApi.mutations = {
    erstellen: gql`
        mutation($bestellposition: bestellpositionInput!) {
            bestellpositionen {
                erstellen(bestellposition: $bestellposition) {
                    ... BestellpositionForListingFragment
                }
            }
        }
        ${BestellpositionenApi.fragments.bestellpositionForListing}
    `,
    bearbeiten: gql`
      mutation editBestellposition($bestellposition: bestellpositionUpdateInput!) {
          bestellpositionen {
              bearbeiten(bestellposition: $bestellposition) {
                  ... BestellpositionForListingFragment
              }
          }
      }
      ${BestellpositionenApi.fragments.bestellpositionForListing}
    `,
    delete: gql`
      mutation deleteBestellposition($bestellpositionId: Int!) {
          bestellpositionen {
              loeschen(id: $bestellpositionId)
          }
      }
    `
};
