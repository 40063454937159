import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'effimod-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.scss']
})
export class DropdownMultiselectComponent implements OnInit {

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    options: any[];

    @Input()
    valuePropertyName: string;

    @Input()
    descriptionPropertyName: string;

    @Input()
    parent: AbstractControl;

    @Input()
    formControlNameString: string;

    constructor() {
    }

    ngOnInit() {
    }

}
