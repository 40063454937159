import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DokumenteBaseService } from './dokumente-base.service';
import { ShowBlobService } from './show-blob.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../notification.service';
import { VeranstaltungDokumenteEnum } from './veranstaltungs-dokument.enum';

@Injectable({providedIn: 'root'})
export class VeranstaltungenDokumenteService extends DokumenteBaseService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(protected showBlobService: ShowBlobService, protected client: HttpClient, protected notificationService: NotificationService) {
        super(showBlobService, client, notificationService);
    }

    generateAgenda(veranstaltungenIds: number[]) {
        const baseApiUrl = `${environment.api.rest}/veranstaltungen/dokumente`;
        const mimeType = 'application/pdf';
        const fileName = 'Agenda.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/agenda`;
        this.generateDocument(dokumentApiPath, veranstaltungenIds, requestOptions, mimeType, fileName);
    }

    generatePdf(veranstaltungId: number, dokumentType: VeranstaltungDokumenteEnum) {
        const baseApiUrl = `${environment.api.rest}/veranstaltungen/${veranstaltungId}/dokumente`;
        switch (dokumentType) {
            case VeranstaltungDokumenteEnum.Kurzbestaetigung:
                this.generateKurzbestaetigung(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.Fragebogen:
                this.generateFragebogen(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.Rueckbestaetigung:
                this.generateRueckbestaetigung(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.Anzahlungsrechnung:
                this.generateAnzahlungsrechnung(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.Kostenzusammenstellung:
                this.generateKostenzusammenstellung(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.Veranstaltungsblatt:
                this.generateVeranstaltungsblatt(baseApiUrl);
                break;

            case VeranstaltungDokumenteEnum.InternetVoucher:
                this.generateInternetVoucher(baseApiUrl);
                break;

            default:
                break;
        }
    }

    private generateInternetVoucher(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'InternetVoucher.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/internet-voucher`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }
    private generateKurzbestaetigung(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Kurzbestätigung.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/kurzbestaetigung`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }

    private generateFragebogen(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Fragebogen.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/fragebogen`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }

    private generateRueckbestaetigung(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Rückbestätigung.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/rueckbestaetigung`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }

    private generateAnzahlungsrechnung(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Anzahlungsrechnung.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/anzahlungsrechnung`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }

    private generateKostenzusammenstellung(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Kostenzusammenstellung.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/kostenzusammenstellung`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }

    private generateVeranstaltungsblatt(baseApiUrl: string) {
        const body = null;
        const mimeType = 'application/pdf';
        const fileName = 'Veranstaltungsblatt.pdf';
        const requestOptions = {responseType: 'blob' as 'json'};
        const dokumentApiPath = `${baseApiUrl}/veranstaltungsblatt`;
        this.generateDocument(dokumentApiPath, body, requestOptions, mimeType, fileName);
    }
}
