import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KundeVersion } from '../../../../data/interfaces/kunde-version.interface';

@Component({
    selector: 'effimod-kunde-details-form',
    templateUrl: './kunde-details-form.component.html',
    styleUrls: ['./kunde-details-form.component.scss']
})
export class KundeDetailsFormComponent implements OnInit {
    @Input() veranstaltungBezeichnung: string;
    @Input() kundeForm: FormGroup;
    @Input() kundeVersion: KundeVersion;
    @Input() isWriteMode: boolean = false;
    @Input() showAdresse: boolean = true;
    @Input() showAdditionalInformation: boolean = false;

    constructor() { }
    ngOnInit() {
        this.ensureFormIsNotEditable();

        // Subscribe to form status changes
        this.kundeForm.statusChanges.subscribe(() => {
            this.ensureFormIsNotEditable();
        });
    }

    ensureFormIsNotEditable() {
        // Disable the form control to prevent it from being modified
        this.kundeForm.disable({ emitEvent: false });
    }


    navigateToInsightlyLink() {
        const externalInsightlyLink: string = 'https://crm.na1.insightly.com/details/';
        const externalCrmKundeId = this.kundeForm.get('externalCrmKundeId').value;
        const kundenType = this.getInsightlyKundeType();
        const externalLink = `${externalInsightlyLink}${kundenType}/${externalCrmKundeId}`;
        window.open(externalLink, '_blank');
    }

    getInsightlyKundeType() {
        const isFirma = this.kundeForm.get('isFirma') ? this.kundeForm.get('isFirma').value : false;
        return isFirma ? 'Organisation' : 'Contact';
    }
}
