import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-datetime-selector',
    templateUrl: './datetime-selector.component.html',
    styleUrls: ['./datetime-selector.component.scss']
})
export class DatetimeSelectorComponent implements OnInit {

    @Input() label: string;
    @Input() parent: FormGroup;
    @Input() showWeekday: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    get dateFormControl() { return this.parent.get('date'); }
    get timeFormControl() { return this.parent.get('time'); }
}
