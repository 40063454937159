import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetVeranstaltungStatusGQL } from '../graphql/veranstaltungen/status/veranstaltung-status.queries';
import { VeranstaltungStatus } from '../interfaces/VeranstaltungStatus';

@Injectable({
    providedIn: 'root'
})
export class VeranstaltungStatusService {

    public veranstaltungStatus: BehaviorSubject<VeranstaltungStatus[]> = new BehaviorSubject<VeranstaltungStatus[]>([]);

    constructor(private getAlleVeranstaltungStatus: GetVeranstaltungStatusGQL) {
    }

    public loadVeranstaltungStatus(): Observable<VeranstaltungStatus[]> {
        return this.getAlleVeranstaltungStatus
            .fetch()
            .pipe(map(res => res.data.publicApi.veranstaltungStatus.alle));
    }
}
