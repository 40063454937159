import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UsersDataService } from '../../data/services/users-data.service';
import * as fromStore from '../selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '../index';

@Injectable()
export class UserEffects {
    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType(fromActions.loadUsers),
        switchMap((action) => {
            return this.usersDataService.loadEntities(true)
                .pipe(
                    map(res => fromActions.loadUsersSuccess({entities: res})),
                    catchError(errorMessage => of(fromActions.loadUsersFail({errorMessage})))
                );
        })
    );

    @Effect()
    storeCurrentUser$ = this.actions$.pipe(
        ofType(fromActions.storeCurrentUser),
        switchMap(action => {
            return this.store.pipe(select(fromStore.getCurrentUser)).pipe(
                    map(res => fromActions.storeCurrentUserSuccess({currentUser: res})),
                    catchError(errorMessage => of(fromActions.storeCurrentUserFail({errorMessage})))
                );
        })
    );

    constructor(private actions$: Actions, private usersDataService: UsersDataService, private store: Store<AppState>) {
    }
}
