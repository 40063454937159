import { AbstractFilter, BaseEntityFilter, DateTimeFilter, OrderBy } from '../../data/interfaces/base-entity-filter.interface';
import * as moment from 'moment';
import { EffimodTableFilterKeyValue } from './effimod-table-definition.interface';
import { EffimodTableFilterOperators } from '../components/reservationen-list/constants/effimod-table-filter-operators';

export abstract class EntityService {

    protected currentFilter: BaseEntityFilter = {
        take: 100,
        skip: 0,
        filterBy: [],
        orderBy: []
    };

    public filterEventList: EffimodTableFilterKeyValue;

    removeFilterByForName(name: string) {
        this.currentFilter.filterBy = this.currentFilter.filterBy.filter(f => f.field !== name);
    }

    addFilterBy(filter: AbstractFilter) {
        this.currentFilter.filterBy.push(filter);
    }

    replaceFilterByName(filter: AbstractFilter) {
        const filterIndex = this.currentFilter.filterBy.findIndex(f => f.field === filter.field);
        if (filterIndex > -1) {
            this.currentFilter.filterBy.splice(filterIndex, 1);
        }
        this.currentFilter.filterBy.push(filter);
    }


    clearAllFilters() {
        this.currentFilter.filterBy.length = 0;
    }

    addOrderBy(orderBy: OrderBy) {
        /*
        this.currentFilter.orderBy = this.currentFilter.orderBy.filter(f => f.field !== orderBy.field);
        this.currentFilter.orderBy.push(orderBy);
        */
        this.currentFilter.orderBy = [orderBy];
    }

    initTodayFilter() {
        const today = new Date();
        today.setHours(0, 0, 0);
        this.replaceFilterByName(new DateTimeFilter({
            field: 'beginn',
            operator: EffimodTableFilterOperators.GT, value: moment(today).locale('de-ch')
        }));

        today.setHours(23, 59, 59);
        this.replaceFilterByName(new DateTimeFilter({
            field: 'ende',
            operator: EffimodTableFilterOperators.LT, value: moment(today).locale('de-ch')
        }));
    }

    abstract loadEntities(params?: any);
}
