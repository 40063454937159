import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'effimod-table-row-edit-controls',
    templateUrl: './effimod-table-row-edit-controls.component.html',
    styleUrls: ['./effimod-table-row-edit-controls.component.scss']
})
export class EffimodTableRowEditControlsComponent implements OnInit {

    @Input() canDelete: boolean;
    @Input() isEditing: boolean;
    @Input() rowData: any;
    @Input() rowIndex: number;
    @Input() editActive: boolean;

    @Output() edit: EventEmitter<void> = new EventEmitter<void>();
    @Output() delete: EventEmitter<void> = new EventEmitter<void>();
    @Output() save: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {
    }

    onEdit() {
        this.edit.emit(this.rowData);
    }

    onDelete() {
        this.delete.emit(this.rowData);
    }

    onSave() {
        this.save.emit(this.rowData);
    }

    onCancel() {
        this.cancel.emit(this.rowData);
    }
}
