import { Pipe, PipeTransform } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { VeranstaltungBearbeitenPermissionCheckService } from '../../services/veranstaltung-bearbeiten-permission-check.service';

@Pipe({
    name: 'veranstaltungenBatchAssignAnsprechpersonButtonTooltip'
})
export class VeranstaltungenBatchAssignAnsprechpersonButtonTooltipPipe implements PipeTransform {

    constructor(private veranstaltungBearbeitenPermissionCheckService: VeranstaltungBearbeitenPermissionCheckService) { }

    transform(veranstaltungen: Veranstaltung[], ...args: any[]): any {

        if (!veranstaltungen || !veranstaltungen.length) {
            return 'Mindestens eine Veranstaltung auswählen';
        }

        if (!this.veranstaltungBearbeitenPermissionCheckService.areVeranstaltungenBearbeitbar(veranstaltungen)) {
            return 'Mindestens eine der Veranstaltungen darf nicht mehr bearbeitet werden';
        }

        return 'Ansprechpartner neu setzen';
    }
}
