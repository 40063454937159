export enum EffimodPermissionEnum {

    // Veranstaltung
    CREATE_VERANSTALTUNG = 'create:veranstaltung',
    ABSCHLIESSEN_VERANSTALTUNG = 'abschliessen:veranstaltung',
    ANNULLIEREN_VERANSTALTUNG = 'annullieren:veranstaltung',
    ABSAGE_VERANSTALTUNG = 'absage:veranstaltung',
    DELETE_VERANSTALTUNG = 'delete:veranstaltung',
    ANZAHLUNG_VERANSTALTUNG = 'anzahlung:veranstaltung',
    UPDATE_VERANSTALTUNG = 'update:veranstaltung',
    COPY_VERANSTALTUNG = 'copy:veranstaltung',
    READ_VERANSTALTUNG = 'read:veranstaltung',

    // Reservation
    DELETE_RESERVATION = 'delete:reservation',
    UPDATE_RESERVATION = 'update:reservation',
    CREATE_RESERVATION = 'create:reservation',
    READ_RESERVATION = 'read:reservation',

    // Blockierungen
    CONVERT_BLOCKIERUNG = 'convert:blockierung',
    CREATE_BLOCKIERUNG = 'create:blockierung',
    UPDATE_BLOCKIERUNG = 'update:blockierung',
    READ_BLOCKIERUNG = 'read:blockierung',

    // Admin
    READ_ADMIN = 'read:admin',
    EXECUTE_ADMIN = 'execute:admin',

    // Dokumente
    GENERATE_DOCUMENT = 'generate:document',

    // Kunde
    READ_KUNDE = 'read:kunde',

    // Rapport
    READ_RAPPORTBLATT = 'read:rapportblatt',

    // Reservationsblatt
    READ_RESERVATIONSBLATT = 'read:reservationsblatt',

    // Daynote (Notizen in der Tagesansicht vom Agenda)
    READ_DAYNOTE = 'read:daynote',
    MUTATE_DAYNOTE = 'mutate:daynote',

    // Auswertung
    READ_AUSWERTUNG = 'read:auswertung'
}
