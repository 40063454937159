import { Component, ContentChildren, QueryList, AfterContentInit, TemplateRef } from '@angular/core';
import { ActionsFooterLeftDirective } from './actions-footer-left.directive';
import { ActionsFooterRightDirective } from './actions-footer-right.directive';

@Component({
    selector: 'effimod-actions-footer',
    templateUrl: './actions-footer.component.html',
    styleUrls: ['./actions-footer.component.scss']
})
export class ActionsFooterComponent implements AfterContentInit {
    @ContentChildren(ActionsFooterLeftDirective, { read: TemplateRef }) leftButtons: QueryList<TemplateRef<any>>;
    @ContentChildren(ActionsFooterRightDirective, { read: TemplateRef }) rightButtons: QueryList<TemplateRef<any>>;

    leftTemplates: TemplateRef<any>[];
    rightTemplates: TemplateRef<any>[];

    ngAfterContentInit() {
        // Convert QueryList to arrays for easier iteration in the template.
        this.leftTemplates = this.leftButtons.toArray();
        this.rightTemplates = this.rightButtons.toArray();
    }
}
