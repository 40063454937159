import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions';
import { VeranstaltungStatus } from '../../data/interfaces/VeranstaltungStatus';
import * as fromHelpers from '../helpers';

export interface VeranstaltungStatusState {
    entities: { [id: number]: VeranstaltungStatus };
    loading: boolean;
    loaded: boolean;
    errorMessage: string;
}

const initialState: VeranstaltungStatusState = {
    entities: {},
    loading: false,
    loaded: false,
    errorMessage: null
};

export function veranstaltungStatusReducerFn(state: VeranstaltungStatusState, action: Action) {
    return veranstaltungStatusReducer(state, action);
}

export const veranstaltungStatusReducer = createReducer(
    initialState,
    on(fromActions.loadVeranstaltungStatus, (state) => {
        return {...state, loading: true, loaded: false, errorMessage: null};
    }),
    on(fromActions.loadVeranstaltungStatusSuccess, (state, {entities}) => {
        const results = fromHelpers.addItems<VeranstaltungStatus>(state.entities, entities);
        return {...state, loading: false, loaded: true, errorMessage: null, entities: results};
    }),
    on(fromActions.loadVeranstaltungStatusFail, (state, {errorMessage}) => {
        return {...state, loading: false, loaded: false, errorMessage};
    })
);

