import { createAction, props } from '@ngrx/store';
import { Geschoss } from '../../data/interfaces/geschoss.interface';
import { Raum } from '../../data/interfaces/raum.interface';

export const loadAll = createAction('[Gebaeude] load all');

export const loadGeschosse = createAction('[Geschosse] load');
export const loadGeschosseSuccess = createAction('[Geschosse] load success', props<{ geschosse: Geschoss[] }>());
export const loadGeschosseFail = createAction('[Geschosse] load fail', props<{ errorMessage: string }>());

export const loadRaeume = createAction('[Raeume] load');
export const loadRaeumeSuccess = createAction('[Raeume] load success', props<{ raeume: Raum[] }>());
export const loadRaeumeFail = createAction('[Raeume] load fail', props<{ errorMessage: string }>());
