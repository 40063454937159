import { Geschoss } from '../../data/interfaces/geschoss.interface';
import { Raum } from '../../data/interfaces/raum.interface';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions';
import * as fromHelpers from '../helpers';

export interface GebaeudeState {
    geschosse: { [id: number]: Geschoss };
    raeume: Raum[];
    status: {
        geschosse: fromHelpers.LoadingStatus;
        raeume: fromHelpers.LoadingStatus;
    };
    errorMessages: string[];
}

const initialState = {
    geschosse: {},
    raeume: [],
    status: {
        geschosse: fromHelpers.LoadingStatus.Initializing,
        raeume: fromHelpers.LoadingStatus.Initializing
    },
    errorMessages: []
};

export const gebaeudeReducer = createReducer(
    initialState,
    on(fromActions.loadGeschosse, (state) => {
        return {...state, status: {...state.status, geschosse: fromHelpers.LoadingStatus.Loading}};
    }),
    on(fromActions.loadGeschosseSuccess, (state, {geschosse}) => {
        const entities = fromHelpers.addItems<Geschoss>(state.geschosse, geschosse);
        return {...state, geschosse: entities, status: {...state.status, geschosse: fromHelpers.LoadingStatus.Loaded}};
    }),
    on(fromActions.loadGeschosseFail, (state, {errorMessage}) => {
        return {...state, status: {...state.status, geschosse: fromHelpers.LoadingStatus.Error}, errorMessages: [...state.errorMessages, errorMessage]};
    }),
    on(fromActions.loadRaeume, (state) => {
        return {...state, status: {...state.status, raeume: fromHelpers.LoadingStatus.Loading}};
    }),
    on(fromActions.loadRaeumeSuccess, (state, {raeume}) => {
        return {...state, raeume, status: {...state.status, raeume: fromHelpers.LoadingStatus.Loaded}};
    }),
    on(fromActions.loadRaeumeFail, (state, {errorMessage}) => {
        return {...state, status: {...state.status, raeume: fromHelpers.LoadingStatus.Error}, errorMessages: [...state.errorMessages, errorMessage]};
    })
);

export function gebaeudeReducerFn(state: GebaeudeState, action: Action) {
    return gebaeudeReducer(state, action);
}
