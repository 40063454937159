import { AbstractFilter, BaseEntityFilter, DateTimeFilter, OrderBy } from '../../data/interfaces/base-entity-filter.interface';
// Consider using modern `Date` handling like `date-fns` or native Date.
import * as moment from 'moment';
import { EffimodTableFilterKeyValue } from './effimod-table-definition.interface';
import { EffimodTableFilterOperators } from '../components/reservationen-list/constants/effimod-table-filter-operators';

export abstract class EntityService {

    protected currentFilter: BaseEntityFilter = {
        take: 100, // Consider making these configurable via constructor
        skip: 0,
        filterBy: [],
        orderBy: []
    };

    public filterEventList: EffimodTableFilterKeyValue;

    // Removes filters by name
    removeFilterByForName(name: string) {
        this.currentFilter.filterBy = this.currentFilter.filterBy.filter(f => f.field !== name);
    }

    // Adds a filter to the list
    addFilterBy(filter: AbstractFilter) {
        this.currentFilter.filterBy.push(filter);
    }

    // Replaces an existing filter by name, otherwise adds a new one
    replaceFilterByName(filter: AbstractFilter) {
        const index = this.currentFilter.filterBy.findIndex(f => f.field === filter.field);
        if (index > -1) {
            this.currentFilter.filterBy[index] = filter; // Replace existing filter
        } else {
            this.currentFilter.filterBy.push(filter); // Add new filter if not found
        }
    }

    // Clears all filters
    clearAllFilters() {
        this.currentFilter.filterBy = [];
    }

    // Sets a single orderBy (optionally, you could allow multiple)
    addOrderBy(orderBy: OrderBy) {
        this.currentFilter.orderBy = [orderBy]; // Replace the whole orderBy list
    }

    // Initialize the filter for today's date range
    initTodayFilter() {
        const today = new Date();
        today.setHours(0, 0, 0);
        this.replaceFilterByName(new DateTimeFilter({
            field: 'beginn',
            operator: EffimodTableFilterOperators.GT,
            value: moment(today).locale('de-ch') // You could replace `moment` with native Date
        }));

        today.setHours(23, 59, 59);
        this.replaceFilterByName(new DateTimeFilter({
            field: 'ende',
            operator: EffimodTableFilterOperators.LT,
            value: moment(today).locale('de-ch')
        }));
    }

    // Abstract method that needs to be implemented by extending services
    abstract loadEntities(params?: any): void;
}
