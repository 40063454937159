import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { VeranstaltungenDataService } from '../../../data/services/veranstaltungen-data.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'effimod-veranstaltung-kopieren-button',
    templateUrl: './veranstaltung-kopieren-button.component.html',
    styleUrls: ['./veranstaltung-kopieren-button.component.scss']
})
export class VeranstaltungKopierenButtonComponent implements OnInit {

    @Input() veranstaltungen: Veranstaltung[];
    @Output() veranstaltungKopiert: EventEmitter<Veranstaltung> = new EventEmitter<Veranstaltung>();

    effimodPermissionEnum = EffimodPermissionEnum;

    constructor(private veranstaltungenDataService: VeranstaltungenDataService, private notificationService: NotificationService) { }

    ngOnInit() {
    }

    copyVeranstaltung() {
        // There are currently two 'Copy' methods in the service have a lot of duplicate code
        // double security check

        if (!this.veranstaltungen || !this.veranstaltungen.length) {
            this.notificationService.notifyWarning('Bitte wählen Sie mindestens eine Veranstaltung aus.');
            return;
        }

        if (this.veranstaltungen.length > 1) {
            this.notificationService.notifyWarning('Bitte nur eine einzige Veranstaltung auswählen');
            return;
        }

        this.veranstaltungen.forEach(veranstaltung => {
            if (!veranstaltung.veranstalterVersion) {
                this.notificationService.notifyError(`Fehler beim Kopieren der Veranstaltung: Die Info des Veranstalters sind nicht vorhanden`);
            } else if (!veranstaltung.veranstalterVersion.externalCrmKundeId) {
                this.notificationService.notifyError(
                    `Fehler beim Kopieren der Veranstaltung: Der/Die Veranstalter/in stammt nicht aus Insightly. Bitte eine Kontaktperson oder eine Organisation aus Insightly auswälen`
                );
            } else {
                this.veranstaltungKopieren(veranstaltung);
            }
        });
    }

    private veranstaltungKopieren(veranstaltung: Veranstaltung) {
        this.veranstaltungenDataService.copyVeranstaltung(veranstaltung.id).subscribe(
            res => {
                this.notificationService.notifySuccess(`Veranstaltung kopiert`);
                this.veranstaltungKopiert.emit(res);
            },
            err => {
                this.notificationService.notifyError(`Fehler beim Kopieren der Veranstaltung`);
                console.error(`Die Veranstaltung (ID=${veranstaltung.id}) konnte nicht kopiert werden: `, err);
            }
        );
    }
}
