import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/de-ch';
moment.locale('de-ch');

@Pipe({
    name: 'weekdayTime'
})
export class WeekdayTimePipe implements PipeTransform {

    transform(value: Date, title?: string, isCustomWeek?: boolean): string {
        // todo: currently sketchy fix as database only saves date, but 00:00:00 gets shifted a day back because stored in utc
        return `${moment(value).format('dddd')} ${moment(value).format('DD. MMMM YYYY HH:mm')}`;
    }
}
