import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KundeVersion } from '../../../../data/interfaces/kunde-version.interface';
import { Kunde } from '../../../../data/interfaces/kunde.interface';
import { Adresse } from '../../../../data/interfaces/adresse';

@Component({
    selector: 'effimod-insightly-labeled-link',
    templateUrl: './insightly-labeled-link.component.html',
    styleUrls: ['./insightly-labeled-link.component.scss']
})
export class InsightlyLabeledLinkComponent implements OnInit {
    @Input()
    kundeVersion: KundeVersion;

    @Input()
    disabled: boolean = false;

    @Input()
    inputType: 'text' | 'number' = 'text';

    @Input()
    parent: FormGroup;

    @Input()
    inputId: string;

    @Input()
    label: string;

    constructor() {
    }

    ngOnInit() {
        if (!this.kundeVersion && this.parent) {
            this.kundeVersion = {} as KundeVersion;
            this.kundeVersion.kunde = {} as Kunde;
            this.kundeVersion.adresse = {} as Adresse;
            if (this.parent.get('isFirma')) {
                this.kundeVersion.kunde.isFirma = this.parent.get('isFirma').value;
            }
            if (this.parent.get('firma')) {
                this.kundeVersion.firma = this.parent.get('firma').value;
            }
            if (this.parent.get('externalCrmKundeId')) {
                this.kundeVersion.externalCrmKundeId = this.parent.get('externalCrmKundeId').value;
            }
            if (this.parent.get('vorname')) {
                this.kundeVersion.vorname = this.parent.get('vorname').value;
            }
            if (this.parent.get('nachname')) {
                this.kundeVersion.nachname = this.parent.get('nachname').value;
            }
            if (this.parent.get('strasse')) {
                this.kundeVersion.adresse.strasse = this.parent.get('strasse').value;
            }
            if (this.parent.get('postleitzahl')) {
                this.kundeVersion.adresse.plz = this.parent.get('postleitzahl').value;
            }
            if (this.parent.get('ort')) {
                this.kundeVersion.adresse.ort = this.parent.get('ort').value;
            }
            if (this.parent.get('land')) {
                this.kundeVersion.adresse.land = this.parent.get('land').value;
            }
            if (this.parent.get('name')) {
                this.kundeVersion.name = this.parent.get('name').value;
            }
        }
    }
}
