import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Geschoss } from '../interfaces/geschoss.interface';
import { Raum } from '../interfaces/raum.interface';
import { GetGeschosseGQL, GetRaeumeGQL, GetRaumBestuhlungenGQL } from '../graphql/gebaeude/gebaeude.queries';
import { map } from 'rxjs/operators';
import { RaumBestuhlung } from '../interfaces/raum-bestuhlung.interface';

@Injectable({providedIn: 'root'})
export class GebaeudeService {

    constructor(private getRaeumeGQL: GetRaeumeGQL,
                private getGeschosseGQL: GetGeschosseGQL,
                private getRaumBestuhlungenGQL: GetRaumBestuhlungenGQL) {
    }

    public loadGeschosse(): Observable<Geschoss[]> {
        return this.getGeschosseGQL.fetch().pipe(map(res => res.data.publicApi.gebaeude.alleGeschosse));
    }

    public loadRaeume(): Observable<Raum[]> {
        return this.getRaeumeGQL.fetch().pipe(map(res => res.data.publicApi.gebaeude.alleRaeume));
    }

    public loadBestuhlungen(): Observable<RaumBestuhlung[]> {
        return this.getRaumBestuhlungenGQL
            .fetch()
            .pipe(
                map(res => {
                    const data = res.data.publicApi.gebaeude.alleRaumBestuhlungen;
                    const placeHolderForNonSelectedRaumBestuhlung = {id: -1, bezeichnung: ''} as RaumBestuhlung;
                    const modifiedRaumBestuhlungen = [placeHolderForNonSelectedRaumBestuhlung, ...data]; // it is important to create a new array in order not to modify the store
                    return modifiedRaumBestuhlungen;
                }));
    }
}
