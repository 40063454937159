import gql from 'graphql-tag';

export const VeranstaltungStatusApi: any = {};

VeranstaltungStatusApi.fragments = {
  veranstaltungStatus: gql`
      fragment VeranstaltungStatusFragment on VeranstaltungStatusType {
          id
          bezeichnung
          beschreibung
      }
  `
};

VeranstaltungStatusApi.queries = {
  alle: gql`
      query getAllVeranstaltungStatus {
          publicApi {
              veranstaltungStatus {
                  alle {
                      ... VeranstaltungStatusFragment
                  }
              }
          }
      }
      ${VeranstaltungStatusApi.fragments.veranstaltungStatus}
  `
};

