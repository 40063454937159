import { BaseEntityFilter } from '../../../interfaces/base-entity-filter.interface';
import gql from 'graphql-tag';
import { KundenApi } from '../kunden.gql';
import { DocumentNode } from 'graphql';

export const KontakteApi: any = {};

export function getEffimodKontakte(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    const fragment = 'KundeFragment';
    const fragmentImport = KundenApi.fragments.kunde;
    let displayDetails = ', displayDetails: false';
    if (withDetails) {
        displayDetails = ', displayDetails: true';
    }

    let filterByString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        const andFilter = filter.filterBy.map(f => f.toQuery()).join(',');
        filterByString += `, filter: {${andFilter}}`;
    }

    let orderByString = '';
    if (filter.orderBy && filter.orderBy.length > 0) {
        orderByString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }

    const query = gql`
      query getEffimodKontakte {
          privateApi {
              kontakte {
                  effimod(take: ${filter.take}, skip: ${filter.skip}${filterByString}${orderByString}${displayDetails}) {
                      ... ${fragment}
                  }
              }
          }
      }
      ${fragmentImport}
  `;

    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

export function getInsightlyKontakte(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    const fragment = 'KundeFragment';
    const fragmentImport = KundenApi.fragments.kunde;
    let displayDetails = ', displayDetails: false';
    if (withDetails) {
        displayDetails = ', displayDetails: true';
    }

    let filterByString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        const andFilter = filter.filterBy.map(f => f.toQuery()).join(',');
        filterByString += `, filter: {${andFilter}}`;
    }

    let orderByString = '';
    if (filter.orderBy && filter.orderBy.length > 0) {
        orderByString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }

    const query = gql`
      query getInsightlyKontakte {
          privateApi {
              kontakte {
                  insightly(take: ${filter.take}, skip: ${filter.skip}${filterByString}${orderByString}${displayDetails}) {
                      ... ${fragment}
                  }
              }
          }
      }
      ${fragmentImport}
  `;

    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

function getGqlString(doc: DocumentNode) {
    return doc.loc && doc.loc.source.body;
}

KontakteApi.mutations = {
  createEffimodKontakt: gql`
      mutation ($kunde: ansprechpersonInput!) {
          kontakte {
              createEffimodKontakt(kontakt: $kunde) {
                  id
                  anrede
                  vorname
                  nachname
                  firma
                  kunde {
                      id
                      isFirma
                      organisationId
                  }
                  adresse {
                      id
                      strasse
                      plz
                      ort
                      region
                      land
                  }
                  telefon
                  mobile
                  email
                  fax
              }
          }
      }
  `,
  updateEffimodKontakt: gql`
        mutation ($kunde: updateEffimodKundeInput!) {
            kontakte {
                updateEffimodKontakt(kontakt: $kunde) {
                    id
                    anrede
                    vorname
                    nachname
                    firma
                    kunde {
                        id
                        isFirma
                        organisationId
                    }
                    adresse {
                        id
                        strasse
                        plz
                        ort
                        region
                        land
                    }
                    telefon
                    mobile
                    email
                    fax
                }
            }
        }
    `
};
