import { createAction, props } from '@ngrx/store';
import { User } from '../../data/interfaces/user.interface';
import { Auth0User } from '../../data/interfaces/auth0-user.interface';

export const loadUsers = createAction('[Users] load');
export const loadUsersSuccess = createAction('[Users] load success', props<{ entities: User[] }>());
export const loadUsersFail = createAction('[Users] load failed', props<{ errorMessage: string }>());

export const storeCurrentUser = createAction('[Users] store current user', props<{ auth0User: Auth0User }>());
export const storeCurrentUserSuccess = createAction('[Users] store current user success', props<{ currentUser: User }>());
export const storeCurrentUserFail = createAction('[Users] store current user failed', props<{ errorMessage: string }>());
