import { Directive, HostListener, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { EffimodTableComponent } from '../effimod-table.component';

@Directive({
    selector: '[effimodAddNewEffimodTableRow]'
})
export class AddNewEffimodTableRowDirective {

    @Input() table: Table;
    @Input() effimodTableComponent: EffimodTableComponent;

    constructor() { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        // const itemTemplate = this.effimodTableComponent.definition.contentItemModelTemplate;
        // shallow copy, so that we don't change the contentItemModelTemplate, which would lead to issues when creating multiple new table entries
        const itemTemplate = Object.assign({}, this.effimodTableComponent.definition.contentItemModelTemplate);
        // const duplicateItem = this.duplicateFirstRow();
        // this.effimodTableComponent.content.items.push(duplicateItem);
        this.effimodTableComponent.addNewRow(itemTemplate)
        this.table.initRowEdit(itemTemplate);
        event.preventDefault();
    }

    private duplicateFirstRow() {
        const duplicateItem = Object.assign({}, this.effimodTableComponent.content.items[0]);
        duplicateItem[this.effimodTableComponent.definition.idColumnSelector] = -1;
        return duplicateItem;
    }
}
