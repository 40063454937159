import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToCheckboxHTML'
})
export class BoolToCheckboxHTMLPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value == true ? '<i class="pi pi-times relevant<" style="font-size: 5em !important; color: pink"></i>' : '';
  }

}
