import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EffimodTableDefinition, EffimodTableFilterType } from '../../../interfaces/effimod-table-definition.interface';
import { VeranstaltungenFilterNames } from '../constants/veranstaltungen-filter-names';
import { EffimodTableFilterOperators } from '../../reservationen-list/constants/effimod-table-filter-operators';
import { VeranstaltungenTableFieldSelectors } from '../constants/veranstaltungen-table-field-selectors';
import { VeranstaltungStatus } from '../../../../data/interfaces/VeranstaltungStatus';
import { User } from '../../../../data/interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class VeranstaltungenTableDefinitionService {
    constructor() {}

    getTableDefinition(sortingDisabled: boolean): EffimodTableDefinition {
        return {
            sortingDisabled,
            idColumnSelector: 'id', // Unique identifier for rows
            columns: [
                {
                    header: 'Nummer',
                    fieldSelector: VeranstaltungenTableFieldSelectors.ID, // Field to select
                    filterType: EffimodTableFilterType.number, // Filter type for this column
                    filterName: VeranstaltungenFilterNames.VERANSTALTUNG_ID, // Filter name
                    operator: EffimodTableFilterOperators.STARTS_WITH, // Filter operator
                    sortingDisabled: true, // Sorting disabled for this column
                    styleClass: 'veranstaltung-nummer' // Custom class for styling
                },
                {
                    header: 'Titel',
                    fieldSelector: VeranstaltungenTableFieldSelectors.TITEL,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.TITEL,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-title'
                },
                {
                    header: 'Von',
                    fieldSelector: VeranstaltungenTableFieldSelectors.BEGINN,
                    pipe: new DatePipe('de-CH'), // Use DatePipe to format the date
                    pipeArgs: ['dd.MM.yyyy HH:mm'], // Arguments for date formatting
                    filterField: VeranstaltungenTableFieldSelectors.BEGINN,
                    filterType: EffimodTableFilterType.date,
                    filterName: VeranstaltungenFilterNames.BEGINN,
                    operator: EffimodTableFilterOperators.GT, // Greater than filter for dates
                    printClipText: true, // Clips the text for print views
                    styleClass: 'veranstaltung-beginn'
                },
                {
                    header: 'Bis',
                    fieldSelector: VeranstaltungenTableFieldSelectors.ENDE,
                    pipe: new DatePipe('de-CH'),
                    pipeArgs: ['dd.MM.yyyy HH:mm'],
                    filterField: VeranstaltungenTableFieldSelectors.ENDE,
                    filterType: EffimodTableFilterType.date,
                    filterName: VeranstaltungenFilterNames.ENDE,
                    operator: EffimodTableFilterOperators.LT, // Less than filter for dates
                    printClipText: true,
                    styleClass: 'veranstaltung-ende'
                },
                {
                    header: 'Veranstalter',
                    fieldSelector: VeranstaltungenTableFieldSelectors.VERANSTALTER_NAME,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.VERANSTALTER_NAME,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-veranstalter'
                },
                {
                    header: 'Ort',
                    fieldSelector: VeranstaltungenTableFieldSelectors.VERANSTALTER_ORT,
                    filterType: EffimodTableFilterType.text,
                    filterName: VeranstaltungenFilterNames.VERANSTALTER_ORT,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-veranstalter-ort'
                },
                {
                    header: 'Sachbearbeiter',
                    fieldSelector: VeranstaltungenTableFieldSelectors.SACHBEARBEITER_NAME,
                    selectionOptions: [{ label: 'Alle', value: 'all' }], // Dropdown options
                    filterType: EffimodTableFilterType.selection, // Selection filter
                    filterName: VeranstaltungenFilterNames.SACHBEARBEITER_ID,
                    operator: EffimodTableFilterOperators.EQ, // Equal operator for filtering
                    sortingDisabled: true,
                    width: 180, // Set column width
                    styleClass: 'veranstaltung-sachbearbeiter'
                },
                {
                    header: 'Status',
                    fieldSelector: VeranstaltungenTableFieldSelectors.STATUS_BEZEICHNUNG,
                    selectionOptions: [{ label: 'Alle', value: 'all' }],
                    filterType: EffimodTableFilterType.selection,
                    filterName: VeranstaltungenFilterNames.STATUS_ID,
                    operator: EffimodTableFilterOperators.EQ,
                    sortingDisabled: true,
                    styleClass: 'veranstaltung-status'
                }
            ],
            navigatable: true, // Rows can be navigated
            selectMultiple: true // Allows multiple row selection
        };
    }

    populateUserOptions(users: User[], tableDefinition: EffimodTableDefinition): void {
        const userColumn = tableDefinition.columns.find(c => c.header === 'Sachbearbeiter');
        if (userColumn) {
            const selectionOptions = users.map(user => ({
                label: user.name,
                value: user.id
            }));
            userColumn.selectionOptions = [{ label: 'Alle', value: 'all' }, ...selectionOptions];
        }
    }

    populateStatusOptions(statusList: VeranstaltungStatus[], tableDefinition: EffimodTableDefinition): void {
        const statusColumn = tableDefinition.columns.find(c => c.header === 'Status');
        if (statusColumn) {
            const selectionOptions = statusList.map(status => ({
                label: status.bezeichnung,
                value: status.id
            }));
            statusColumn.selectionOptions = [{ label: 'Alle', value: 'all' }, ...selectionOptions];
        }
    }
}
