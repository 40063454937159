import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'effimod-pseudo-datetime-selector',
  templateUrl: './pseudo-datetime-selector.component.html',
  styleUrls: ['./pseudo-datetime-selector.component.scss']
})
export class PseudoDatetimeSelectorComponent implements OnInit {
    @Input()
    label: string;
    @Input()
    beginn: any;

  constructor() { }

  ngOnInit() {
  }

}
