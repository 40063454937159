import * as fromActions from '../actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Artikel } from '../../data/interfaces/artikel.interface';

export interface ArtikelState {
    entities: { [id: number]: Artikel };
    entitiesCount: number;
    entitiesByKategorieName: { [kategorieName: string]: Artikel[] };
    filteredArtikel: Artikel[];
    selectedKategorieName: string;
    loading: boolean;
    loaded: boolean;
    errorMessage: string;
}

const initialState: ArtikelState = {
    entities: {},
    entitiesCount: 0,
    entitiesByKategorieName: {},
    filteredArtikel: [],
    selectedKategorieName: '',
    loading: false,
    loaded: false,
    errorMessage: null
};

export const artikelReducer = createReducer(
    initialState,
    on(fromActions.loadArtikel, (state) => {
        return {...state, loading: true, loaded: false, errorMessage: null};
    }),
    on(fromActions.loadArtikelSuccess, (state, {artikel}) => {
        return reduceSuccessfullyLoadedArtikelState(artikel, state);
    }),
    on(fromActions.loadArtikelFail, (state, {errorMessage}) => {
        return {...state, loading: false, loaded: false, errorMessage};
    }),
    on(fromActions.loadBestellbareArtikel, (state) => {
        return {...state, loading: true, loaded: false, errorMessage: null};
    }),
    on(fromActions.loadBestellbareArtikelSuccess, (state, {artikel}) => {
        return reduceSuccessfullyLoadedArtikelState(artikel, state);
    }),
    on(fromActions.loadBestellbareArtikelFail, (state, {errorMessage}) => {
        return {...state, loading: false, loaded: false, errorMessage};
    }),
    on(fromActions.SelectAnotherKategorieName, (state, {selectedKategorieName}) => {
        const filteredArtikel = state.entitiesByKategorieName[selectedKategorieName];
        return {...state, selectedKategorieName, filteredArtikel};
    }),
    // on(fromActions.SelectAnotherKategorieNameSuccess, (state, {s}))
);

export function artikelReducerFn(state: ArtikelState, action: Action) {
    return artikelReducer(state, action);
}

function reduceSuccessfullyLoadedArtikelState(artikel: Artikel[], state) {
    const entities = artikel
        .reduce(
        (items: { [id: number]: Artikel }, item: Artikel) => { return {...items, [item.nummer]: item}; },
        {...state.entities}
    );
    const entitiesByKategorieName = artikel
        .reduce(
        (itemsMap: { [kategorieName: string]: Artikel[] }, item: Artikel) => {
            if (!itemsMap[item.kategorie.bezeichnung]) {
                itemsMap[item.kategorie.bezeichnung] = [item];
            } else {
                itemsMap[item.kategorie.bezeichnung].push(item);
            }
            return {
                ...itemsMap
            };
        },
        {
            ...state.entitiesByKategorieName
        }
    );

    const katNames = Object.keys(entitiesByKategorieName);
    const selectedKategorieName = katNames && katNames.length ? katNames[0] : '';

    for (const kat of katNames) {
        let artikelList = entitiesByKategorieName[kat];
        if (kat.toLocaleLowerCase() !== 'raum') {
            artikelList = artikelList.sort((a, b) => a.bezeichnung > b.bezeichnung ? 1 : -1);
        }
        entitiesByKategorieName[kat] = artikelList;
    }
    const entitiesCount = artikel.length;
    return {...state, loading: false, loaded: true, errorMessage: null, entities, entitiesCount, entitiesByKategorieName, selectedKategorieName};
}
