import { Pipe, PipeTransform } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { VeranstaltungBearbeitenPermissionCheckService } from '../../services/veranstaltung-bearbeiten-permission-check.service';

@Pipe({
    name: 'veranstaltungenBatchAssignAnsprechpersonButtonDisabled'
})
export class VeranstaltungenBatchAssignAnsprechpersonButtonDisabledPipe implements PipeTransform {

    constructor(private veranstaltungModificationPermissionCheckService: VeranstaltungBearbeitenPermissionCheckService) { }

    transform(veranstaltungen: Veranstaltung[], ...args: any[]): boolean {
        return !this.veranstaltungModificationPermissionCheckService.areVeranstaltungenBearbeitbar(veranstaltungen);
    }
}
