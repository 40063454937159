import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EffimodTableDefinition, EffimodTableFilterType, EffimodTableInputType } from '../../../interfaces/effimod-table-definition.interface';
import { ReservationenTableHeaders } from '../constants/reservationen-table-headers';
import { ReservationenTableStyleClasses } from '../constants/reservationen-table-style-classes';
import { EffimodTableFilterOperators } from '../constants/effimod-table-filter-operators';
import { ReservationenTableFieldSelectors } from '../constants/reservationen-table-field-selectors';
import { ReservationenTableFilterNames } from '../constants/reservationen-table-filter-names';

@Injectable({
    providedIn: 'root'
})
export class ReservationenTableDefinitionService {

    constructor() {}

    getTableDefinition(isVeranstaltungSpecific: boolean): EffimodTableDefinition {
        const tableDefinition: EffimodTableDefinition = {
            idColumnSelector: ReservationenTableFieldSelectors.RESERVATION_NUMMER,
            columns: [
                {
                    header: ReservationenTableHeaders.RESERVATION_NUMMER,
                    fieldSelector: [ReservationenTableFieldSelectors.RESERVATION_NUMMER],
                    filterType: EffimodTableFilterType.text,
                    filterName: ReservationenTableFilterNames.RESERVATION_NUMMER,
                    operator: EffimodTableFilterOperators.STARTS_WITH,
                    printWidth: 70,
                    styleClass: ReservationenTableStyleClasses.RESERVATION_NUMMER
                },
                {
                    header: ReservationenTableHeaders.BESCHRIFTUNG,
                    fieldSelector: ReservationenTableFieldSelectors.BESCHRIFTUNG,
                    filterType: EffimodTableFilterType.text,
                    filterName: ReservationenTableFilterNames.BESCHRIFTUNG,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    styleClass: ReservationenTableStyleClasses.BESCHRIFTUNG
                },
                {
                    header: ReservationenTableHeaders.RAUM,
                    fieldSelector: ReservationenTableFieldSelectors.RAUM_BEZEICHNUNG,
                    filterType: EffimodTableFilterType.selection,
                    filterName: ReservationenTableFilterNames.RAUM_ID,
                    operator: EffimodTableFilterOperators.EQ,
                    selectionOptions: [{ label: 'Alle', value: 'all' }],
                    styleClass: ReservationenTableStyleClasses.RAUM
                },
                {
                    header: ReservationenTableHeaders.VERANSTALTER,
                    fieldSelector: ReservationenTableFieldSelectors.VERANSTALTER_NAME,
                    filterType: EffimodTableFilterType.text,
                    filterName: ReservationenTableFilterNames.VERANSTALTER_NAME,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    sortingDisabled: true,
                    styleClass: ReservationenTableStyleClasses.VERANSTALTER
                },
                {
                    header: ReservationenTableHeaders.ORT,
                    fieldSelector: ReservationenTableFieldSelectors.VERANSTALTER_ORT,
                    filterType: EffimodTableFilterType.text,
                    filterName: ReservationenTableFilterNames.VERANSTALTER_ORT,
                    operator: EffimodTableFilterOperators.CONTAINS,
                    styleClass: ReservationenTableStyleClasses.ORT
                },
                {
                    header: ReservationenTableHeaders.VON,
                    fieldSelector: ReservationenTableFieldSelectors.BEGINN,
                    pipe: new DatePipe('de-CH'),
                    pipeArgs: ['dd.MM.yyyy HH:mm'],
                    filterName: ReservationenTableFilterNames.BEGINN,
                    filterType: EffimodTableFilterType.date,
                    operator: EffimodTableFilterOperators.GT,
                    printWidth: 100,
                    printClipText: true,
                    styleClass: ReservationenTableStyleClasses.VON
                },
                {
                    header: ReservationenTableHeaders.ENDE,
                    fieldSelector: ReservationenTableFieldSelectors.ENDE,
                    pipe: new DatePipe('de-CH'),
                    pipeArgs: ['dd.MM.yyyy HH:mm'],
                    filterField: ReservationenTableFilterNames.ENDE,
                    filterType: EffimodTableFilterType.date,
                    operator: EffimodTableFilterOperators.LT,
                    printWidth: 100,
                    printClipText: true,
                    styleClass: ReservationenTableStyleClasses.BIS
                },
                {
                    header: ReservationenTableHeaders.STATUS,
                    fieldSelector: ReservationenTableFieldSelectors.STATUS_BEZEICHNUNG,
                    filterType: EffimodTableFilterType.selection,
                    filterName: ReservationenTableFilterNames.STATUS_ID,
                    operator: EffimodTableFilterOperators.EQ,
                    sortingDisabled: true,
                    selectionOptions: [{ label: 'Alle', value: 'all' }],
                    styleClass: ReservationenTableStyleClasses.STATUS
                }
            ],
            navigatable: true,
            selectMultiple: true
        };

        if (isVeranstaltungSpecific) {
            tableDefinition.columns.push({
                header: ReservationenTableHeaders.VOUCHER,
                fieldSelector: ReservationenTableFieldSelectors.VOUCHER,
                editable: false,
                inputType: EffimodTableInputType.checkbox,
                printHide: true,
                sortingDisabled: true,
                styleClass: ReservationenTableStyleClasses.VOUCHER
            });
        }

        return tableDefinition;
    }
}
