import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'effimod-confirm-before-delete-modal',
  templateUrl: './confirm-before-delete-modal.component.html',
  styleUrls: ['./confirm-before-delete-modal.component.scss']
})
export class ConfirmBeforeDeleteModalComponent implements OnInit {
    @Input() isModalDisplayed: boolean;
    @Input() ConfirmMessage: string;
    @Output() isModalDisplayedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() confirmDeletion: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() {
    }

    onDeleteConfirmed() {
        this.confirmDeletion.emit(true);
        this.closeModal();
    }

    onDeleteCanceled() {
        this.closeModal();
    }

    private closeModal() {
        this.isModalDisplayedChange.emit(false);
    }
}
