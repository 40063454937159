export const EffimodTableFilterOperators = {
    STARTS_WITH: 'startsWith',
    GT: 'gt',
    LT: 'lt',
    EQ: 'eq',
    NEQ: 'neq',
    CONTAINS: 'contains',
    EQUALS: 'equals',
    ENDS_WITH: 'endsWith',
};
