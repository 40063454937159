import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EntityService } from 'src/app/shared/interfaces/entity-service.class';
import { Apollo } from 'apollo-angular';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { User } from '../interfaces/user.interface';
import { UserResponse } from '../graphql/user/model/user.response';
import { AdminApi } from '../graphql/user/user.gql';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsersDataService extends EntityService {

    private userSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public users$: Observable<User[]> = this.userSubject;
    public loading$: Observable<boolean> = this.loadingSubject;

    constructor(
        private readonly apollo: Apollo,
        private readonly notificationService: NotificationService) {
        super();
    }

    public loadEntities(selectInactiveUsers: boolean = false): Observable<User[]> {
        this.loadingSubject.next(true);
        return this.apollo.subscribe<UserResponse>({
                query: AdminApi.queries.alleUser,
                variables: {selectInactiveUsers}
            })
            .pipe(
                map(res => res.data.privateApi.admin.alleUser),
                tap(res => res));
        // .subscribe(
        //     (response) => {
        //         this.loadingSubject.next(false);
        //         this.userSubject.next(response.data.admin.alleUser);
        //     },
        //     (_) => {
        //         this.loadingSubject.next(false);
        //         this.notificationService.notifiy({
        //             text: `Fehler beim Abfragen sämtlicher Auth0User`,
        //             urgency: NotificationUrgency.error
        //         });
        //     }
        // );
    }
}
