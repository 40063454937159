import { Injectable } from '@angular/core';
import { EffimodTableFilterEvent, EffimodTableFilterKeyValue, EffimodTableFilterType, EffimodTableSortingEvent } from '../../../interfaces/effimod-table-definition.interface';
import { EntityService } from '../../../interfaces/entity-service.class';
import { CheckboxFilter, DateTimeFilter, NumberFilter, OrderBy, SelectionFilter, TextFilter } from '../../../../data/interfaces/base-entity-filter.interface';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class EffimodTableService {

    constructor() { }

    changeFilter(service: EntityService, filterEvent: EffimodTableFilterEvent) {
        const filterName = filterEvent.filterName;
        if (!filterName) {
            console.log('error: filterName is not defined');
            return;
        }
        service.removeFilterByForName(filterName);

        if (filterEvent.value === '') {
            return;
        }

        switch (filterEvent.filterType) {
            case EffimodTableFilterType.text:
                this.handleTextFilter(service, filterEvent, filterName);
                break;
            case EffimodTableFilterType.number:
                this.handleNumberFilter(service, filterEvent, filterName);
                break;
            case EffimodTableFilterType.selection:
                this.handleSelectionFilter(service, filterEvent, filterName);
                break;
            case EffimodTableFilterType.date:
                this.handleDateFilter(service, filterEvent, filterName);
                break;
            case EffimodTableFilterType.checkbox:
                this.handleCheckboxFilter(service, filterEvent, filterName);
                break;
        }
    }

    private handleTextFilter(service: EntityService, filterEvent: EffimodTableFilterEvent, filterName: string) {
        const operator = filterEvent.operator ? filterEvent.operator : 'contains';
        service.addFilterBy(new TextFilter({
            field: filterName,
            operator,
            value: filterEvent.value
        }));
    }

    private handleNumberFilter(service: EntityService, filterEvent: EffimodTableFilterEvent, filterName: string) {
        if (filterEvent.operator === 'startsWith') {
            service.addFilterBy(new NumberFilter({
                field: filterName,
                operator: filterEvent.operator,
                value: filterEvent.value
            }));
        } else {
            service.addFilterBy(new NumberFilter({
                field: filterName,
                operator: 'eq',
                value: filterEvent.value
            }));
        }
    }

    private handleSelectionFilter(service: EntityService, filterEvent: EffimodTableFilterEvent, filterName: string) {
        if (filterEvent.value !== 'all') {
            service.addFilterBy(new SelectionFilter({
                field: filterName,
                operator: 'eq',
                value: Number(filterEvent.value)
            }));
        }
    }

    private handleCheckboxFilter(service: EntityService, filterEvent: EffimodTableFilterEvent, filterName: string) {
        service.addFilterBy(new CheckboxFilter({
            field: filterName,
            operator: filterEvent.operator ? filterEvent.operator : 'eq',
            value: filterEvent.value === 'true'
        }));
    }

    private handleDateFilter(service: EntityService, filterEvent: EffimodTableFilterEvent, filterName: string) {
        const operator = filterEvent.operator ? filterEvent.operator : 'gt';
        let filterValueCopy = filterEvent.value;

        if (typeof filterValueCopy === 'string') {
            if (operator === 'lt') {
                filterValueCopy += ' 23:59:59';
            }

            if (moment(filterValueCopy).isValid()) {
                service.addFilterBy(new DateTimeFilter({
                    field: filterName,
                    operator,
                    value: moment(filterValueCopy).locale('de-ch')
                }));
            }
        } else {
            console.error('Invalid date filter value:', filterValueCopy);
        }
    }

    changeSortOrderWithReload(service: EntityService, sortingEvent: EffimodTableSortingEvent) {
        const fieldName = this.getFieldName(sortingEvent.fieldSelector);
        service.addOrderBy(new OrderBy({ field: fieldName, direction: sortingEvent.order }));
        service.loadEntities();
    }

    changeSortOrderWithoutReload(service: EntityService, sortingEvent: EffimodTableSortingEvent) {
        const fieldName = this.getFieldName(sortingEvent.fieldSelector);
        service.addOrderBy(new OrderBy({ field: fieldName, direction: sortingEvent.order }));
    }

    filtersChanged(service: EntityService, filterEvents: EffimodTableFilterKeyValue) {
        service.clearAllFilters();
        service.filterEventList = filterEvents;
        for (const field of Object.keys(filterEvents)) {
            this.changeFilter(service, filterEvents[field] as EffimodTableFilterEvent);
        }
        service.loadEntities();
    }

    private getFieldName(fieldSelector: string[]): string {
        const fieldName = fieldSelector.map(s => {
            return s.charAt(0).toUpperCase() + s.slice(1);
        }).join('');
        return fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
    }
}
