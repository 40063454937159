import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NotificationService } from './notification.service';
import { catchError } from 'rxjs/operators';
import { NotificationUrgency } from '../interfaces/notification.interface';

@Injectable({
    providedIn: 'root'
})
export class CacheDataService {
    private cacheInvalidatorUrl: string = `${environment.api.rest}/cache/invalidate`;

    constructor(private client: HttpClient,
                private notificationService: NotificationService) { }

    public invalidateInsightlyEffimodCache() {
        const url = `${this.cacheInvalidatorUrl}/insightly`;
        this.client.post<void>(url, {}).subscribe(res => {
            this.notificationService.notifiy({
                text: `Insightly aktualisiert`,
                urgency: NotificationUrgency.success
            });
        }),
        catchError(() => {
            this.notificationService.notifiy({
                text: `Fehler beim Aktualisieren von Insightly`,
                urgency: NotificationUrgency.error
            });
            return of(false);
        });
    }

    private invalidateKeys(keys: string[]): Observable<void> {
        const url = `${this.cacheInvalidatorUrl}/keys`;
        return this.client.post<void>(url, keys);
    }

    private invalidateKey(key: string): Observable<void> {
        const url = `${this.cacheInvalidatorUrl}/key`;
        return this.client.post<void>(url, {key});
    }
}
