import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { CustomSerializer, effects, reducers } from './store';
import { registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { NgProgress, NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

Sentry.init({
    dsn: 'https://54c9d05c284a440db95123325fd056d3@sentry.io/1546898'
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
    }

    handleError(error) {
        // todo enable the following only for testing and production environemtns (not development though, because it'd send not-useful error messages more often than desired
        if (environment.production) {
            Sentry.captureException(error.originalError || error);
        }
        if (environment.staging) {
            const eventId = Sentry.captureException(error.originalError || error);
            Sentry.showReportDialog({eventId});
        }
        console.error(error);
    }
}

const fm = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: []
        }
    }
});

export const metaReducers: MetaReducer<any>[] = [];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LayoutModule,
        FontAwesomeModule,
        HttpClientModule,
        ApolloModule,
        HttpLinkModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot(effects),
        StoreRouterConnectingModule.forRoot(),
        NgProgressModule,
        NgProgressHttpModule,
        (environment.production && !environment.staging) ? [] : StoreDevtoolsModule.instrument()
    ],
    providers: [
        {provide: ErrorHandler, useClass: SentryErrorHandler},
        {provide: RouterStateSerializer, useClass: CustomSerializer},
        {provide: NgProgress, useClass: NgProgress},
        {provide: LOCALE_ID, useValue: 'de-ch' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    private readonly cache: InMemoryCache;

    constructor(library: FaIconLibrary,
                private readonly apollo: Apollo,
                private readonly httpLink: HttpLink) {
        library.addIconPacks(fas);

        registerLocaleData(localeDeCh, 'de-CH');

        this.cache = new InMemoryCache({fragmentMatcher: fm});

        // const token = localStorage.get('token');
        const authHeader = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', environment.api.token);
        // .set('Authorization', `Bearer ${token}`);

        const effimodHttpLink = httpLink.create({
            uri: environment.api.graphql,
            headers: authHeader
        });

        const effimodErrorLink = onError(({graphQLErrors, networkError}) => {
            if (graphQLErrors) {
                graphQLErrors.map((err: { message, locations, path }) =>
                    console.log(`[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`)
                );
            }
            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }
        });

        const httpLinkWithErrorHandling = ApolloLink.from([
            effimodHttpLink,
            effimodErrorLink
        ]);

        this.apollo.create({
            link: httpLinkWithErrorHandling,
            ssrMode: true,
            cache: this.cache,
            connectToDevTools: false
            // defaultOptions: {
            //     watchQuery: {
            //         errorPolicy: 'all'
            //     }
            // }
        });
    }
}

