import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../notification.service';
import { NotificationUrgency } from '../../interfaces/notification.interface';
import { finalize, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ShowBlobService } from './show-blob.service';

export class DokumenteBaseService {
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(protected showBlobService: ShowBlobService, protected client: HttpClient, protected notificationService: NotificationService) {}

    protected generateDocument(dokumentApiPath: string, body: any | null, requestOptions: { responseType: 'json' }, mimeType: string, fileName: string, canDownloadFile: boolean = true) {
        this.loading$.next(true);
        this.client.post<any>(dokumentApiPath, body, requestOptions)
            .pipe(
                take(1),
                finalize(() => this.loading$.next(false))
            )
            .subscribe(
                res => {
                    if (canDownloadFile) {
                        const blob: Blob = res as Blob;
                        this.showBlobService.showBlob(blob, mimeType, fileName);
                    } else {
                        // do nothing on purpose...
                    }
                },
                error => this.handleError(error)
            );
    }

    protected handleError(err: HttpErrorResponse): void {
        if (err.status == 400) {
            this.notificationService.notifiy({text: 'Die gewünschte Datei konnte nicht generiert werden.', urgency: NotificationUrgency.error});
            const reader = new FileReader();
            reader.addEventListener('loadend', (e) => {
                const parsedErrorResponse = JSON.parse(e.target['result']);
                if (parsedErrorResponse && parsedErrorResponse.errors) {
                    if (parsedErrorResponse.errors['Bis.Von']) {
                        const errorMessage = parsedErrorResponse.errors['Bis.Von'];
                        this.notificationService.notifiy({text: errorMessage, urgency: NotificationUrgency.error});
                    }
                }
                if (parsedErrorResponse.error) {
                    this.notificationService.notifiy({text: parsedErrorResponse.error[0], urgency: NotificationUrgency.error});
                }
            });
            reader.readAsText(err.error);
        } else {
            console.log('not json');
        }
    }
}
