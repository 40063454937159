import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Veranstaltung } from '../../data/interfaces/veranstaltung.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SelectedVeranstaltungenService {
    private selectedVeranstaltungenSubject = new BehaviorSubject<Veranstaltung[]>([]);
    public selectedVeranstaltungen$ = this.selectedVeranstaltungenSubject.asObservable();

    constructor() {}

    setSelectedVeranstaltungen(veranstaltungen: Veranstaltung[]): void {
        this.selectedVeranstaltungenSubject.next(veranstaltungen);
    }

    clearSelection(): void {
        this.selectedVeranstaltungenSubject.next([]);
    }

    getSelectedVeranstaltungen(): Veranstaltung[] {
        return this.selectedVeranstaltungenSubject.getValue();
    }

    areAllSelectedProvisorisch$(): Observable<boolean> {
        return this.selectedVeranstaltungen$.pipe(
            map(selectedVeranstaltungen =>
                selectedVeranstaltungen.length > 0 &&
                selectedVeranstaltungen.every(v => v.status && v.status.bezeichnung === 'Provisorisch')
            )
        );
    }

    hasSelection$(): Observable<boolean> {
        return this.selectedVeranstaltungen$.pipe(
            map(selectedVeranstaltungen => selectedVeranstaltungen.length > 0)
        );
    }
}
