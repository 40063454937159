import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Reservation } from '../../../data/interfaces/reservation.interface';
import { ReservationenDataService } from '../../../data/services/reservationen-data.service';
import { BlockierungenDataService } from '../../../data/services/blockierungen-data.service';
import { Blockierung } from '../../../data/interfaces/blockierung.interface';

@Component({
    selector: 'effimod-raumbelegung-warning',
    templateUrl: './raumbelegung-warning.component.html',
    styleUrls: ['./raumbelegung-warning.component.scss']
})
export class RaumbelegungWarningComponent implements OnInit, OnChanges {
    // TODO implement service call to get all raumbelegungen of room between beginn and end date

    @Input()
    raumId: string;

    @Input()
    beginn: any;

    @Input()
    ende: any;

    @Input()
    reservationNr: string;


    public reservationen: Reservation[] = [];
    public blockierungen: Blockierung[] = [];
    private beginnDatum: any;
    private endeDatum: any;
    private raum: string;

    constructor(private reservationenDataService: ReservationenDataService, private blockierungenDataService: BlockierungenDataService) { }

    ngOnInit() {
        this.setCheckValues();
    }

    ngOnChanges() {
        if (this.beginn.date != this.beginnDatum || this.ende.date != this.endeDatum || this.raum != this.raumId) {
            this.setCheckValues();
            this.reservationenDataService.getReservationenForRaumAndDateRange(this.raum, this.beginnDatum, this.endeDatum).subscribe(
                result => {
                    if (!result || result.length <= 0) {
                        this.reservationen = [];
                    } else {
                        // todo jonathan: remove this by using new parameter in reservationenquery backend
                        let filteredResult = result
                            .filter(x => x.veranstaltung.status.bezeichnung != 'Annulliert')
                            .filter(x => x.veranstaltung.status.bezeichnung != 'absage');

                        if (this.reservationNr) {
                            filteredResult = filteredResult.filter(x => x.reservationsNummer !== this.reservationNr);
                        }
                        this.reservationen = filteredResult;
                    }
                }
            );
            this.blockierungenDataService.getBlockierungForRaumAndDateRange(this.raum, this.beginnDatum, this.endeDatum).subscribe(
                r => {
                    this.blockierungen = r;
                }
            );
        }
    }

    setCheckValues() {
        if (this.beginn && this.ende && this.raumId) {
            this.beginnDatum = this.beginn;
            this.endeDatum = this.ende;
            this.raum = this.raumId;
        }
    }
}
