import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Notification, NotificationUrgency } from '../interfaces/notification.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public notifications$ = new BehaviorSubject<Notification[]>([]);
    private msgId = 0;
    private notifications = [] as Notification[];

    constructor() {
    }

    public notifiy(notification: { text: string, urgency: NotificationUrgency }): void {
        const msgId = this.msgId + 1;
        this.msgId = msgId;
        this.notifications = [
            ...this.notifications,
            {
                id: msgId,
                text: notification.text,
                urgency: notification.urgency
            } as Notification
        ];
        this.notifications$.next(this.notifications);
        setTimeout(() => {
            this.hide(msgId);
        }, 10000);
    }

    public hide(msgId: number) {
        this.notifications = this.notifications.filter(n => n.id !== msgId);
        this.notifications$.next(this.notifications);
    }

    public notifyError(message: string) {
        this.notifiy({
            text: message,
            urgency: NotificationUrgency.error
        });
    }
}
