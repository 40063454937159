import { Component } from '@angular/core';
import { SelectedVeranstaltungenService } from '../../services/selected-veranstaltungen.service';
import { VeranstaltungenDokumenteService } from '../../services/dokumente/veranstaltungen-dokumente.service';
import { NotificationService } from '../../services/notification.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'effimod-veranstaltungen-generate-agenda-dokument-button',
    templateUrl: './veranstaltungen-generate-agenda-dokument-button.component.html',
    styleUrls: ['./veranstaltungen-generate-agenda-dokument-button.component.scss']
})
export class VeranstaltungenGenerateAgendaDokumentButtonComponent {

    agendaIcon: IconProp = ['fas', 'desktop'];

    constructor(
        public selectedVeranstaltungenService: SelectedVeranstaltungenService,
        private veranstaltungenDokumenteService: VeranstaltungenDokumenteService,
        private notificationService: NotificationService
    ) {}

    generateAgenda(): void {
        const selectedVeranstaltungen = this.selectedVeranstaltungenService.getSelectedVeranstaltungen();
        if (!selectedVeranstaltungen.length) {
            this.notificationService.notifyWarning('Bitte wählen Sie mindestens eine Veranstaltung aus.');
            return;
        }

        const ids = selectedVeranstaltungen.map(x => x.id);
        this.veranstaltungenDokumenteService.generateAgenda(ids);
        this.notificationService.notifyInfo('Agenda Dokument wird generiert...');
    }
}
