import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ArtikelService } from '../../data/services/artikel.service';

import * as fromActions from '../actions';
import * as fromStore from '../selectors';
import { AppState } from '../index';
import { select, Store } from '@ngrx/store';

@Injectable()
export class ArtikelEffects {
    @Effect()
    loadArtikel$ = this.actions$.pipe(
        ofType(fromActions.loadArtikel),
        switchMap((action) => {
            return this.artikelService.loadArtikel()
                .pipe(
                    map(res => fromActions.loadArtikelSuccess({artikel: res})),
                    catchError(errorMessage => of(fromActions.loadArtikelFail({errorMessage})))
                );
        })
    );

    @Effect()
    loadBestellbareArtikel$ = this.actions$.pipe(
        ofType(fromActions.loadBestellbareArtikel),
        switchMap((action) => {
            return this.artikelService.loadBestellbareArtikel()
                .pipe(
                    map(res => fromActions.loadBestellbareArtikelSuccess({artikel: res})),
                    catchError(errorMessage => of(fromActions.loadBestellbareArtikelFail({errorMessage})))
                );
        })
    );

    @Effect()
    SelectedKategorieChanged$ = this.actions$.pipe(
        ofType(fromActions.SelectAnotherKategorieName),
        switchMap(action => {
            const name = fromStore.getSelectedKategorieName;
            return this.store.pipe(select(fromStore.getArtikelByKategorieName, {kategorieName: name}))
                .pipe(map(res => fromActions.SelectAnotherKategorieNameSuccess({artikel: res})));
        })
    );

    constructor(private actions$: Actions, private artikelService: ArtikelService, private store: Store<AppState>) {
    }
}
