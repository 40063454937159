export const VeranstaltungenTableFieldSelectors = {
    ID: ['id'],
    TITEL: ['titel'],
    BEGINN: ['beginn'],
    ENDE: ['ende'],
    VERANSTALTER_NAME: ['veranstalterVersion', 'name'],
    VERANSTALTER_ORT: ['veranstalterVersion', 'adresse', 'ort'],
    SACHBEARBEITER_NAME: ['sachbearbeiter', 'name'],
    STATUS_BEZEICHNUNG: ['status', 'bezeichnung']
};
