export const ReservationenTableFilterNames = {
    RESERVATION_NUMMER: 'reservationsNummer',
    BESCHRIFTUNG: 'beschriftung',
    RAUM_ID: 'raumId',
    VERANSTALTER_NAME: 'veranstalterName',
    VERANSTALTER_ORT: 'veranstalterOrt',
    BEGINN: 'beginn',
    ENDE: 'ende',
    STATUS_ID: 'statusId',
    VOUCHER: 'voucher',
    LIEFERANTEN_FLAG: 'lieferantenFlag'
};
