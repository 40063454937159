import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-kunden-typ-selector-form',
    templateUrl: './kunden-typ-selector-form.component.html',
    styleUrls: ['./kunden-typ-selector-form.component.scss']
})
export class KundenTypSelectorFormComponent implements OnInit {
    @Input()
    isReadonly: boolean = false;

    @Input()
    parent: FormGroup;

    constructor() {
    }

    ngOnInit() {
    }

}

