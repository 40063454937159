import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'effimod-modal-card-wrapper',
    templateUrl: './modal-card-wrapper.component.html',
    styleUrls: ['./modal-card-wrapper.component.scss']
})
export class ModalCardWrapperComponent implements OnInit {

    @Input() modalTitle: string = '[Modal-Title]';
    @Input() submitButtonText: string = 'Speichern';
    @Input() cancelButtonText: string = 'Abbrechen';
    @Input() deleteButtonText: string = '';

    @Input() submitButtonDisabledReason: string = '';
    @Input() isSubmitButtonDisabled: boolean = false;
    @Input() isActive: boolean = false;
    @Input() showIcons: boolean = false;

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.onCancel();
    }

    onSubmit() {
        this.confirm.emit();
    }

    onCancel() {
        this.cancel.emit();
    }

    onDelete() {
        this.delete.emit();
    }
}
