import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseKundenDataService } from '../../../data/services/kunden/base-kunden-data.service';
import { Observable } from 'rxjs';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';

@Component({
    selector: 'effimod-veranstaltung-erfassen-button',
    templateUrl: './veranstaltung-erfassen-button.component.html',
    styleUrls: ['./veranstaltung-erfassen-button.component.scss']
})
export class VeranstaltungErfassenButtonComponent implements OnInit {

    effimodPermissionEnum = EffimodPermissionEnum;
    isVeranstaltungErfassenEnabled$: Observable<boolean>;
    private veranstalterVersion: KundeVersion;

    constructor(private baseKundenDataService: BaseKundenDataService, private router: Router) {}

    ngOnInit() {
        this.isVeranstaltungErfassenEnabled$ = this.baseKundenDataService.kunde$.pipe(
            map((kunde: KundeVersion) => {
                this.veranstalterVersion = kunde;
                return !kunde || !!(kunde && kunde.externalCrmKundeId);
            })
        );
    }

    navigateToVeranstaltungErfassen() {
        if (this.veranstalterVersion) {
            const queryParams = {
                queryParams: {
                    isFirma: this.veranstalterVersion.kunde.isFirma,
                    kundeVersionId: this.veranstalterVersion.id
                }
            };
            this.router.navigate(['veranstaltungen', 'erfassen'], queryParams);
        } else {
            this.router.navigate(['veranstaltungen', 'erfassen']);
        }
    }

}
