import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Raum } from '../../data/interfaces/raum.interface';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { AppState } from '../../store';
import { catchError, first } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { NotificationUrgency } from '../interfaces/notification.interface';

@Injectable({
    providedIn: 'root'
})
export class RaeumeResolver implements Resolve<Raum[]> {

    constructor(private store: Store<AppState>, private notificationService: NotificationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Raum[]> {
        return this.store.select(fromStore.getRaeume).pipe(
            first(raeume => raeume && raeume.length > 0),
            catchError(() => {
                this.notificationService.notifiy({
                    urgency: NotificationUrgency.error,
                    text: 'Die Räume konnten nicht geladen werden'
                });
                return of([]);
            })
        );
    }
}
