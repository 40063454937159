import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';

@Injectable({providedIn: 'root'})
export class EffimodUsersResolver implements Resolve<any> {
    constructor(private store: Store<AppState>) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.store.dispatch(fromStore.loadUsers());
    }
}
