import gql from 'graphql-tag';
import { BaseEntityFilter } from '../../interfaces/base-entity-filter.interface';
import { DocumentNode } from 'graphql';
import { Blockierung } from '../../interfaces/blockierung.interface';
import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';

export const BlockierungenApi: any = {};

BlockierungenApi.fragments = {
    blockierung: gql`
        fragment BlockierungFragment on BlockierungType {
            id
            createdOn
            lastModified
            titel
            beginn
            ende
            raum {
                id
                bezeichnung
                geschoss {
                    id
                    bezeichnung
                }
            }
            voucherUsername
        }
    `,
    blockierungForListing: gql`
        fragment BlockierungForListingFragment on BlockierungType {
            id
            titel
            beginn
            ende
            raum {
                id
                bezeichnung
            }
            voucherUsername
        }
    `
};

BlockierungenApi.mutations = {
    erstellen: gql`
        mutation blockierungErstellen($blockierung: blockierungInput!) {
            blockierungen {
                erstellen(blockierung: $blockierung) {
                    ...BlockierungFragment
                }
            }
        }
        ${BlockierungenApi.fragments.blockierung}
    `,
    bearbeiten: gql`
        mutation blockierungBearbeiten($blockierung: blockierungUpdateInput!) {
            blockierungen {
                bearbeiten(blockierung: $blockierung) {
                    ...BlockierungFragment
                }
            }
        }
        ${BlockierungenApi.fragments.blockierung}
    `,
    delete: gql`
        mutation deleteBlockierung($blockierungId: Int!) {
            blockierungen {
                delete(id: $blockierungId)
            }
        }
    `
};

export interface BlockierungenResponse {
    privateApi: {
        blockierungen: {
            alleBlockierungen: Blockierung[];
        };
    };
    blockierungen: {
        erstellen: Blockierung;
        bearbeiten: Blockierung;
        delete: number;
    };
}

@Injectable({providedIn: 'root'})
export class CreateBlockierungMutationGQL extends Mutation<BlockierungenResponse, {}> {
    document = BlockierungenApi.mutations.erstellen;
}

@Injectable({providedIn: 'root'})
export class UpdateBlockierungMutationGQL extends Mutation<BlockierungenResponse, {}> {
    document = BlockierungenApi.mutations.bearbeiten;
}

@Injectable({providedIn: 'root'})
export class DeleteBlockierungMutationGQL extends Mutation<BlockierungenResponse, {}> {
    document = BlockierungenApi.mutations.delete;
}

export function getBlockierungen(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    let fragment = 'BlockierungForListingFragment';
    let fragmentImport = BlockierungenApi.fragments.blockierungForListing;
    let displayDetails = ', displayDetails: false';
    if (withDetails) {
        fragment = 'BlockierungFragment';
        fragmentImport = BlockierungenApi.fragments.blockierung;
        displayDetails = ', displayDetails: true';
    }
    let filterOrderString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        filterOrderString += `, filter: {${filter.filterBy.map(f => f.toQuery()).join(',')}}`;
    }
    if (filter.orderBy && filter.orderBy.length > 0) {
        filterOrderString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }
    const query = gql`
        query getBlockierung 
        {
            privateApi {
                blockierungen {
                    alleBlockierungen(take: ${filter.take}, skip: ${filter.skip}${filterOrderString}${displayDetails}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;
    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

function getGqlString(doc: DocumentNode) {
    return doc.loc && doc.loc.source.body;
}
