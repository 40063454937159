import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import * as fromStore from '../../../store';
import { AppState, getVeranstaltungStatusEntities } from '../../../store';
import { KundeVersion } from 'src/app/data/interfaces/kunde-version.interface';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { VeranstaltungStatus } from 'src/app/data/interfaces/VeranstaltungStatus';
import { User } from '../../../data/interfaces/user.interface';
import { GraphQLVeranstaltungStatusUpdateInput } from 'src/app/data/graphql/veranstaltungen/input/veranstaltung-status-update.input';

import { VeranstaltungenDataService } from '../../../data/services/veranstaltungen-data.service';
import { VeranstaltungenDokumenteService } from '../../services/dokumente/veranstaltungen-dokumente.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationUrgency } from '../../interfaces/notification.interface';

import { EffimodTableContent } from '../../interfaces/effimod-table-content.interface';
import { EffimodTableDefinition, EffimodTableFilterKeyValue, EffimodTableSelectionEvent, EffimodTableSortingEvent } from '../../interfaces/effimod-table-definition.interface';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { NavigationBarActionEnum } from '../../enums/navigation-bar-action.enum';
import { KundenView } from '../../enums/view-enum';
import { VeranstaltungListExtensionData } from './model/veranstaltung-list-extension-data.model';
import { VeranstaltungenTableDefinitionService } from './services/veranstaltungen-table-definition.service';


@Component({
    selector: 'effimod-veranstaltungen-list',
    templateUrl: './veranstaltungen-list.component.html',
    styleUrls: ['./veranstaltungen-list.component.scss']
})
export class VeranstaltungenListComponent implements OnInit, OnChanges {

    @Input() view: KundenView = KundenView.None;
    @Input() extensionData: VeranstaltungListExtensionData;
    @Input() veranstaltungen: Veranstaltung[];
    @Input() veranstalterVersion: KundeVersion;
    @Input() selectable: boolean;
    @Input() isStatic: boolean;
    @Input() sortingDisabled: boolean = true;
    @Input() previousFilters: EffimodTableFilterKeyValue;

    @Output() filtersChanged = new EventEmitter<EffimodTableFilterKeyValue>();
    @Output() sortOrderChanged = new EventEmitter<EffimodTableSortingEvent>();
    @Output() selectionChanged = new EventEmitter<null>();
    @Output() deleted = new EventEmitter<number[]>();
    @Output() statusChange = new EventEmitter<null>();
    @Output() kundeFormStatusChange = new EventEmitter<NavigationBarActionEnum>();

    public status$: Observable<{ [id: string]: VeranstaltungStatus }>;
    public effimodPermissionEnum = EffimodPermissionEnum;
    public loading$: Observable<boolean>;

    public content: EffimodTableContent = {
        items: [],
        take: 0,
        skip: 0,
        totalItems: 0
    };

    public tableDefinition: EffimodTableDefinition;
    public selectedVeranstaltungen: Veranstaltung[] = [];
    public displayDeletionCheckback: boolean = false;
    public users$: Observable<User[]>;
    public navigationBarActionEnum = NavigationBarActionEnum;
    public isInsightlyVeranstalter: boolean = true;

    public isAgendaEnabled: boolean = false;
    public selectionMadeAndProvisorisch: boolean = false;

    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly veranstaltungenDokumenteService: VeranstaltungenDokumenteService,
        private readonly notificationService: NotificationService,
        private readonly veranstaltungenDataService: VeranstaltungenDataService,
        private readonly veranstaltungenTableDefinitionService: VeranstaltungenTableDefinitionService
    ) {}

    ngOnInit() {
        this.loading$ = this.veranstaltungenDokumenteService.loading$;
        this.initTableDefinition();
        this.content.items = this.veranstaltungen || [];
        this.initializeStatus();
        this.initializeUsers();
        this.isInsightlyVeranstalter = !!(this.veranstalterVersion && this.veranstalterVersion.externalCrmKundeId);
        this.evaluateButtonStates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.veranstaltungen) {
            this.content.items = changes.veranstaltungen.currentValue || [];
            this.selectedVeranstaltungen = [];
        }
    }

    private initializeStatus() {
        this.status$ = this.store.pipe(
            select(getVeranstaltungStatusEntities),
            first(),
            tap(res => {
                const status = Object.values(res).map(r => ({ label: r.bezeichnung, value: r.id }));
                const statusColumn = this.tableDefinition.columns.find(c => c.header === 'Status');
                if (statusColumn) {
                    statusColumn.selectionOptions.push(...status);
                }
            })
        );
    }

    private initializeUsers() {
        this.users$ = this.store.select(fromStore.getUserEntities).pipe(
            filter(users => !!users && Object.keys(users).length > 0),
            first(),
            map(users => {
                const userValues = Object.values(users).filter(x => !x.isInaktiv);
                const userOptions = userValues.map(u => ({ label: u.name, value: u.id }));
                const userColumn = this.tableDefinition.columns.find(c => c.header === 'Sachbearbeiter');
                if (userColumn) {
                    userColumn.selectionOptions.push(...userOptions);
                }
                return userValues;
            })
        );
    }

    private evaluateButtonStates() {
        this.isAgendaEnabled = this.selectionMade();
        this.selectionMadeAndProvisorisch = this.selectionMade() && this.allSelectedVeranstaltungenProvisorisch();
    }

    onSelectionChanged(event: EffimodTableSelectionEvent) {
        this.selectedVeranstaltungen = event.selection;
        this.evaluateButtonStates();
    }

    triggerNavigationBarAction(navigationBarActionEnum: NavigationBarActionEnum) {
        this.kundeFormStatusChange.emit(navigationBarActionEnum);
    }

    onFiltersChanged(filterEvents: EffimodTableFilterKeyValue) {
        this.filtersChanged.emit(filterEvents);
    }

    onSortOrderChanged(sortingEvent: EffimodTableSortingEvent) {
        this.sortOrderChanged.emit(sortingEvent);
    }

    onNavigate(data: any) {
        this.router.navigateByUrl('/veranstaltungen/' + data.id);
    }

    onDeleteVeranstaltungen() {
        this.closeAllModals();
        const deletedVeranstaltungIds = this.selectedVeranstaltungen.map(v => v.id);
        deletedVeranstaltungIds.forEach(id => this.veranstaltungenDataService.deleteVeranstaltung(id, false));
        this.content.items = this.content.items.filter(item => !deletedVeranstaltungIds.includes(item.id));
        this.deleted.emit(deletedVeranstaltungIds);
    }

    changeVeranstaltungenStatus() {
        const veranstaltungStatusUpdate: GraphQLVeranstaltungStatusUpdateInput = {
            veranstaltungenId: this.selectedVeranstaltungen.map(v => v.id)
        };

        const listToChange = this.selectedVeranstaltungen.map(sv => {
            const index = this.veranstaltungen.findIndex(v => v.id === sv.id);
            return { v: this.veranstaltungen[index], index };
        });

        this.selectedVeranstaltungen.forEach(sv => {
            const index = this.veranstaltungen.findIndex(v => v.id === sv.id);
            this.veranstaltungen.splice(index, 1);
        });

        this.veranstaltungenDataService.updateVeranstaltungStatus(veranstaltungStatusUpdate).subscribe(() => {
            this.veranstaltungenDataService.loadEntities();
            listToChange.forEach(vc => {
                vc.v.status.id = 2;
                vc.v.status.bezeichnung = 'Definitiv';
                this.veranstaltungen.splice(vc.index, 0, vc.v);
            });
            this.notificationService.notifiy({
                text: `Provisorisch zu Definitiv geändert`,
                urgency: NotificationUrgency.success
            });
        });
    }

    generateAgenda() {
        const ids = this.selectedVeranstaltungen.map(x => x.id);
        this.veranstaltungenDokumenteService.generateAgenda(ids);
    }

    private closeAllModals() {
        this.displayDeletionCheckback = false;
    }

    private initTableDefinition() {
        this.tableDefinition = this.veranstaltungenTableDefinitionService.getTableDefinition(this.sortingDisabled);
    }

    private allSelectedVeranstaltungenProvisorisch(): boolean {
        return this.selectedVeranstaltungen.every(x => x.status && x.status.bezeichnung === 'Provisorisch');
    }

    private selectionMade(): boolean {
        return this.selectedVeranstaltungen && this.selectedVeranstaltungen.length > 0;
    }

    onVeranstaltungKopiert(copy: Veranstaltung): void {
        this.content.items = [copy, ...this.content.items];
    }
}
