import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { GraphQLAnsprechpersonenUpdateInput } from '../../../data/graphql/veranstaltungen/input/ansprechpersonen-update.input';
import { KundenTypOptions } from '../kunden-typ-selector/kunden-typ-options';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { VeranstaltungenDataService } from '../../../data/services/veranstaltungen-data.service';
import { EffimodPermissionEnum } from '../../enums/permission.enum';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'effimod-veranstaltungen-batch-assign-ansprechperson-button',
    templateUrl: './veranstaltungen-batch-assign-ansprechperson-button.component.html',
    styleUrls: ['./veranstaltungen-batch-assign-ansprechperson-button.component.scss']
})
export class VeranstaltungenBatchAssignAnsprechpersonButtonComponent implements OnChanges {
    effimodPermissionEnum = EffimodPermissionEnum;

    @Input() public veranstaltungen: Veranstaltung[];

    public cachedGraphQLAnsprechpersonenUpdateInput: GraphQLAnsprechpersonenUpdateInput | null = null;
    public preselectedAnsprechpersonKundenTyp: KundenTypOptions = KundenTypOptions.kontakt;

    constructor(
        private veranstaltungenDataService: VeranstaltungenDataService,
        private notificationService: NotificationService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.veranstaltungen && this.veranstaltungen) {
            this.cacheVeranstaltungIdsFromTableSelection();
        }
    }

    cacheVeranstaltungIdsFromTableSelection() {
        if (!this.veranstaltungen || this.veranstaltungen.length === 0) {
            this.cachedGraphQLAnsprechpersonenUpdateInput = null;
            return;
        }

        this.cachedGraphQLAnsprechpersonenUpdateInput = {
            veranstaltungenIds: this.veranstaltungen.map(v => v.id),
            newAnsprechpersonVersionId: null  // This will be set later
        };

        this.preselectedAnsprechpersonKundenTyp = KundenTypOptions.kontakt;
    }

    async onAnsprechpersonSelectionConfirmed(ansprechpersonVersion: KundeVersion) {
        if (!this.cachedGraphQLAnsprechpersonenUpdateInput) {
            this.notificationService.notifyError('Der Vorgang konnte nicht durchgeführt werden, da die Veranstaltungsauswahl fehlt.');
            return;
        }

        this.cachedGraphQLAnsprechpersonenUpdateInput.newAnsprechpersonVersionId = ansprechpersonVersion.id;

        try {
            await this.veranstaltungenDataService.updateAnsprechpersonen(this.cachedGraphQLAnsprechpersonenUpdateInput).toPromise();
            this.cachedGraphQLAnsprechpersonenUpdateInput = null;
        } catch (error) {
            this.notificationService.notifyError('Fehler beim Aktualisieren der Ansprechpersonen.');
        }
    }
}
