import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routerLink'
})
export class RouterLinkPipe implements PipeTransform {

  transform(object: any, routerLinkBase: string, idCol: string): any {
      return `/${routerLinkBase}/${object[idCol]}`;
  }

}
