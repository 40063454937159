import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-kunde-erstellen-form',
    templateUrl: './kunde-erstellen-form.component.html',
    styleUrls: ['./kunde-erstellen-form.component.scss']
})
export class KundeErstellenFormComponent implements OnInit {

    @Input()
    showAdresse: boolean;

    @Input()
    parent: FormGroup;

    constructor() { }

    ngOnInit() { }
}
