import { Component, Input } from '@angular/core';

@Component({
    selector: 'effimod-crm-link',
    templateUrl: './crm-link.component.html',
    styleUrls: ['./crm-link.component.scss']
})
export class CrmLinkComponent {
    @Input() tooltip: string;
    @Input() link: string;
    @Input() logo: string;
    @Input() displayText: string;

    navigateToLink() {
        if (this.link) {
            window.open(this.link, '_blank');
        }
    }
}

