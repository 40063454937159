import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EffimodTableColumnDefinition, EffimodTableInputType } from '../../../../interfaces/effimod-table-definition.interface';

@Component({
    selector: 'effimod-table-field-editable',
    templateUrl: './effimod-table-field-editable.component.html',
    styleUrls: ['./effimod-table-field-editable.component.scss']
})
export class EffimodTableFieldEditableComponent implements OnInit {

    @Input() item: any;
    @Input() colDefinition: EffimodTableColumnDefinition;
    @Input() idColumnSelector: string;

    // @Output() colValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() edited: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    get effimodTableInputTypes() { return EffimodTableInputType; }

    onDropdownSelectionChanged(event: any) {
        this.edited.emit({colDefinition: this.colDefinition, value: event.value, idColumnSelector: this.idColumnSelector});
    }
}
