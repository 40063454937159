import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { KundeVersion } from 'src/app/data/interfaces/kunde-version.interface';
import { FormGroup } from '@angular/forms';
import { KundeFormService } from 'src/app/shared/services/forms/kunde-form.service';
import { tap } from 'rxjs/operators';


@Component({
    selector: 'effimod-kunde-erstellen',
    templateUrl: './kunde-erstellen.component.html',
    styleUrls: ['./kunde-erstellen.component.scss']
})
export class KundeErstellenComponent implements OnInit, OnDestroy {

    @Output()
    formContentChange: EventEmitter<KundeVersion> = new EventEmitter<KundeVersion>();

    public kontakt$: Observable<KundeVersion>;
    public form: FormGroup;
    public formStatusChangeSubscription: Subscription;
    public firmaEmpty = true;

    constructor(
        private readonly kundeFormService: KundeFormService) { }

    ngOnInit() {
        this.form = this.kundeFormService.createCreateKundeForm();

        // To make sure that the confirmation button from the modal is initially disabled
        this.formContentChange.emit(null);

        this.formStatusChangeSubscription = this.form.statusChanges
            .pipe(
                tap(() => {
                    // need to check here if organisation selected
                    if (this.form.valid && !this.firmaEmpty) {
                        const kundeVersion = this.kundeFormService.mapKundeFormToKunde(this.form);
                        this.formContentChange.emit(kundeVersion);
                    } else {
                        this.formContentChange.emit(null);
                    }
                })
            )
        .subscribe();
    }

    onAssociatedOrganisationSelected(organisation: KundeVersion) {
        this.form = this.kundeFormService.updateCreateAnsprechpersonFormWithFirma(this.form, organisation);
        this.firmaEmpty = false;
    }

    ngOnDestroy() {
        if (this.formStatusChangeSubscription) {
            this.formStatusChangeSubscription.unsubscribe();
        }
    }

    fieldCleared() {
        this.firmaEmpty = true;
        this.formContentChange.emit(null);
    }
}
