export const ReservationenTableStyleClasses = {
    RESERVATION_NUMMER: 'reservation-nummer',
    BESCHRIFTUNG: 'reservation-beschriftung',
    RAUM: 'reservation-raum',
    VERANSTALTER: 'reservation-veranstalter-name',
    ORT: 'reservation-veranstalter-ort',
    VON: 'reservation-beginn',
    BIS: 'reservation-ende',
    STATUS: 'reservation-status',
    VOUCHER: 'reservation-has-voucher',
    LIEFERANTEN_FLAG: 'lieferanten-flag'
};
