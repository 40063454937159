import { createAction, props } from '@ngrx/store';
import { Artikel } from '../../data/interfaces/artikel.interface';

export const loadArtikel = createAction('[Artikel] load');
export const loadArtikelSuccess = createAction('[Artikel] load success', props<{ artikel: Artikel[] }>());
export const loadArtikelFail = createAction('[Artikel] load failed', props<{ errorMessage: string }>());
export const loadBestellbareArtikel = createAction('[Artikel] load bestellbare Artikel');
export const loadBestellbareArtikelSuccess = createAction('[Artikel] load bestellbare Artikel success', props<{ artikel: Artikel[] }>());
export const loadBestellbareArtikelFail = createAction('[Artikel] load bestellbare Artikel failed', props<{ errorMessage: string }>());
export const SelectAnotherKategorieName = createAction('[ArtikelKategorie] select another KategorieName', props<{selectedKategorieName: string}>());
export const SelectAnotherKategorieNameSuccess = createAction('[ArtikelKategorie] select another KategorieName success', props<{artikel: Artikel[]}>());
