import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import * as fromActions from '../actions';
import { GebaeudeService } from '../../data/services/gebaeude.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class GebaeudeEffects {
    @Effect()
    loadGeschosse$ = this.actions$.pipe(
        ofType(fromActions.loadGeschosse, fromActions.loadAll),
        switchMap((action) => {
            return this.gebaeudeService.loadGeschosse()
                .pipe(
                    map(res => fromActions.loadGeschosseSuccess({geschosse: res})),
                    catchError(errorMessage => of(fromActions.loadGeschosseFail({errorMessage})))
                );
        })
    );
    @Effect()
    loadRaeume$ = this.actions$.pipe(
        ofType(fromActions.loadRaeume, fromActions.loadAll),
        switchMap((action) => {
            return this.gebaeudeService.loadRaeume()
                .pipe(
                    map(res => fromActions.loadRaeumeSuccess({raeume: res})),
                    catchError(errorMessage => of(fromActions.loadRaeumeFail({errorMessage})))
                );
        })
    );

    constructor(private actions$: Actions, private gebaeudeService: GebaeudeService) {
    }
}
