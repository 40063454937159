import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'effimod-datetime-labeled-input',
  templateUrl: './datetime-labeled-input.component.html',
  styleUrls: ['./datetime-labeled-input.component.scss']
})
export class DatetimeLabeledInputComponent implements OnInit {
    @Input()
    disabled: boolean = false;

    @Input()
    inputType: 'text' | 'number' = 'text';

    @Input()
    parent: FormGroup;

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    formControlNameString: string;

    @Input()
    dateFormat: string = 'dd.MM.yyyy HH:mm:ss';

    constructor() {
    }

    ngOnInit() {
    }

}
