import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Artikel } from '../interfaces/artikel.interface';
import { Apollo } from 'apollo-angular';
import { ArtikelParameters, ArtikelResponse } from '../graphql/artikel/model/artikel.response';
import { ArtikelApi } from '../graphql/artikel/artikel.gql';
import { map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class ArtikelService {

    constructor(private readonly apollo: Apollo) {
    }

    public loadArtikel(kategorieId?: number): Observable<Artikel[]> {
        const queryParams: ArtikelParameters = {kategorieId};
        return this.apollo.subscribe<ArtikelResponse>({
                query: ArtikelApi.queries.getArtikel,
                variables: queryParams
            })
            .pipe(
                map(res => {
                    return res.data.publicApi.artikel.alleArtikel;
                })
            );
    }

    public loadBestellbareArtikel(): Observable<Artikel[]> {
        return this.apollo.subscribe<ArtikelResponse>({query: ArtikelApi.queries.getBestellbareArtikel})
            .pipe(
                map(res => {
                    return res.data.publicApi.artikel.bestellbareArtikel;
                })
            );
    }
}
