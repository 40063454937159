import { Component } from '@angular/core';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { NotificationService } from '../../services/notification.service';
import { BaseKundeSelectionModalButtonComponent } from '../base-kunde-selection-modal-button/base-kunde-selection-modal-button.component';
import { KundeVersionSyncService } from '../../services/kunde-version-sync.service';

@Component({
    selector: 'effimod-veranstalter-selection-modal-button',
    templateUrl: './veranstalter-selection-modal-button.component.html',
    styleUrls: ['./veranstalter-selection-modal-button.component.scss']
})
export class VeranstalterSelectionModalButtonComponent extends BaseKundeSelectionModalButtonComponent {

    constructor(notificationService: NotificationService, protected kundeVersionSyncService: KundeVersionSyncService) {
        super(notificationService, kundeVersionSyncService);
        this.buttonTooltip = 'Veranstalter ändern';
        this.modalTitle = 'Veranstalter auswählen';
        this.modalSubmitButtonText = 'bestätigen';
        this.preselectedKundenTypOption = KundenTypOptions.organisation;
    }
}
