import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-adresse-form',
    templateUrl: './adresse-form.component.html',
    styleUrls: ['./adresse-form.component.scss']
})
export class AdresseFormComponent implements OnInit {

    @Input()
    addOptionalKeywordForLabelText = false;

    @Input()
    adresseForm: FormGroup;

    @Input()
    showLand: boolean = false;

    constructor() { }

    ngOnInit() { }

    get strasseLabelText() {
        return this.addOptionalKeywordForLabelText ? `strasse (optional)` : 'strasse';
    }

    get postleitzahlLabelText() {
        return this.addOptionalKeywordForLabelText ? 'PLZ (optional)' : 'PLZ';
    }

    get ortLabelText() {
        return this.addOptionalKeywordForLabelText ? 'ort (optional)' : 'ort';
    }

    get landLabelText() {
        return this.addOptionalKeywordForLabelText ? 'land (optional)' : 'land';
    }
}
