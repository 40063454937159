import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { VeranstaltungStatus } from '../../../interfaces/VeranstaltungStatus';
import { VeranstaltungStatusApi } from './veranstaltung-status.gql';

export interface VeranstaltungStatusResponse {
    publicApi: {
        veranstaltungStatus: {
            alle: VeranstaltungStatus[]
        };
    };
}

@Injectable({providedIn: 'root'})
export class GetVeranstaltungStatusGQL extends Query<VeranstaltungStatusResponse, {}> {
    document = VeranstaltungStatusApi.queries.alle;
}


