import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EffimodTableColumnDefinition, EffimodTableInputType } from '../../../../interfaces/effimod-table-definition.interface';

@Component({
    selector: 'effimod-table-field',
    templateUrl: './effimod-table-field.component.html',
    styleUrls: ['./effimod-table-field.component.scss']
})
export class EffimodTableFieldComponent implements OnInit {

    @Input() item: any;
    @Input() colDefinition: EffimodTableColumnDefinition;
    @Input() idColumnSelector: string;
    public effimodTableInputTypes = EffimodTableInputType;

    constructor() { }

    ngOnInit() {
    }
}
