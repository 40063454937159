import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/de-ch';
moment.locale('de-ch');

@Pipe({
  name: 'weekdayOnly'
})
export class WeekdayOnlyPipe implements PipeTransform {

      transform(value: Date): string {
          return `${moment(value).format('dddd')}`;
      }

}
