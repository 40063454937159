import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'effimod-email-labeled-input',
    templateUrl: './email-labeled-input.component.html',
    styleUrls: ['./email-labeled-input.component.scss']
})
export class EmailLabeledInputComponent implements OnInit, OnChanges {
    @Input()
    veranstaltungsbezeichnung: string;

    @Input()
    kunde: any;

    @Input()
    parent: FormGroup;

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    formControlNameString: string;

    public ansprache: string;

    public mailtoLink: string = '';

    constructor() {
    }

    ngOnInit() {
        this.SetupMailToLink();
    }

    ngOnChanges() {
        this.SetupMailToLink();
    }

    private SetupMailToLink() {
        this.setAnsprache();
        this.mailtoLink = `mailto:${this.kunde.email}?subject=${encodeURIComponent(this.veranstaltungsbezeichnung)}&body=${this.ansprache}`;
    }
    
    private setAnsprache() {
        if (this.kunde && this.kunde.anrede) {
            this.ansprache = 'Sehr geehrte';
            if (this.kunde.anrede == 'Frau') {
                this.ansprache += ' ' + this.kunde.anrede;
            } else if (this.kunde.anrede == 'Herr') {
                this.ansprache += 'r ' + this.kunde.anrede;
            } else {
                this.ansprache += '/r ' + this.kunde.anrede;
            }
            this.ansprache += ' ' + this.kunde.nachname;
        } else {
            this.ansprache = 'Sehr geehrte/r Herr/Frau ' + this.kunde.nachname;
        }
    }
}
