import { Component } from '@angular/core';
import { BaseKundeSelectionModalButtonComponent } from '../base-kunde-selection-modal-button/base-kunde-selection-modal-button.component';
import { NotificationService } from '../../services/notification.service';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { KundeVersionSyncService } from '../../services/kunde-version-sync.service';

@Component({
  selector: 'effimod-rechnungsadresse-selection-modal-button',
  templateUrl: './rechnungsadresse-selection-modal-button.component.html',
  styleUrls: ['./rechnungsadresse-selection-modal-button.component.scss']
})
export class RechnungsadresseSelectionModalButtonComponent extends BaseKundeSelectionModalButtonComponent {

    constructor(notificationService: NotificationService, protected kundeVersionSyncService: KundeVersionSyncService) {
        super(notificationService, kundeVersionSyncService);
        this.buttonTooltip = 'Rechnungsadresse ändern';
        this.modalTitle = 'Rechnungsadresse auswählen';
        this.modalSubmitButtonText = 'bestätigen';
        this.preselectedKundenTypOption = KundenTypOptions.organisation;
    }
}
