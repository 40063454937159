import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fieldSelector'
})
export class FieldSelectorPipe implements PipeTransform {

    transform(object: any, selectorArr: string[]): any {
        if (selectorArr.length === 0) { return 'no selector'; }
        return selectorArr.reduce((obj, selector) => {
            return obj ? obj[selector] : '-';
        }, object);
    }

}
