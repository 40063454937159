import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actionValidation'
})
export class ActionValidationPipe implements PipeTransform {

  transform(value: any, propArr: any[], expected: any): any {
        let temp = value;
      // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < propArr.length; i++) {
           temp = temp[`${propArr[i]}`];
        }
        return temp === expected;
  }

}
