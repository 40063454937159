export const ReservationenTableHeaders = {
    RESERVATION_NUMMER: 'Nummer',
    BESCHRIFTUNG: 'Beschriftung',
    RAUM: 'Raum',
    VERANSTALTER: 'Veranstalter',
    ORT: 'Ort',
    VON: 'Von',
    ENDE: 'Bis',
    STATUS: 'Status',
    VOUCHER: 'Voucher'
};

