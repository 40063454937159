import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import { KontakteApi } from '../kontakte/kontakte.gql';
import { KundeVersion } from '../../../interfaces/kunde-version.interface';

export interface EffimodKontaktResponse {
    kontakte: {
        createEffimodKontakt: KundeVersion;
        updateEffimodKontakt: KundeVersion;
    };
}


@Injectable({providedIn: 'root'})
export class CreateEffimodKontakteMutationsGQL extends Mutation<EffimodKontaktResponse, {}> {
    document = KontakteApi.mutations.createEffimodKontakt;
}

@Injectable({providedIn: 'root'})
export class UpdateEffimodKontakteMutationsGQL extends Mutation<EffimodKontaktResponse, {}> {
    document = KontakteApi.mutations.updateEffimodKontakt;
}
