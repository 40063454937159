import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../index';


@Injectable()
export class RouterEffects {
    constructor(private actions$: Actions, private store$: Store<AppState>) {
    }

    /*
    @Effect()
    routerNavigation$ = this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        switchMap(() => {
            return of(fromActions.hasNavigated());
        }),
    );
     */

}
