import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'effimod-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    @Input() loadingMessage: string = 'Daten werden geladen...';

    constructor() {
    }

    ngOnInit() {
    }

}
