import * as fromActions from '../actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { VeranstaltungStatusService } from '../../data/services/veranstaltung-status.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class VeranstaltungStatusEffects {
    @Effect()
    loadVeranstaltungStatus$ = this.actions$.pipe(
        ofType(fromActions.loadVeranstaltungStatus),
        switchMap(() => {
            // Use the cached observable from VeranstaltungStatusService
            return this.veranstaltungStatusService.getVeranstaltungStatus().pipe(
                first(), // Make sure to get the latest cached value only once
                map(statusList => fromActions.loadVeranstaltungStatusSuccess({ entities: statusList })),
                catchError(errorMessage => {
                    return of(fromActions.loadVeranstaltungStatusFail({ errorMessage }));
                })
            );
        })
    );

    constructor(private actions$: Actions, private veranstaltungStatusService: VeranstaltungStatusService) {}
}
