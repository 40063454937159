import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseEntityFilter, DateTimeFilter, NumberFilter } from '../interfaces/base-entity-filter.interface';
import { Apollo } from 'apollo-angular';
import { EntityService } from '../../shared/interfaces/entity-service.class';
import { map, tap } from 'rxjs/operators';
import { Blockierung } from '../interfaces/blockierung.interface';
import * as moment from 'moment';
import {
    BlockierungenResponse,
    CreateBlockierungMutationGQL,
    DeleteBlockierungMutationGQL,
    getBlockierungen,
    UpdateBlockierungMutationGQL
} from '../graphql/blockierungen/blockierungen.gql';
import { GraphQLBlockierungInput } from '../graphql/blockierungen/input/blockierung-input';
import { GraphQLBlockierungUpdateInput } from '../graphql/blockierungen/input/GraphQLBlockierungUpdateInput';
import { NotificationService } from '../../shared/services/notification.service';

@Injectable({providedIn: 'root'})
export class BlockierungenDataService extends EntityService {

    private blockierungenSubject: BehaviorSubject<Blockierung[]> = new BehaviorSubject<Blockierung[]>([]);
    private blockierungSubject: BehaviorSubject<Blockierung> = new BehaviorSubject<Blockierung>(null);

    public blockierungen$: Observable<Blockierung[]> = this.blockierungenSubject;
    public blockierung$: Observable<Blockierung> = this.blockierungSubject;

    constructor(private readonly apollo: Apollo,
                private notificationService: NotificationService,
                private readonly createBlockierungMutationGQL: CreateBlockierungMutationGQL,
                private readonly updateBlockierungMutationGQL: UpdateBlockierungMutationGQL,
                private readonly deleteBlockierungMutationGQL: DeleteBlockierungMutationGQL) {
        super();
    }

    public loadEntities(): void {
        this.currentFilter.take = 200; // just to be sure
        this.apollo.subscribe<BlockierungenResponse>({query: getBlockierungen(this.currentFilter, false)})
            .subscribe(
                (res) => {
                    const blockierungen = res.data.privateApi.blockierungen.alleBlockierungen;
                    this.blockierungenSubject.next(blockierungen);
                    return blockierungen;
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    public loadEntity(id: number): Observable<Blockierung> {
        return this.apollo.subscribe<BlockierungenResponse>({
            query: getBlockierungen({
                take: 1,
                skip: 0,
                filterBy: [
                    new NumberFilter({field: 'id', operator: 'eq', value: id})
                ]
            }, true)
        }).pipe(
            map(res => {
                const blockierung = res.data.privateApi.blockierungen.alleBlockierungen[0];
                this.blockierungSubject.next(blockierung);
                return blockierung;
            }));
    }

    public createBlockierung(raumId: number, beginn: Date, ende: Date, titel: string): Observable<Blockierung> {
        const blockierung = {beginn, ende, raumId, titel} as GraphQLBlockierungInput;
        return this.createBlockierungMutationGQL.mutate({blockierung}).pipe(
            map(res => {
                const newBlockierung = res.data.blockierungen.erstellen;
                // todo jonathan: @rustom: is this still necessary as we don't access Blockierungen from blockierungen-data.service anymore for agenda
                // -> yes, for now at least... it is required especially by the selectedBlockierungenService so that it reacts correctly. it can be refactored though...
                this.addItem(newBlockierung);
                return newBlockierung;
            })
        );
    }

    public updateBlockierung(blockierungData: Blockierung): Observable<Blockierung> {
        const blockierung = this.mapBlockierungToUpdateInput(blockierungData);
        return this.updateBlockierungMutationGQL.mutate({blockierung}).pipe(
            map(res => {
                const updatedBlockierung = res.data.blockierungen.bearbeiten;
                this.replaceItem(updatedBlockierung);
                return updatedBlockierung;
            })
        );
    }

    public deleteBlockierung(blockierungId: number): Observable<number> {
        return this.deleteBlockierungMutationGQL.mutate({blockierungId})
            .pipe(
                map(res => {
                    const removedBlockierungId = res.data.blockierungen.delete;
                    this.removeItemById(removedBlockierungId);
                    return removedBlockierungId;
                })
            );
    }

    private replaceItem(updatedBlockierung: Blockierung) {
        this.removeItemById(updatedBlockierung.id);
        this.addItem(updatedBlockierung);
    }

    private addItem(newBlockierung: Blockierung) {
        this.blockierungenSubject.next([...this.blockierungenSubject.value, newBlockierung]);
        this.blockierungSubject.next(newBlockierung);
    }

    private removeItemById(blockierungId: number) {
        const currentItemIndex = this.blockierungenSubject.value.findIndex(x => x.id == blockierungId);
        if (currentItemIndex > -1) {
            const currentBlockierungen = Object.assign([], this.blockierungenSubject.value);
            currentBlockierungen.splice(currentItemIndex, 1);
            this.blockierungenSubject.next([...currentBlockierungen]);
            this.blockierungSubject.next(null);
        }
    }

    private mapBlockierungToUpdateInput(blockierungData: Blockierung) {
        return {
            id: blockierungData.id,
            beginn: blockierungData.beginn,
            ende: blockierungData.ende,
            titel: blockierungData.titel,
            raumId: blockierungData.raum.id,
        } as GraphQLBlockierungUpdateInput;
    }

    public getBlockierungForRaumAndDateRange(raumId: string, beginn: string, ende: string): Observable<Blockierung[]> {
        const beginnFilter = new DateTimeFilter({
            field: 'beginn',
            operator: 'gt',
            value: moment(beginn)
        });

        const endeFilter = new DateTimeFilter({
            field: 'ende',
            operator: 'lt',
            value: moment(ende)
        });

        const roomFilter = new NumberFilter({
            field: 'raumId',
            operator: 'eq',
            value: raumId,
        });

        const rangeFilter: BaseEntityFilter = {
            take: 3,
            skip: 0,
            filterBy: [endeFilter, beginnFilter, roomFilter],
        };

        return this.apollo.subscribe<BlockierungenResponse>({query: getBlockierungen(rangeFilter, false)})
            .pipe(
                map(res => {
                    return res.data.privateApi.blockierungen.alleBlockierungen.filter(x => x.raum.id === parseInt(raumId, 10));
                })
            );
    }
}
