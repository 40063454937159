import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArtikelState } from '../reducers';
import * as fromReducers from '../reducers';

export const getArtikelState = createFeatureSelector<ArtikelState>('artikel');

export const getArtikel = createSelector(getArtikelState, (state) => state.entities);
export const getArtikelLoading = createSelector(getArtikelState, (state) => state.loading);
export const getArtikelLoaded = createSelector(getArtikelState, (state) => state.loaded);
export const getArtikelKategorieNames = createSelector(getArtikelState, (state: fromReducers.ArtikelState) => Object.keys(state.entitiesByKategorieName));
export const getArtikelByKategorieName = createSelector(getArtikelState, (state: fromReducers.ArtikelState, props) => state.entitiesByKategorieName[props.kategorieName]);
export const getSelectedKategorieName = createSelector(getArtikelState, (state: fromReducers.ArtikelState) => state.selectedKategorieName);
export const getFilteredArtikel = createSelector(getArtikelState, (state: fromReducers.ArtikelState) => state.filteredArtikel);
