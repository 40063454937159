import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        NavigationBarComponent,
        NotificationComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        NavigationBarComponent,
        NotificationComponent
    ]
})
export class LayoutModule {

}
