import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions';

export interface NavigationState {
    entityIds: string[];
    selectedEntityId: string;
}

const initialState: NavigationState = {
    entityIds: [],
    selectedEntityId: null
};

const navigationReducer = createReducer(
    initialState,
    on(fromActions.setEntityIds, (state, {entityIds}) => {
        return {...state, entityIds, selectedEntityId: null};
    }),
    on(fromActions.setSelectedEntityId, (state, {entityId}) => {
        return {...state, selectedEntityId: entityId};
    }),
    on(fromActions.loadNextItem, (state, {basePath}) => {
        return {...state, selectedEntityId: state.entityIds[state.entityIds.indexOf(state.selectedEntityId) + 1]};
    }),
    on(fromActions.loadPreviousItem, (state, {basePath}) => {
        return {...state, selectedEntityId: state.entityIds[state.entityIds.indexOf(state.selectedEntityId) - 1]};
    })
);

export function navigationReducerFn(state: NavigationState, action: Action) {
    return navigationReducer(state, action);
}
