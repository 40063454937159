import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';

export const getNavigationState = createFeatureSelector('navigation');
export const getSelectedEntityId = createSelector(getNavigationState, (state: fromReducers.NavigationState) => state.selectedEntityId);
export const canNavigateBack = createSelector(getNavigationState, (state: fromReducers.NavigationState) => {
    return state.selectedEntityId && state.entityIds.length > 0 && state.entityIds.indexOf(state.selectedEntityId) !== 0;
});
export const canNavigateForward = createSelector(getNavigationState, (state: fromReducers.NavigationState) => {
    return state.selectedEntityId && state.entityIds.length > 0 && state.entityIds.indexOf(state.selectedEntityId) < state.entityIds.length - 1;
});
