import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, shareReplay, tap, filter, first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as fromStore from '../store';
import { AppState } from '../store';
import { Store, select } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { EffimodPermissionEnum } from '../shared/enums/permission.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(private store: Store<AppState>) { }

    checkCurrentUserForPermission(permissionName: EffimodPermissionEnum): Observable<boolean> {
        return this.store.pipe(
            select(fromStore.getCurrentUser),
            filter(currentUser => !!currentUser),
            first(),
            map(currentUser => {
                const hasPermission = currentUser != null && currentUser.permissions.includes(permissionName);
                // Second part of the condition makes sure that calling the directive with no value will always
                // PASS the permission check (makes it easier to apply the directive in dynamic scenarios)
                if (hasPermission || !permissionName) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }
}
