import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BenachrichtigungsEmail } from '../interfaces/benachrichtigungsEmail';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BenachrichtigungsLogInterface } from '../interfaces/benachrichtigungs-log.interface';

@Injectable({
    providedIn: 'root'
})

export class EmailService {

    constructor(private client: HttpClient) { }

    generateAenderungsEmail(typ: string, id: string, title: string, body: string, addressList: string[], senderName: string) {
        const gesamtTitel = `${typ} ${id} - ${title}`;
        const urlTyp = typ === 'Veranstaltung' ? 'veranstaltungen' : 'reservationen';
        const url = `${environment.url}/${urlTyp}/${id}`;
        return {body, title: gesamtTitel, addressList, url, typ, id, senderName} as BenachrichtigungsEmail;
    }

    sendEmail(email: BenachrichtigungsEmail): Observable<any> {
        return this.client.post(`${environment.api.rest}/email`, email);
    }

    getBenachrichtigungsHistoryForVeranstaltung(veranstaltungsNummer: string): Observable<BenachrichtigungsLogInterface> {
        return this.client.get<BenachrichtigungsLogInterface>(`${environment.api.rest}/email/veranstaltung/logs/${veranstaltungsNummer}`);
    }

    getBenachrichtigungsHistoryForReservation(reservationsNummer: string): Observable<BenachrichtigungsLogInterface> {
        return this.client.get<BenachrichtigungsLogInterface>(`${environment.api.rest}/email/reservation/logs/${reservationsNummer}`);
    }

}
