import { RouterEffects } from './router.effect';
import { ArtikelEffects } from './artikel.effect';
import { GebaeudeEffects } from './gebaeude.effect';
import { NavigationEffects } from './navigation.effect';
import { VeranstaltungStatusEffects } from './veranstaltung-status.effect';
import { UserEffects } from './user.effect';

export const effects: any[] = [
    RouterEffects,
    ArtikelEffects,
    GebaeudeEffects,
    NavigationEffects,
    VeranstaltungStatusEffects,
    UserEffects
];
