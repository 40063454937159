import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'effimod-modal-button',
  templateUrl: './modal-button.component.html',
  styleUrls: ['./modal-button.component.scss']
})
export class ModalButtonComponent {
    @Input() buttonTooltip: string = 'Open';
    @Input() buttonText: string = 'Open';
    @Input() disabled: boolean = false;
    @Input() disabledReason: string = '';
    @Input() modalTitle: string;
    @Input() modalSubmitButtonText: string = 'Submit';
    @Input() modalSubmitButtonDisabledReason: string;
    @Input() modalCancelButtonText: string = 'Cancel';
    @Input() modalSubmitButtonDisabled: boolean = false;
    @Input() buttonClasses: string = '';

    @Output() confirm = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();
    @Output() modalOpened = new EventEmitter<void>();

    isModalVisible = false;

    openModal() {
        this.isModalVisible = true;
        this.modalOpened.emit();
    }

    closeModal() {
        this.isModalVisible = false;
        this.cancel.emit();
    }

    onConfirm() {
        this.confirm.emit();
        this.closeModal();
    }
}
