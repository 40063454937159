import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'effimod-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    @Input()
    searchTitle: string;

    keywords: string;

    @Output()
    search: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    onSearch() {
        if (this.keywords) {
            this.search.emit(this.keywords);
        }
    }
}
