import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Bestellposition } from '../interfaces/bestellposition.interface';
import { Apollo } from 'apollo-angular';
import { EntityService } from 'src/app/shared/interfaces/entity-service.class';
import { BestellpositionenApi } from '../graphql/bestellpositionen/bestellpositionen.gql';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationUrgency } from 'src/app/shared/interfaces/notification.interface';
import { BestellpositionenParameters, BestellpositionenResponse } from '../graphql/bestellpositionen/model/bestellpositionen-response';
import { GraphQLBestellpositionInput, GraphQLBestellpositionUpdateInput } from '../graphql/bestellpositionen/model/bestellpositionen-input';
import { map } from 'rxjs/operators';
import { CreateBestellpositionMutationGQL, DeleteBestellpositionMutationGQL, EditBestellpositionMutationGQL } from '../graphql/bestellpositionen/bestellpositionen.mutations';

@Injectable({
    providedIn: 'root'
})
export class BestellpositionenDataService extends EntityService {

    private currentBestellpositionenSubject: BehaviorSubject<Bestellposition[]> = new BehaviorSubject<Bestellposition[]>([]);
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public currentBestellpositionen$: Observable<Bestellposition[]> = this.currentBestellpositionenSubject;
    public loading$: Observable<boolean> = this.loadingSubject;

    constructor(
        private readonly apollo: Apollo,
        private readonly notificationService: NotificationService,
        private readonly createBestellpositionMutationGQL: CreateBestellpositionMutationGQL,
        private readonly editBestellpositionMutationGQL: EditBestellpositionMutationGQL,
        private readonly deleteBestellpositionMutationGQL: DeleteBestellpositionMutationGQL) {
        super();
    }

    public loadEntities(reservationId: number): void {
        const queryParams: BestellpositionenParameters = {reservationId};
        this.loadingSubject.next(true);
        this.apollo.subscribe<BestellpositionenResponse>({
                query: BestellpositionenApi.queries.getBestellpositionen,
                variables: queryParams
            })
            .subscribe(
                (response) => {
                    this.loadingSubject.next(false);
                    this.currentBestellpositionenSubject.next(response.data.privateApi.bestellpositionen.alleBestellpositionen);
                },
                (_) => {
                    this.loadingSubject.next(false);
                    this.notificationService.notifiy({
                        text: `Fehler beim Abfragen von Artikeln [Bestellpositionen] für die reservationId ${reservationId}`,
                        urgency: NotificationUrgency.error
                    });
                }
            );
    }

    public createBestellpositionAsync(bestellpositionInput: GraphQLBestellpositionInput) {
        this.loadingSubject.next(true);
        this.apollo.mutate(
            {
                mutation: BestellpositionenApi.mutations.erstellen,
                variables: {bestellposition: bestellpositionInput}
            })
            .subscribe(
                (response: any) => {
                    this.loadingSubject.next(false);
                    this.currentBestellpositionenSubject.next([...this.currentBestellpositionenSubject.value, response.data.bestellpositionen.erstellen]);
                },
                (_) => {
                    this.loadingSubject.next(false);
                    this.notificationService.notifiy({
                        text: `Fehler bei der Erstellung der Bestellposition mit der Bemerkung ${bestellpositionInput.bemerkung}`,
                        urgency: NotificationUrgency.error
                    });
                }
            );
    }

    public createBestellposition(bestellpositionInput: GraphQLBestellpositionInput): Observable<any> {
        return this.createBestellpositionMutationGQL.mutate({bestellposition: bestellpositionInput}).pipe(
            map(res => {
                return res.data.bestellpositionen.erstellen;
            }));

        // this.notificationService.notifiy({
        //     text: `Fehler bei der Erstellung der Bestellposition mit der Bemerkung ${bestellpositionInput.bemerkung}`,
        //     urgency: NotificationUrgency.error
        // });
    }

    public deleteBestellposition(bestellpositionId: number): Observable<any> {
        return this.deleteBestellpositionMutationGQL.mutate({bestellpositionId}).pipe(
            map(res => {
                return res.data.bestellpositionen.delete;
            }));
    }

    public editBestellposition(bestellpositionUpdate: GraphQLBestellpositionUpdateInput): Observable<any> {
        return this.editBestellpositionMutationGQL.mutate({bestellposition: bestellpositionUpdate}).pipe(
            map(res => {
                return res.data.bestellpositionen.bearbeiten;
            }));
    }
}
