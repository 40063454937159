export const environment = {
    name: 'staging',
    production: false,
    staging: true,
    url: 'https://effimod-staging.technopark.ch',
    apiUrl: 'https://effimod-api-staging.technopark.ch/api',
    api: {
        graphql: 'https://effimod-api-staging.technopark.ch/graphql',
        rest: 'https://effimod-api-staging.technopark.ch/api',
        token: 'token'
    }
};
