export const VeranstaltungenFilterNames = {
    VERANSTALTUNG_ID: 'id',
    TITEL: 'titel',
    BEGINN: 'beginn',
    ENDE: 'ende',
    VERANSTALTER_NAME: 'veranstalterName',
    VERANSTALTER_ORT: 'veranstalterOrt',
    SACHBEARBEITER_ID: 'sachbearbeiterId',
    STATUS_ID: 'statusId'
};
