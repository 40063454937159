import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VeranstaltungenBatchBearbeitungService {
    private baseUrl = `${environment.api.rest}`;

    constructor(private httpClient: HttpClient) { }

    appendTiaginfo(veranstaltungIds: number[], tiagInfo: string): Observable<any> {
        const url = `${this.baseUrl}/veranstaltungen/tiaginfo`;
        const body = {
            tiagInfo,
            veranstaltungIds
        };

        return this.httpClient.post(url, body);
    }
}
