import * as fromActions from '../actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { VeranstaltungStatusService } from '../../data/services/veranstaltung-status.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class VeranstaltungStatusEffects {
    @Effect()
    loadVeranstaltungStatus$ = this.actions$.pipe(
        ofType(fromActions.loadVeranstaltungStatus),
        switchMap((action) => {
            return this.veranstaltungStatusService.loadVeranstaltungStatus()
                .pipe(
                    map(res => fromActions.loadVeranstaltungStatusSuccess({entities: res})),
                    catchError(errorMessage => {
                        return of(fromActions.loadVeranstaltungStatusFail({errorMessage}));
                    })
                );
        })
    );

    constructor(private actions$: Actions, private veranstaltungStatusService: VeranstaltungStatusService) {
    }
}
