import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    EffimodTableColumnDefinition,
    EffimodTableDefinition,
    EffimodTableFilterEvent,
    EffimodTableFilterKeyValue,
    EffimodTableFilterType, EffimodTableSelectionEvent,
    EffimodTableSortingEvent
} from '../../interfaces/effimod-table-definition.interface';
import { EffimodTableContent } from '../../interfaces/effimod-table-content.interface';
import * as moment from 'moment';

@Component({
    selector: 'effimod-table',
    templateUrl: './effimod-table.component.html',
    styleUrls: ['./effimod-table.component.scss']
})
export class EffimodTableComponent implements OnInit {

    @Input() content: EffimodTableContent;
    @Input() definition: EffimodTableDefinition;
    @Input() selectable: boolean;
    @Input() isStatic: boolean; // only filtering
    @Input() isSortable: boolean = true;
    @Input() filterCollection: EffimodTableFilterKeyValue;

    @Output() onSortChanged = new EventEmitter<EffimodTableSortingEvent>();
    @Output() onSelectionChanged = new EventEmitter<EffimodTableSelectionEvent>();
    @Output() navigated = new EventEmitter<any>();
    @Output() onFiltersChanged = new EventEmitter<EffimodTableFilterKeyValue>();
    @Output() lazyLoadingTriggered = new EventEmitter<any>();

    @Output() newRowAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveNewRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveEditedRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() newRowEdited: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowEdited: EventEmitter<any> = new EventEmitter<any>();
    @Output() cancelRowChanges: EventEmitter<any> = new EventEmitter<any>();

    @Output() editExistingRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteRow: EventEmitter<any> = new EventEmitter<any>();

    public filterTypes = EffimodTableFilterType;
    public selectedRows: any[];
    public newRowData: any;
    public editActive = false;

    constructor() { }

    ngOnInit() {
        if (this.filterCollection) {
            this.sendFilterArray();
        } else {
            this.filterCollection = {};
        }
    }

    public filterChanges(event, col: EffimodTableColumnDefinition) {
        if (event.key === 'Enter') {
            this.sendFilterArray();
            return;
        } else if (event.code === 'Backspace' && event.target.value === '') {
            delete this.filterCollection[col.filterName];
            return;
        }
        const current: EffimodTableFilterEvent = {
            value: event.target.value,
            filterType: col.filterType,
            fieldSelector: col.fieldSelector,
            operator: col.operator,
            filterName: col.filterName
        };
        this.filterCollection[col.filterName] = current;
    }

    public onDropdownChanged(event, col) {
        const current: EffimodTableFilterEvent = {
            value: event.value,
            filterType: col.filterType,
            fieldSelector: col.fieldSelector,
            operator: col.operator,
            filterName: col.filterName
        };

        this.filterCollection[col.filterName] = current;
        this.sendFilterArray();
    }

    onDateChanged(event, col) {
        const dateValue = event.target.value;
        const parsedDate = moment(dateValue);

        if (!parsedDate.isValid() || parsedDate.year() < 2000 || parsedDate.year() > 3000) {
            return;
        }

        const current: EffimodTableFilterEvent = {
            value: event.target.value,
            filterType: col.filterType,
            fieldSelector: col.fieldSelector,
            operator: col.operator,
            filterName: col.filterName
        };
        this.filterCollection[col.filterName] = current;
        if (Object.keys(this.filterCollection).length > 1 || Object.values(this.filterCollection).filter(x => x.filterType == EffimodTableFilterType.date).length === 2) {
            this.sendFilterArray();
        }
    }

    public sendFilterArray() {
        if (Object.keys(this.filterCollection).length > 0) {
            this.selectedRows = [];
            this.onFiltersChanged.emit(this.filterCollection);
        }
    }

    public sort(event) {
        this.onSortChanged.emit({ fieldSelector: event.field, order: event.order });
    }

    public selectionChanged() {
        if (this.selectable) {
            this.onSelectionChanged.emit({ selection: this.selectedRows });
        }
    }

    addNewRow(newRow: any) {
        this.editActive = true;
        this.discardCurrentNewRow();
        this.newRowData = newRow;
        this.content.items.push(newRow);
        this.newRowAdded.emit(newRow);
    }

    onCancelNewRow() {
        this.editActive = false;
        this.discardCurrentNewRow();
    }

    onSaveNewRow(rowData) {
        this.editActive = false;
        this.saveNewRow.emit(rowData);
        this.newRowData = null;
    }

    onEditRow(rowData) {
        this.editActive = true;
        this.editExistingRow.emit(rowData);
    }

    onDeleteRow(rowData) {
        this.deleteRow.emit(rowData);
    }

    onSaveEditedRow(rowData) {
        this.editActive = false;
        this.saveEditedRow.emit(rowData);
    }

    onCancelEditRowChanges(rowData) {
        this.editActive = false;
        this.cancelRowChanges.emit(rowData);
    }

    onNewRowEdited(event, rowData) {
        this.newRowEdited.emit({ event, rowData });
    }

    onRowEdited(event, rowData) {
        this.rowEdited.emit({ event, rowData });
    }

    onRowSelect(event) {
        if (this.definition.navigatable && (event.originalEvent.target.className.includes('navigatable') || event.originalEvent.target.parentElement.className.includes('navigatable'))) {
            this.navigated.emit(event.data);
        } else {
            this.onSelectionChanged.emit({ selection: this.selectedRows });
        }
    }

    private discardCurrentNewRow() {
        if (!this.content || !this.content.items || !this.newRowData) {
            return;
        }

        const rowIndex = this.content.items.findIndex(x => x.id === this.newRowData.id);
        if (rowIndex > -1) {
            this.content.items.splice(rowIndex, 1);
            this.newRowData = null;
        }
    }

    public paginate(event) {
        this.content.skip = event.first;
        this.content.take = event.rows;
        const eventData = {
            first: event.first,
            rows: event.rows,
            sortField: this.content.sortField,
            sortOrder: this.content.sortOrder,
            filters: this.filterCollection
        };
        this.lazyLoadingTriggered.emit(eventData);
    }
}
