import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { GebaeudeGuard } from './shared/guards/gebaeude.guard';
import { VeranstaltungStatusGuard } from './shared/guards/veranstaltung-status.guard';
import { AuthGuard } from './auth/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BestellbareArtikelGuard } from './shared/guards/bestellbare-artikel-guard.service';
import { EffimodUsersResolver } from './shared/resolvers/effimod-users-resolver.service';
import { CheckAuthorizationGuard } from './auth/check-authorization.guard';
import { EffimodPermissionEnum } from './shared/enums/permission.enum';
import { Auth0TokenInterceptorService } from './auth/auth0-token-interceptor.service';
import { EffimodGraphqlErrorsInterceptor } from './shared/interceptors/effimod-graphql-errors-interceptor.service';
import { RaeumeResolver } from './shared/resolvers/raeume-resolver.service';

const appRoutes: Routes = [
    {
        path: '',
        canActivate: [GebaeudeGuard, VeranstaltungStatusGuard, AuthGuard],
        resolve: {
            users: EffimodUsersResolver,
            raeume: RaeumeResolver
        },
        children: [
            {path: '', redirectTo: 'agenda', pathMatch: 'full'},
            {path: 'auswertungen', loadChildren: () => import('./auswertungen/auswertungen.module').then(mod => mod.AuswertungenModule)},
            {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule)},
            {
                path: 'kunden',
                loadChildren: () => import('./kunden/kunden.module').then(mod => mod.KundenModule),
                canActivate: [CheckAuthorizationGuard],
                data: {permission: EffimodPermissionEnum.READ_KUNDE}
            },
            {
                path: 'veranstaltungen',
                loadChildren: () => import('./veranstaltungen/veranstaltungen.module').then(mod => mod.VeranstaltungenModule),
                canActivate: [CheckAuthorizationGuard],
                data: {permission: EffimodPermissionEnum.READ_VERANSTALTUNG}
            },
            {
                path: 'reservationen',
                loadChildren: () => import('./reservationen/reservationen.module').then(mod => mod.ReservationenModule),
                canActivate: [BestellbareArtikelGuard, CheckAuthorizationGuard],
                data: {permission: EffimodPermissionEnum.READ_RESERVATION}
            },
            {path: 'agenda', loadChildren: () => import('./agenda/agenda.module').then(mod => mod.AgendaModule)},
            {
                path: 'blockierungen',
                loadChildren: () => import('./blockierungen/blockierungen.module').then(mod => mod.BlockierungenModule),
                canActivate: [CheckAuthorizationGuard],
                data: {permission: EffimodPermissionEnum.READ_BLOCKIERUNG}
            },
            {path: '404-page-not-found', component: PageNotFoundComponent},
            {path: '**', redirectTo: '/404-page-not-found'}
        ]
    }
];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, routingConfiguration)],
    exports: [RouterModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EffimodGraphqlErrorsInterceptor,
            multi: true
        }
    ]
})
export class AppRoutingModule {
}
