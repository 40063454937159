import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { NavigationItem, NavigationSubItems } from 'src/app/data/interfaces/navigation/navigation-item.interface';
import { EffimodPermissionEnum } from 'src/app/shared/enums/permission.enum';
import { Observable, concat, forkJoin, of } from 'rxjs';
import { AuthorizationService } from 'src/app/auth/authorization.service';
import { take, filter } from 'rxjs/operators';

@Component({
    selector: 'effimod-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input()
    env: string = '';

    @Output()
    actionClicked: EventEmitter<string> = new EventEmitter<string>();

    public isBurgerMenuActive: boolean = false;

    navItems: NavigationItem[] = [
        {
            routerLink: '/auswertungen',
            externalLink: null,
            name: 'Auswertungen',
            requiredPermission: EffimodPermissionEnum.READ_AUSWERTUNG,
            subItems: {
                links: [
                    {
                        routerLink: '/auswertungen/umsatz',
                        externalLink: null,
                        name: 'umsatz',
                        requiredPermission: EffimodPermissionEnum.READ_AUSWERTUNG
                    }
                ],
            }
        },
        {
            routerLink: '/kunden',
            externalLink: null,
            name: 'Kunden',
            requiredPermission: EffimodPermissionEnum.READ_KUNDE,
            subItems: {
                links: [
                    {
                        routerLink: '/kunden/organisationen',
                        externalLink: null,
                        name: 'organisationen',
                        requiredPermission: EffimodPermissionEnum.READ_KUNDE
                    },
                    {
                        routerLink: '/kunden/kontakte',
                        externalLink: null,
                        name: 'kontakte',
                        requiredPermission: EffimodPermissionEnum.READ_KUNDE
                    }
                ],
            }
        },
        {
            routerLink: '/veranstaltungen',
            externalLink: null,
            name: 'Veranstaltungen',
            requiredPermission: EffimodPermissionEnum.READ_VERANSTALTUNG
        },
        {
            routerLink: '/reservationen',
            externalLink: null,
            requiredPermission: EffimodPermissionEnum.READ_RESERVATION,
            name: 'Reservationen'
        },
        {
            routerLink: '/blockierungen',
            externalLink: null,
            requiredPermission: EffimodPermissionEnum.READ_BLOCKIERUNG,
            name: 'Blockierungen'
        },
        {
            routerLink: '/agenda',
            externalLink: null,
            name: 'Agenda'
        },
        {
            routerLink: null,
            externalLink: null,
            name: 'Admin',
            requiredPermission: EffimodPermissionEnum.READ_ADMIN,
            subItems: {
                actions: [
                    {
                        name: 'aktualisieren',
                        logo: 'assets/images/insightly (round).png',
                        title: 'aktualisieren',
                        requiredPermission: EffimodPermissionEnum.EXECUTE_ADMIN
                    },
                    {
                        name: 'syncArtikel',
                        logo: '',
                        title: 'Artikel aktualisieren',
                        requiredPermission: EffimodPermissionEnum.EXECUTE_ADMIN
                    }
                ]
            }
        }
    ];


    constructor(
        public auth: AuthService
    ) {
    }

    ngOnInit() {
    }

    public setBurgerClass() {
        this.isBurgerMenuActive = !this.isBurgerMenuActive;
    }
}
