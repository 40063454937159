import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'effimod-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges {

    // Hide old inactive values, use attribute hide on options items

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    options: any[];

    @Input()
    valuePropertyName: string;

    @Input()
    descriptionPropertyName: string;

    @Input()
    parent: AbstractControl;

    @Input()
    formControlNameString: string;

    constructor() {
    }

    ngOnInit() {}

    ngOnChanges(): void {
    }

    get effimodFormControl() { return this.parent.get(this.formControlNameString); }
}
