import { Pipe, PipeTransform } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';

@Pipe({
    name: 'veranstaltungenKopierenTooltip'
})
export class VeranstaltungenKopierenTooltipPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        const veranstaltungen = value as Veranstaltung[];

        if (!veranstaltungen || !veranstaltungen.length) {
            return 'Welche Veranstaltung soll kopiert werden?';
        }

        if (veranstaltungen.length > 1) {
            return 'Es darf nur eine einzelne Veranstaltung eines Insightly-Kunden kopiert werden';
        }

        return 'Veranstaltung kopieren';
    }

}
