import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { GetVeranstaltungStatusGQL } from '../graphql/veranstaltungen/status/veranstaltung-status.queries';
import { VeranstaltungStatus } from '../interfaces/VeranstaltungStatus';

@Injectable({
    providedIn: 'root'
})
export class VeranstaltungStatusService {
    // A subject to hold the cached list of VeranstaltungStatus
    private veranstaltungStatusCache$ = new BehaviorSubject<VeranstaltungStatus[] | null>(null);

    constructor(private getAlleVeranstaltungStatus: GetVeranstaltungStatusGQL) {}

    /**
     * Loads the VeranstaltungStatus and updates the cache.
     * This method is automatically called if the cache is empty.
     */
    private loadAndCacheVeranstaltungStatus(): Observable<VeranstaltungStatus[]> {
        return this.getAlleVeranstaltungStatus.fetch().pipe(
            map(res => res.data.publicApi.veranstaltungStatus.alle),
            tap(statusList => this.veranstaltungStatusCache$.next(statusList))
        );
    }

    /**
     * Provides the cached VeranstaltungStatus list as an observable.
     * If the cache is empty, it automatically loads the data and caches it.
     */
    public getVeranstaltungStatus(): Observable<VeranstaltungStatus[]> {
        return this.veranstaltungStatusCache$.pipe(
            switchMap(statusList => {
                if (statusList === null) {
                    // Cache is empty, load and cache the data
                    return this.loadAndCacheVeranstaltungStatus();
                } else {
                    // Cache is already populated, return it as an observable
                    return this.veranstaltungStatusCache$.asObservable();
                }
            }),
            take(1) // Complete after retrieving the data once
        );
    }
}
