import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../index';
import { select, Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as fromActions from '../actions';
import * as fromSelectors from '../selectors';

@Injectable()
export class NavigationEffects {
    @Effect({dispatch: false})
    onNavigateBackwards$ = this.actions$.pipe(
        ofType(fromActions.loadNextItem, fromActions.loadPreviousItem),
        withLatestFrom(this.store.pipe(select(fromSelectors.getSelectedEntityId))),
        map(([action, selectedId]) => {
            this.router.navigateByUrl(`${action.basePath}/${selectedId}`);
        })
    );

    constructor(private actions$: Actions,
                private router: Router,
                private store: Store<AppState>) {
    }
}
