import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { NotificationService } from '../../services/notification.service';
import { EffimodKontakteDataService } from '../../../data/services/kunden/effimod-kontakte-data.service';

@Component({
    selector: 'effimod-base-kunde-selection-modal-button'
})
export abstract class BaseKundeSelectionModalButtonComponent implements OnInit {

    @Input() buttonText: string = 'ändern';
    @Input() buttonTooltip: string;
    @Input() buttonClasses: string = '';
    @Input() disabled: boolean;
    @Input() disabledReason: string;
    @Input() preselectedKundenTypOption: KundenTypOptions;
    @Input() modalTitle: string;
    @Input() modalSubmitButtonText: string;
    @Input() modalSubmitButtonDisabledReason: string;

    @Output() selected = new EventEmitter<KundeVersion>();

    currentKundeSelection: KundeVersion;

    constructor(
        protected notificationService: NotificationService,
        protected effimodKontakteDataService: EffimodKontakteDataService
    ) {}

    ngOnInit() {}

    async onKundeSelectionConfirmed() {
        if (!this.currentKundeSelection) {
            this.notificationService.notifyError('Bitte wählen Sie einen Kunden aus.');
            return;
        }

        if (!this.currentKundeSelection.kunde) {
            this.notificationService.notifyError('Der Stammkunde für die gewählte Kundeversion konnte nicht gefunden werden.');
            return;
        }

        if (this.currentKundeSelection.kunde.isFirma && !this.currentKundeSelection.externalCrmKundeId) {
            this.notificationService.notifyError('Firmen können nicht ohne CRM-ID synchronisiert werden.');
            return;
        }

        if (this.currentKundeSelection.externalCrmKundeId) {
            const syncedKunde = await this.syncInsightlyKunde(this.currentKundeSelection.externalCrmKundeId, this.currentKundeSelection.kunde.isFirma);
            if (syncedKunde) {
                this.selected.emit(syncedKunde);
            } else {
                this.notificationService.notifyError('Der Kunde konnte nicht synchronisiert werden.');
            }
        } else if (this.currentKundeSelection.id) {
            this.selected.emit(this.currentKundeSelection);
        } else {
            this.notificationService.notifyError('Der gewählte Kunde konnte nicht verarbeitet werden.');
        }
    }

    private async syncInsightlyKunde(externalCrmKundeId: string, isFirma: boolean): Promise<KundeVersion | undefined> {
        try {
            const syncedKunde = await this.effimodKontakteDataService.syncKundeByExternalCrmId(externalCrmKundeId, isFirma).toPromise();
            if (syncedKunde) {
                return syncedKunde;
            } else {
                throw new Error('No Kunde returned from sync.');
            }
        } catch (error) {
            this.notificationService.notifyError('Fehler beim Synchronisieren des Kunden. Bitte melden Sie sich beim Sytemadministrator und teilen Sie ihm die Kundendaten mit.');
            console.error('Error syncing Kunde:', error);
        }
    }
}
