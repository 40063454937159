import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { KundenTypOptions } from '../kunden-typ-selector/kunden-typ-options';
import { InsightlyDataService } from '../../services/insightly-data.service';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { InsightlyDataMapper } from '../../services/insightly-data.mapper';

@Component({
    selector: 'effimod-kunde-selector',
    templateUrl: './kunde-selector.component.html',
    styleUrls: ['./kunde-selector.component.scss']
})
export class KundeSelectorComponent implements OnInit, OnDestroy {

    public isOrganisation: boolean;
    public isLoading: boolean = false;

    public kunden: KundeVersion[] = [];
    private subscriptions: Subscription = new Subscription();

    @Input()
    readonlyKundenTypSelector: boolean = false;

    @Input()
    preselectedKundenTypOption: KundenTypOptions = KundenTypOptions.organisation;

    @Output()
    kundeSelected: EventEmitter<KundeVersion> = new EventEmitter<KundeVersion>();

    constructor(
        private insightlyDataService: InsightlyDataService,
        private insightlyDataMapper: InsightlyDataMapper
    ) { }

    ngOnInit() {
        this.isOrganisation = this.preselectedKundenTypOption === KundenTypOptions.organisation;
    }

    onKundeSelected(kunde: KundeVersion) {
        this.kundeSelected.emit(kunde);
    }

    onKeywordsChanged(keyword: string) {
        this.searchKunden(keyword);
    }

    private searchKunden(keyword: string): void {
        this.kunden = [];
        this.isLoading = true;

        const searchObservable = this.isOrganisation
            ? this.insightlyDataService.searchOrganisations(keyword).pipe(
                map(orgs => this.insightlyDataMapper.mapOrganisationsToKundeVersion(orgs))
            )
            : this.insightlyDataService.searchContacts(keyword).pipe(
                map(contacts => this.insightlyDataMapper.mapContactsToKundeVersion(contacts))
            );

        this.subscriptions.add(
            searchObservable
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(res => {
                    console.log('Search results: ', res);
                    this.kunden = res;
                }, error => {
                    console.error('Error loading Kunden:', error);
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
