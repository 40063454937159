import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Veranstaltung } from '../../../data/interfaces/veranstaltung.interface';
import { EffimodPermissionEnum } from '../../enums/permission.enum';

@Component({
    selector: 'effimod-veranstaltungen-batch-bearbeitung-button',
    templateUrl: './veranstaltungen-batch-bearbeitung-button.component.html',
    styleUrls: ['./veranstaltungen-batch-bearbeitung-button.component.scss']
})
export class VeranstaltungenBatchBearbeitungButtonComponent implements OnChanges {
    @Input() veranstaltungen: Veranstaltung[];
    isDisabled: boolean = true;
    isModalDisplayed: boolean;
    effimodPermissionEnum = EffimodPermissionEnum;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.veranstaltungen && !changes.veranstaltungen.isFirstChange()) {
            const veranstaltungen = changes.veranstaltungen.currentValue as Veranstaltung[];
            const sealedVeranstaltungenDetected = veranstaltungen.some(x => x.isSealed);
            this.isDisabled = !veranstaltungen || !veranstaltungen.length || sealedVeranstaltungenDetected;
        }
    }
}
