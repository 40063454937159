import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from '../../../shared/services/notification.service';
import { KundeVersion } from '../../interfaces/kunde-version.interface';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class EffimodKontakteDataService {

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loading$: Observable<boolean> = this.loadingSubject;

    constructor(
        private apollo: Apollo,
        private readonly notificationService: NotificationService
    ) { }

    public createKunde(kunde: KundeVersion): Observable<KundeVersion> {
        const CREATE_EFFIMOD_KONTAKT_MUTATION = gql`
            mutation ($kontakt: ansprechpersonInput!) {
                kontakte {
                    createEffimodKontakt(kontakt: $kontakt) {
                        id
                        anrede
                        vorname
                        nachname
                        firma
                        kunde {
                            id
                            isFirma
                            organisationId
                        }
                        adresse {
                            id
                            strasse
                            plz
                            ort
                            region
                            land
                        }
                        telefon
                        mobile
                        email
                        fax
                    }
                }
            }
        `;

        this.loadingSubject.next(true);

        const ansprechpersonInput = this.mapKundeVersionToAnsprechpersonInput(kunde);

        return this.apollo.mutate<{ kontakte: { createEffimodKontakt: KundeVersion } }>({
            mutation: CREATE_EFFIMOD_KONTAKT_MUTATION,
            variables: { kontakt: ansprechpersonInput }
        }).pipe(
            map(res => res.data.kontakte.createEffimodKontakt),
            tap(() => this.loadingSubject.next(false)),
            catchError(err => {
                this.loadingSubject.next(false);
                this.notificationService.notifyError(
                    `Fehler bei der Erstellung des Kunden mit dem Namen ${kunde.vorname} ${kunde.nachname}`
                );
                return throwError(err);
            })
        );
    }

    private mapKundeVersionToAnsprechpersonInput(kundeVersion: KundeVersion): any {
        return {
            adresse: kundeVersion.adresse,
            anrede: kundeVersion.anrede,
            email: kundeVersion.email,
            fax: kundeVersion.fax,
            firma: kundeVersion.firma,
            telefon: kundeVersion.telefon,
            mobile: kundeVersion.mobile,
            nachname: kundeVersion.nachname,
            titel: kundeVersion.titel,
            vorname: kundeVersion.vorname,
            kunde: {
                isFirma: kundeVersion.kunde.isFirma,
                organisationId: +kundeVersion.kunde.organisationId
            }
        };
    }

    public updateKunde(kunde: KundeVersion): Observable<KundeVersion> {
        const UPDATE_EFFIMOD_KONTAKT_MUTATION = gql`
            mutation ($kunde: updateEffimodKundeInput!) {
                kontakte {
                    updateEffimodKontakt(kontakt: $kunde) {
                        id
                        anrede
                        vorname
                        nachname
                        firma
                        adresse {
                            id
                            strasse
                            plz
                            ort
                            region
                            land
                        }
                        telefon
                        mobile
                        email
                        fax
                    }
                }
            }
        `;

        this.loadingSubject.next(true);

        return this.apollo.mutate<{ kontakte: { updateEffimodKontakt: KundeVersion } }>({
            mutation: UPDATE_EFFIMOD_KONTAKT_MUTATION,
            variables: { kunde }
        }).pipe(
            map(res => res.data.kontakte.updateEffimodKontakt),
            tap(() => this.loadingSubject.next(false)),
            catchError(err => {
                this.loadingSubject.next(false);
                this.notificationService.notifyError(
                    `Fehler bei der Bearbeitung des Kunden mit dem Namen ${kunde.vorname} ${kunde.nachname}`
                );
                return throwError(err);
            })
        );
    }

    syncKundeByExternalCrmId(externalCrmKundeId: string, isFirma: boolean): Observable<KundeVersion> {
        const SYNC_KUNDE_BY_EXTERNAL_CRM_ID_MUTATION = gql`
            mutation syncKundeByExternalCrmId($externalCrmKundeId: String!, $isFirma: Boolean!) {
                kunden {
                    syncKundeByExternalCrmId(externalCrmKundeId: $externalCrmKundeId, isFirma: $isFirma) {
                        id
                        externalCrmKundeId
                        firma
                        debitorenNummer
                        name
                        anrede
                        vorname
                        nachname
                        email
                        telefon
                        mobile
                        crm {
                            id
                            name
                        }
                        kunde {
                            id
                            organisationId
                            isFirma
                        }
                        adresse {
                            id
                            strasse
                            plz
                            ort
                            region
                            land
                        }
                        rechnungsAdresse {
                            id
                            strasse
                            plz
                            ort
                            region
                            land
                        }
                    }
                }
            }
        `;

        return this.apollo.mutate<{ kunden: { syncKundeByExternalCrmId: KundeVersion } }>({
            mutation: SYNC_KUNDE_BY_EXTERNAL_CRM_ID_MUTATION,
            variables: { externalCrmKundeId: externalCrmKundeId + '', isFirma }
        }).pipe(
            map(result => result.data.kunden.syncKundeByExternalCrmId),
            catchError(err => {
                this.notificationService.notifyError(
                    'Fehler beim Synchronisieren des Kunden. Bitte versuchen Sie es später erneut.'
                );
                return throwError(err);
            })
        );
    }
}
