import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'effimod-form-input-wrapper',
    templateUrl: './form-input-wrapper.component.html',
    styleUrls: ['./form-input-wrapper.component.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}
    ]
})
export class FormInputWrapperComponent implements OnInit {
    // called like this because otherwise the routerlink gets set on the whole component
    @Input() routerLinkInput: string;
    @Input() parent: FormGroup;
    @Input() inputId: string;
    @Input() label: string;

    public routeMatches: boolean = false;

    constructor(private readonly router: Router) {
    }

    ngOnInit() {
        if (this.routerLinkInput != undefined) {
            this.routeMatches = this.routerLinkInput.endsWith(this.router.url);
        }
    }
}
