import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'effimod-labeled-link',
  templateUrl: './labeled-link.component.html',
  styleUrls: ['./labeled-link.component.scss']
})
export class LabeledLinkComponent implements OnInit {
@Input()
label: string;
@Input()
link: string;
@Input()
display: string;


  constructor() { }

  ngOnInit() {
  }

}
