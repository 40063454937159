export const KundeFormFieldNames = {
    ID: 'id',
    IS_FIRMA: 'isFirma',
    FIRMA: 'firma',
    VORNAME: 'vorname',
    NACHNAME: 'nachname',
    NAME: 'name',
    STRASSE: 'strasse',
    POSTLEITZAHL: 'postleitzahl',
    ORT: 'ort',
    LAND: 'land',
    ANREDE: 'anrede',
    TELEFON: 'telefon',
    MOBILE: 'mobile',
    EMAIL: 'email',
    EXTERNAL_CRM_KUNDE_ID: 'externalCrmKundeId',
    ORGANISATION_ID: 'organisationId',
    DEBITOREN_NUMMER: 'debitorenNummer'
};
