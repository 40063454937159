import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyRoundTo5Rappen'
})
export class CurrencyRoundTo5RappenPipe implements PipeTransform {

    transform(value: number): any {
        return (Math.round(value * 20) / 20).toFixed(2);
    }
}
