import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotificationUrgency } from '../interfaces/notification.interface';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import * as fromStore from '../../store';
import { select, Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class EffimodActionsService {

    baseUrl = `${environment.api.rest}`;

    constructor(private client: HttpClient, private notificationService: NotificationService, private store: Store<fromStore.AppState>) { }

    public syncBestellbareArtikelFromGaraioremToEffimod() {
        // todo it'd probably be better/clearer to rename the api to something that reflects the fact, that these artikel are the "Bestellbare" (Kategorie != Standard)
        const url = `${this.baseUrl}/artikel/sync`;
        this.client.post<void>(url, {}).subscribe(res => {

            this.store.dispatch(fromStore.loadBestellbareArtikel());

            this.notificationService.notifiy({
                text: `Artikel aktualisiert`,
                urgency: NotificationUrgency.success
            });
        }),
            catchError(() => {
                this.notificationService.notifiy({
                    text: `Fehler beim Aktualisieren der Artikel`,
                    urgency: NotificationUrgency.error
                });
                return of(false);
            });
    }
}
