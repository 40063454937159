import gql from 'graphql-tag';
import { KundenApi } from '../kunden/kunden.gql';
import { BaseEntityFilter } from '../../interfaces/base-entity-filter.interface';
import { DocumentNode } from 'graphql';
import { Veranstaltung } from '../../interfaces/veranstaltung.interface';

export const VeranstaltungenApi: any = {};

VeranstaltungenApi.fragments = {
    veranstaltungForList: gql`
        fragment VeranstaltungForListFragment on VeranstaltungType {
            id
            titel
            beginn
            ende
            anzahlPersonen
            kurzbeschreibung
            tiagInfo
            allgemeineInfo
            zusatzInfo
            parkdienstBemerkung
            verpflegungBemerkung
            isAnzahlungVerarbeitet
            veranstalterVersion {
                id
                firma
                vorname
                nachname
                name
                kunde {
                    id,
                    isFirma,
                    organisationId
                }
                crm {
                    id,
                    name
                },
                externalCrmKundeId,
                adresse {
                    id
                    plz
                    ort
                }
            }
            kundeAnsprechpersonVersion {
                ... KundeVersionFragment
            }
            kundeRechnungVersion {
                ... KundeVersionFragment
            }
            reservationen {
                id
                reservationsNummer
                beschriftung
            }
            status {
                id
                bezeichnung
                beschreibung
            }
            sachbearbeiter {
                id
                username
                vorname
                nachname
                name
            }
            isSealed
        }
        ${KundenApi.fragments.kundeVersion}
    `,
    veranstaltung: gql`
        fragment VeranstaltungFragment on VeranstaltungType {
            id
            createdOn
            lastModified
            titel
            beginn
            ende
            anzahlPersonen
            kurzbeschreibung
            tiagInfo
            allgemeineInfo
            zusatzInfo
            parkdienstBemerkung
            verpflegungBemerkung
            isAnzahlungVerarbeitet
            veranstalterVersion {
                ... KundeVersionFragment
            }
            kundeAnsprechpersonVersion {
                ... KundeVersionFragment
            }
            kundeRechnungVersion {
                ... KundeVersionFragment
            }
            reservationen {
                reservationsNummer
                beschriftung
            }
            status {
                id
                bezeichnung
            }
            isSealed
            sachbearbeiter {
                id
                auth0Id
                name
                username
                vorname
                nachname
            }
        }
        ${KundenApi.fragments.kundeVersion}
    `
};

VeranstaltungenApi.mutations = {
    erstellen: gql`
        mutation createVeranstaltung($veranstaltungInput: VeranstaltungInputType!) {
            veranstaltungen {
                erstellen(veranstaltungInput: $veranstaltungInput) {
                    ... VeranstaltungFragment
                }
            }
        }
        ${VeranstaltungenApi.fragments.veranstaltung}
    `,
    delete: gql`
        mutation deleteVeranstaltung($veranstaltungId: Int!) {
            veranstaltungen {
                delete(id: $veranstaltungId)
            }
        }
    `,
    annullieren: gql`
      mutation($veranstaltungId: Int!) {
          veranstaltungen {
              annullieren(id: $veranstaltungId)
          }
      }
    `,
    kopieren: gql`
      mutation($veranstaltungId: Int!) {
          veranstaltungen {
              kopieren(id: $veranstaltungId) {
                  ... VeranstaltungFragment
              }
          }
      }
      ${VeranstaltungenApi.fragments.veranstaltung}
    `,
    abschliessen: gql`
      mutation($veranstaltungId: Int!) {
          veranstaltungen {
              abschliessen(id: $veranstaltungId)
          }
      }
    `,
    updateAnsprechpersonen: gql`
        mutation updateAnsprechpersonen($ansprechpersonenUpdate: AnsprechpersonenUpdateInput!) {
            veranstaltungen {
                ansprechpersonenBearbeiten(ansprechpersonenUpdate: $ansprechpersonenUpdate) {
                    id
                }
            }
        }
    `,
    updateVeranstaltungStatus: gql`
        mutation veranstaltungStatusBearbeiten($veranstaltungStatusUpdate: VeranstaltungStatusUpdateInputType!) {
            veranstaltungStatus {
                bearbeiten(veranstaltungStatusUpdate: $veranstaltungStatusUpdate)
            }
        }
    `,
    update: gql`
        mutation updateVeranstaltung($veranstaltung: veranstaltungUpdateInput!) {
            veranstaltungen {
                bearbeiten(veranstaltung: $veranstaltung) {
                    ... VeranstaltungFragment
                }
            }
        }
        ${VeranstaltungenApi.fragments.veranstaltung}
    `,
    createVeranstaltungFromBlockierungen: gql`
        mutation createVeranstaltungFromBlockierungen($blockierungenKonvertieren: BlockierungenKovertierenZuVeranstaltungenInputType!) {
            veranstaltungen {
                blockierungenKonvertierenZuVeranstaltung(blockierungenKonvertieren: $blockierungenKonvertieren) {
                    ... VeranstaltungFragment
                }
            }
        }
        ${VeranstaltungenApi.fragments.veranstaltung}
    `,
    anzahlungenVerarbeiten: gql`
        mutation anzahlungVerarbeiten($id: Int!) {
            veranstaltungen {
                anzahlungVerarbeiten(id: $id)
            }
        }
    `,
};

export interface VeranstaltungenResponse {
    privateApi: {
        veranstaltungen: { alleVeranstaltungen: Veranstaltung[] };
    };
}

export function getVeranstaltungen(filter: BaseEntityFilter, withDetails: boolean, debug = false) {
    let fragment = 'VeranstaltungForListFragment';
    let fragmentImport = VeranstaltungenApi.fragments.veranstaltungForList;
    let displayDetails = ', displayDetails = false';
    if (withDetails) {
        fragment = 'VeranstaltungFragment';
        fragmentImport = VeranstaltungenApi.fragments.veranstaltung;
        displayDetails = ', displayDetails = true';
    }
    let filterOrderString = '';
    if (filter.filterBy && filter.filterBy.length > 0) {
        filterOrderString += `, filter: {${filter.filterBy.map(f => f.toQuery()).join(',')}}`;
    }
    if (filter.orderBy && filter.orderBy.length > 0) {
        filterOrderString += `, orderBy: {${filter.orderBy.map(f => f.toQuery()).join(',')}}`;
    }
    const query = gql`
        query getVeranstaltungen {
            privateApi {
                veranstaltungen {
                    alleVeranstaltungen(take: ${filter.take}, skip: ${filter.skip}${filterOrderString}) {
                        ... ${fragment}
                    }
                }
            }
        }
        ${fragmentImport}
    `;
    if (debug) {
        console.log(getGqlString(query));
    }
    return query;
}

function getGqlString(doc: DocumentNode) {
    return doc.loc && doc.loc.source.body;
}
