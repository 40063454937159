export enum VoucherDurationBezeichnungModel {
    OneDay = '1 Tag', // 28,
    TwoDays = '2 Tage', // 29,
    ThreeDays = '3 Tage', //30,
    FourDays = '4 Tage', // 31,
    OneWeek = '1 Woche', // 32,
    TwoWeeks = '2 Wochen', // 33
    ThreeWeeks = '3 Wochen', // 34
    FourWeeks = '4 Wochen' // 35
}

