import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import { BestellpositionenApi } from './bestellpositionen.gql';
import { Bestellposition } from '../../interfaces/bestellposition.interface';

export interface BestellpositionResponse {
    bestellpositionen: {
        erstellen: Bestellposition,
        bearbeiten: Bestellposition
        delete: number,
        // kopieren: Veranstaltung,
    };
}

@Injectable({providedIn: 'root'})
export class CreateBestellpositionMutationGQL extends Mutation<BestellpositionResponse, {}> {
    document = BestellpositionenApi.mutations.erstellen;
}

@Injectable({providedIn: 'root'})
export class EditBestellpositionMutationGQL extends Mutation<BestellpositionResponse, {}> {
    document = BestellpositionenApi.mutations.bearbeiten;
}

@Injectable({providedIn: 'root'})
export class DeleteBestellpositionMutationGQL extends Mutation<BestellpositionResponse, {}> {
    document = BestellpositionenApi.mutations.delete;
}
