import { Component, OnInit } from '@angular/core';
import { BaseKundeSelectionModalButtonComponent } from '../base-kunde-selection-modal-button/base-kunde-selection-modal-button.component';
import { NotificationService } from '../../services/notification.service';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { KundeTabSectionEnum } from '../../enums/kunde-tab-section.enum';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';
import { KundeVersionSyncService } from '../../services/kunde-version-sync.service';
import { EffimodKontakteDataService } from '../../../data/services/kunden/effimod-kontakte-data.service';

@Component({
    selector: 'effimod-ansprechperson-selection-modal-button',
    templateUrl: './ansprechperson-selection-modal-button.component.html',
    styleUrls: ['./ansprechperson-selection-modal-button.component.scss']
})
export class AnsprechpersonSelectionModalButtonComponent extends BaseKundeSelectionModalButtonComponent implements OnInit {

    constructor(
        notificationService: NotificationService,
        protected effimodKontakteDataService: EffimodKontakteDataService,
        protected kundeVersionSyncService: KundeVersionSyncService) {
        super(notificationService, kundeVersionSyncService);
        this.buttonTooltip = 'Ansprechperson ändern';
        this.modalTitle = 'Ansprechperson auswählen';
        this.modalSubmitButtonText = 'bestätigen';
        this.preselectedKundenTypOption = KundenTypOptions.kontakt;
    }

    public selectedTabSection: KundeTabSectionEnum = KundeTabSectionEnum.Auswaehlen;

    public readonly KundeTabSectionEnum = KundeTabSectionEnum;

    ngOnInit() {
        this.selectedTabSection = KundeTabSectionEnum.Auswaehlen;
    }

    async onAnsprechpersonChange() {
        if (this.selectedTabSection === KundeTabSectionEnum.Erfassen) {
            try {
                const createdKunde = await this.effimodKontakteDataService.createKunde(this.currentKundeSelection).toPromise();
                this.selected.emit(createdKunde);
            } catch (error) {
                console.error('Error creating Ansprechperson:', error);
            }
        } else {
            await this.onKundeSelectionConfirmed();
        }
    }

    updateSelectedTab(selectedTab: KundeTabSectionEnum) {
        this.selectedTabSection = selectedTab;
    }

    onKundeSelectionClicked(selectedKunde: KundeVersion) {
        if (selectedKunde && selectedKunde.kunde && selectedKunde.kunde.isFirma) {
            this.notificationService.notifyError('Ansprechpersonen können nur Kontakte (Personen) sein, keine Firmen.');
            return;
        }

        this.currentKundeSelection = selectedKunde;
    }

    onKundeErstellenRequestConfirmed(kundeVersion: KundeVersion) {
        this.currentKundeSelection = kundeVersion;
    }
}
