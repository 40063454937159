import { Component } from '@angular/core';
import { KundenTypOptions } from '../../components/kunden-typ-selector/kunden-typ-options';
import { NotificationService } from '../../services/notification.service';
import { EffimodKontakteDataService } from '../../../data/services/kunden/effimod-kontakte-data.service';
import { BaseKundeSelectionModalButtonComponent } from '../base-kunde-selection-modal-button/base-kunde-selection-modal-button.component';

@Component({
    selector: 'effimod-veranstalter-selection-modal-button',
    templateUrl: './veranstalter-selection-modal-button.component.html',
    styleUrls: ['./veranstalter-selection-modal-button.component.scss']
})
export class VeranstalterSelectionModalButtonComponent extends BaseKundeSelectionModalButtonComponent {

    constructor(notificationService: NotificationService, effimodKontakteDataService: EffimodKontakteDataService) {
        super(notificationService, effimodKontakteDataService);
        this.buttonTooltip = 'Veranstalter ändern';
        this.modalTitle = 'Veranstalter auswählen';
        this.modalSubmitButtonText = 'bestätigen';
        this.preselectedKundenTypOption = KundenTypOptions.organisation;
    }
}
