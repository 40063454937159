import { Pipe, PipeTransform } from '@angular/core';
import { KundeVersion } from '../../../data/interfaces/kunde-version.interface';

@Pipe({
    name: 'crmLinkText'
})
export class CrmLinkTextPipe implements PipeTransform {
    transform(kundeVersion: KundeVersion, showName: boolean, showAdditionalInformation: boolean): string {
        if (!kundeVersion) {
            return '';
        }

        return showName ? this.getDisplayName(kundeVersion, showAdditionalInformation) : this.getCrmIdentifier(kundeVersion);
    }

    private getCrmIdentifier(kundeVersion: KundeVersion): string {
        return kundeVersion.externalCrmKundeId || '';
    }

    private getDisplayName(kundeVersion: KundeVersion, showAdditionalInformation: boolean): string {
        let text = kundeVersion.name;
        if (showAdditionalInformation) {
            const additionalInfo = this.getAdditionalInfo(kundeVersion);
            if (additionalInfo) {
                text += ` (${additionalInfo})`;
            }
        }

        return text;
    }

    private getAdditionalInfo(kundeVersion: KundeVersion): string {
        const infoParts: string[] = [];
        if (kundeVersion.adresse && kundeVersion.adresse.ort) {
            infoParts.push(kundeVersion.adresse.ort);
        }

        if (kundeVersion.debitorenNummer) {
            infoParts.push(`Debitoren-Nr ${kundeVersion.debitorenNummer}`);
        }
        return infoParts.join(', ');
    }
}
